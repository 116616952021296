import React from "react";
import "./AllVideosModal.scss";

const VideoItem = ({ name, onClick, thumbnailUrl }) => {
  return (
    <div className="VideoItem" onClick={onClick}>
      <>
        <img
          className="VideoItem-image"
          src={thumbnailUrl}
          alt={`Video preview for ${name}`}
        />
        <div className="LivecamItem-name-wrapper">
          <div className="LivecamItem-name">{name}</div>
        </div>
      </>
    </div>
  );
};

const AllVideosModal = ({ videos, selectVideo }) => {
  const sortedVideos = videos
    .slice()
    .sort((a, b) => a.properties.name.localeCompare(b.properties.name));
  return (
    <div className="AllVideosModal">
      <header>
        <div className="title">Videos</div>
      </header>
      <div className="VideoItems">
        {sortedVideos.map(video => {
          const { id, name, thumbnail_url } = video.properties;
          return (
            <VideoItem
              key={id}
              name={name}
              onClick={() => selectVideo(name)}
              thumbnailUrl={thumbnail_url}
            />
          );
        })}
      </div>
    </div>
  );
};

export default AllVideosModal;
