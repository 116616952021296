import React, { Component } from "react";

export class VideoPlayer extends Component {
  constructor() {
    super();
    this.state = {
      videoHeight: 360,
      videoWidth: 600
    };
  }

  componentDidMount() {
    const { height, width } = this.props.video.properties;
    const containerWidth = this.refs.videoContainer.getBoundingClientRect()
      .width;
    const calculatedHeight = containerWidth * (height / width);
    this.setState({
      videoHeight: calculatedHeight,
      videoWidth: containerWidth
    });
  }

  render() {
    const { video } = this.props;
    const { videoHeight, videoWidth } = this.state;

    return (
      <div ref="videoContainer">
        <iframe
          title={video.properties.name}
          style={{ maxWidth: "100%" }}
          src={video.properties.embed_url}
          width={videoWidth}
          height={videoHeight}
          frameBorder="0"
          allowFullScreen
        />
      </div>
    );
  }
}
