import * as actions from "../constants/action_names.js";
import queryString from "query-string";

const query = queryString.parse(window.location.href.split("?")[1] || "");

const initialState = {
  filter: query.florafilter ? query.florafilter : "native"
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.FLORA_GEOJSON_REQUEST:
      return {
        ...state,
        geojson: null
      };
    case actions.FLORA_GEOJSON_RESPONSE:
      return {
        ...state,
        geojson: action.geojson,
        species: action.species
      };
    case actions.FLORA_GEOJSON_ERROR_RESPONSE:
      return {
        ...state,
        geojson: action.error
      };
    case actions.FLORA_SET_FILTER:
      return {
        ...state,
        filter: action.filter
      };
    default:
      return state;
  }
};
