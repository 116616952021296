import * as actions from "../constants/action_names.js";
import queryString from "query-string";

const query = queryString.parse(window.location.href.split("?")[1] || "");

const initialState = {
  filter: query.inatfilter ? query.inatfilter : null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.INATURALIST_OVERVIEW_RESPONSE:
      return {
        ...state,
        overview: action.json,
        selected: action.selected
      };
    case actions.INATURALIST_OVERVIEW_ERROR_RESPONSE:
      return {
        ...state,
        error: action.error
      };
    case actions.INATURALIST_POINTS_RESPONSE:
      return {
        ...state,
        _fetchingVideoPoints: true,
        points: action.geojson
      };
    case actions.INATURALIST_POINTS_ERROR_RESPONSE:
      return {
        ...state,
        error: action.error
      };
    case actions.INATURALIST_PHOTOS_REQUEST:
      return {
        ...state,
        photos: null
      };
    case actions.INATURALIST_PHOTOS_RESPONSE:
      return {
        ...state,
        photos: action.json.rows
      };
    case actions.INATURALIST_PHOTOS_ERROR_RESPONSE:
      return {
        ...state
      };
    case actions.INATURALIST_SELECT:
      return {
        ...state,
        points: {
          ...state.points,
          features: state.points
            ? state.points.features.map(feature => {
                return {
                  ...feature,
                  properties: {
                    ...feature.properties,
                    _actionMapSelected:
                      feature.properties.id === action.observation
                  }
                };
              })
            : []
        }
      };
    case actions.INATURALIST_SET_FILTER:
      return {
        ...state,
        filter: action.filter
      };
    default:
      return state;
  }
};
