import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch, withRouter } from "react-router";
import queryString from "query-string";

import { clearModalSize, setModalSize } from "../../actions/critterMap_actions";
import "./TrailcamModal.scss";
import CategoryTrailcamModal from "./CategoryTrailcamModal";
import FullscreenTrailcamModal from "./FullscreenTrailcamModal";
import IndividualTrailcamModal from "./IndividualTrailcamModal";

const mapStateToProps = state => ({
  modalSize: state.critterMap.modalSize
});

const mapDispatchToProps = dispatch => ({
  clearModalSize: () => dispatch(clearModalSize()),
  setModalSize: size => dispatch(setModalSize(size))
});

class TrailcamModal extends Component {
  modalSizes = {
    expanded: "extralarge",
    collapsed: "large"
  };

  componentWillUnmount() {
    this.props.clearModalSize();
  }

  isModalExpanded() {
    return this.getModalSize() === this.modalSizes.expanded;
  }

  getModalSize() {
    let modalSize = this.props.modalSize;
    if (!modalSize) {
      modalSize = this.modalSizes.expanded;
    }
    return modalSize;
  }

  toggleModalSize() {
    const modalSize = this.getModalSize();
    const newModalSize = Object.values(this.modalSizes).filter(
      size => size !== modalSize
    )[0];
    this.props.setModalSize(newModalSize);
  }

  render() {
    const camRegExp = "\\D[^/]+";
    const dateRegExp = "\\d{4}-\\d{2}-\\d{2}-\\d{1,2}";
    let query = queryString.parse(window.location.search);
    if (typeof query.layers === "string" && query.layers !== "trailcams") {
      query.layers = "trailcams";
    } else if (query.layers.indexOf("trailcams") < 0) {
      query.layers.push("trailcams");
    }

    return (
      <>
        <Route exact path={"/trailcams"}>
          <Redirect
            to={`/trailcams/by-category?${queryString.stringify(query)}`}
          />
        </Route>
        <div className="TrailcamModal">
          <header>
            <div className="title">MPG Trailcams</div>
            <div className="opt" onClick={this.toggleModalSize.bind(this)}>
              {this.isModalExpanded() ? "»" : "«"}
            </div>
          </header>
          <div className="description">
            <p>
              MPG Ranch contains a network of motion activated trailcameras.
              Through microwave radio links, images from this camera are
              uploaded in close to real time. These unobtrusive cameras let
              researchers and the general public observe animals and their
              behavior.
            </p>
            <p>
              The graph below shows when the cameras were triggered by motion.
              Select a different period on the timeline, a different camera tag,
              or an individual camera to change the image selection. Click
              directly on the graph to select a particular time.
            </p>
          </div>
          <Switch>
            <Route
              exact
              path={`/trailcams/by-category/:cam(${camRegExp})?/:date(${dateRegExp})?/:filename?`}
              component={CategoryTrailcamModal}
            />
            <Route
              path={`/trailcams/:cam(${camRegExp})/:date(${dateRegExp})?/:filename?`}
              component={IndividualTrailcamModal}
            />
          </Switch>
          <Switch>
            <Route
              path={`/trailcams/by-category/:cam(${camRegExp})/:date(${dateRegExp})/:filename`}
            >
              <FullscreenTrailcamModal byCategory={true} />
            </Route>
            <Route
              path={`/trailcams/:cam(${camRegExp})?/:date(${dateRegExp})/:filename`}
              component={FullscreenTrailcamModal}
            />
          </Switch>
        </div>
      </>
    );
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(TrailcamModal)
);
