import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import ReactResizeDetector from "react-resize-detector";
import { fetchWeatherData } from "../../actions/async/weather";
import { selectWeatherStation } from "../../actions/weather";
import { slugify } from "../../constants/app";
import WeatherChart from "./WeatherChart";
import { WeatherSummary } from "./WeatherSummary";
import "./WeatherModal.scss";

const mapStateToProps = state => ({
  weather: state.weather,
  timeline: state.timeline
});

const mapDispatchToProps = dispatch => ({
  fetchWeatherData: (bounds, stationSlug) => {
    dispatch(fetchWeatherData(bounds, stationSlug));
  },
  selectWeatherStation: station => dispatch(selectWeatherStation(station))
});

class WeatherModal extends Component {
  componentDidMount() {
    this.updateData();
    WeatherModal.selectCurrentWeatherStation(this.props);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.match.params.station !== prevProps.match.params.station ||
      this.props.timeline.bounds !== prevProps.timeline.bounds
    ) {
      this.updateData();
    }

    if (
      this.props.match.params.station !== prevProps.match.params.station ||
      this.props.weather.points !== prevProps.weather.points
    ) {
      WeatherModal.selectCurrentWeatherStation(this.props);
    }
  }

  shouldComponentUpdate(newProps) {
    if (newProps.weather.data !== this.props.weather.data) {
      return true;
    }
    if (newProps.weather.data.length !== this.props.weather.data.length) {
      return true;
    }
    if (newProps.weather.points !== this.props.weather.points) {
      return true;
    }
    if (newProps.match.params.station !== this.props.match.params.station) {
      return true;
    }
    if (
      newProps.timeline.bounds[0] !== this.props.timeline.bounds[0] ||
      newProps.timeline.bounds[1] !== this.props.timeline.bounds[1]
    ) {
      return true;
    }
    return false;
  }

  updateData() {
    const { match, timeline } = this.props;
    this.props.fetchWeatherData(timeline.bounds, match.params.station);
  }

  static getCurrentWeatherStation(props) {
    if (!(props.weather && props.weather.points)) return null;
    const slug = props.match.params.station;
    const match = props.weather.points.features.filter(
      feature => slugify(feature.properties.id) === slug
    );
    return match.length > 0 ? match[0] : null;
  }

  static selectCurrentWeatherStation(props) {
    const station = WeatherModal.getCurrentWeatherStation(props);
    if (station && !station.properties._actionMapSelected) {
      props.selectWeatherStation(station.properties.id);
    }
  }

  render() {
    const station = WeatherModal.getCurrentWeatherStation(this.props);
    const data = this.props.weather.data;
    const bounds = this.props.timeline.bounds;
    const dateFormat = "MMMM DD, YYYY";

    let cleanData = [];
    if (data && data.rows) {
      cleanData = data.rows.map(d => ({
        dewPoint: d.dewpti,
        humidity: d.hum,
        precipitation: d.precip_totali,
        pressure: d.pressurei,
        time_stamp: new Date(d.time_stamp),
        temperature: d.tempi,
        windSpeed: d.wspdi
      }));
    }

    return (
      <div className="weather-modal">
        {station ? (
          <React.Fragment>
            <div className="weather-modal-header">
              <div className="title">{station.properties.id}</div>
              <div className="date-range">
                {moment(bounds[0]).format(dateFormat)} to{" "}
                {moment(bounds[1]).format(dateFormat)}
              </div>
            </div>
            {data && data.rows ? (
              <ReactResizeDetector
                handleWidth
                render={({ width, height }) => (
                  <WeatherChart
                    data={cleanData}
                    height={height}
                    width={width}
                  />
                )}
              />
            ) : null}
            <WeatherSummary data={cleanData} />
          </React.Fragment>
        ) : null}
      </div>
    );
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(WeatherModal)
);
