import * as actions from "../constants/action_names";

export const selectVideo = video => {
  return dispatch => {
    dispatch({
      type: actions.VIDEO_SELECT,
      video
    });
  };
};
