import * as actions from "../../constants/action_names";
import * as config from "../../constants/config";
import { fetchSql } from "../../constants/carto";

export const fetchSoilData = (bounds, stationSlug) => {
  const sql = `SELECT data_type, date, sensor_depth, port_number, value
      FROM ${config.soil_data_table}
      WHERE date > '${bounds[0].toISOString()}' AND date < '${bounds[1].toISOString()}'
        AND regexp_replace(regexp_replace(lower(location),  E'[^\\\\w\\\\s-]', '', 'gi'), E'[-\\\\s]+', '-', 'gi') = '${stationSlug}'`;

  return dispatch => {
    dispatch({
      type: actions.SOIL_DATA_REQUEST,
      json: null
    });

    return fetchSql(sql, "json")
      .then(json => {
        json.rows = json.rows.map(row => ({
          ...row,
          date: new Date(row.date)
        }));
        dispatch({
          type: actions.SOIL_DATA_RESPONSE,
          json
        });
      })
      .catch(error =>
        dispatch({
          type: actions.SOIL_DATA_ERROR_RESPONSE,
          error: error.message
        })
      );
  };
};

export const fetchSoilPoints = bounds => {
  const sql = `SELECT DISTINCT ON (data.location) name,
      stations.the_geom, data.value
    FROM ${config.soil_station_table} stations
    LEFT JOIN ${config.soil_data_table} data
    ON stations.name = data.location
    WHERE data.date > '${bounds[0].toISOString()}' AND data.date < '${bounds[1].toISOString()}'
      AND data.data_type = 'potential'
      AND data.sensor_depth = 6
      AND stations.the_geom IS NOT NULL
    ORDER BY data.location, data.date DESC`;

  return dispatch => {
    dispatch({
      type: actions.SOIL_POINTS_REQUEST,
      geojson: null
    });

    return fetchSql(sql, "geojson")
      .then(geojson => {
        dispatch({
          type: actions.SOIL_POINTS_RESPONSE,
          geojson
        });
      })
      .catch(error =>
        dispatch({
          type: actions.SOIL_POINTS_ERROR_RESPONSE,
          error
        })
      );
  };
};

export const fetchSoilOverview = () => {
  const sql = `SELECT date_trunc('week', date) AS week, COUNT(*) AS count
      FROM ${config.soil_data_table}
      GROUP BY week`;

  return dispatch => {
    dispatch({
      type: actions.SOIL_OVERVIEW_REQUEST,
      json: null
    });

    return fetchSql(sql, "json")
      .then(json => {
        json.rows = json.rows.map(row => ({
          ...row,
          date: new Date(row.week)
        }));
        dispatch({
          type: actions.SOIL_OVERVIEW_RESPONSE,
          json
        });
      })
      .catch(error =>
        dispatch({
          type: actions.SOIL_OVERVIEW_ERROR_RESPONSE,
          error: error.message
        })
      );
  };
};
