import * as actions from "../../constants/action_names.js";
import { fetchSql } from "../../constants/carto";
import { boundary_table } from "../../constants/config";

export const fetchRanchBoundary = () => {
  const sql = `SELECT cartodb_id, name, the_geom, valid_start, valid_end FROM ${boundary_table}`;

  return dispatch => {
    return fetchSql(sql, "geojson")
      .then(json => {
        dispatch({
          type: actions.BOUNDARY_RESPONSE,
          json
        });
      })
      .catch(error =>
        dispatch({
          type: actions.BOUNDARY_ERROR,
          error: error.message
        })
      );
  };
};
