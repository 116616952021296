import * as actions from "../constants/action_names.js";
import queryString from "query-string";

const query = queryString.parse(window.location.href.split("?")[1] || "");

const DEFAULT_STATE = {
  filter: query.trailcamfilter ? query.trailcamfilter : "All"
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actions.MOTION_OVERVIEW_RESPONSE:
      return {
        ...state,
        _fetchingMotionTimeline: false,
        overview: action.json
      };
    case actions.MOTION_POINTS_RESPONSE:
      return {
        ...state,
        points: action.json
      };
    case actions.TRAILCAM_IMAGE_RESPONSE:
      return {
        ...state,
        images: action.json
      };
    case actions.TRAILCAM_CATEGORIES_RESPONSE:
      return {
        ...state,
        categories: action.categories
      };
    case actions.TRAILCAM_SET_FILTER:
      return {
        ...state,
        filter: action.filter
      };
    case actions.TRAILCAM_SELECT:
      return {
        ...state,
        points: {
          ...state.points,
          features: state.points.features.map(feature => {
            return {
              ...feature,
              properties: {
                ...feature.properties,
                _actionMapSelected: feature.properties.text === action.trailcam
              }
            };
          })
        }
      };
    case actions.TRAILCAM_IMAGE_DISABLE_REQUEST:
      // No-op
      return {
        ...state
      };
    case actions.TRAILCAM_IMAGE_DISABLE_RESPONSE:
      // No-op. We could update the image list (remove the image) but the
      // performance isn't great.
      return {
        ...state
      };
    case actions.TRAILCAM_IMAGE_DISABLE_ERROR_RESPONSE:
      // We could show the user a message?
      console.error(action.error);
      return {
        ...state
      };
    default:
      return state;
  }
};
