import React, { Component } from "react";
import { slugify } from "../../constants/app";
import FullscreenModal from "../modals/FullscreenModal";
import { VideoPlayer } from "./VideoPlayer";

class IndividualVideoModal extends Component {
  static getCurrentVideo(props) {
    if (!(props.video && props.video.points)) return null;
    const slug = props.videoSlug;
    const match = props.video.points.features.filter(
      feature => slugify(feature.properties.name) === slug
    );
    return match.length > 0 ? match[0] : null;
  }

  static selectCurrentVideo(props) {
    const video = IndividualVideoModal.getCurrentVideo(props);
    if (video && !video.properties._actionMapSelected) {
      props.selectVideo(video.properties.name);
    }
  }

  componentDidMount() {
    IndividualVideoModal.selectCurrentVideo(this.props);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.videoSlug === prevProps.videoSlug &&
      this.props.video.points === prevProps.video.points
    ) {
      return;
    }
    IndividualVideoModal.selectCurrentVideo(this.props);
  }

  render() {
    const video = IndividualVideoModal.getCurrentVideo(this.props);
    return (
      <FullscreenModal onClose={() => this.props.onClose()}>
        <div className="VideoModal">
          {video ? (
            <>
              <div className="title">{video.properties.name}</div>
              <VideoPlayer video={video} />
            </>
          ) : null}
        </div>
      </FullscreenModal>
    );
  }
}

export default IndividualVideoModal;
