import React, { Component, useRef } from "react";
import * as d3 from "d3";
import moment from "moment";
import "./TimelineContainer.scss";
import { setTimeline } from "../../actions/timeline_actions.js";
import { layers } from "../../constants/layers.js";
import { connect } from "react-redux";
import { setActiveLayers, setLayerOpacity } from "../../actions/layer_actions";
import push from "../../constants/push.js";
import Timeline from "../timeline/Timeline.js";
import Hammer from "react-hammerjs";

const TimelineTimeLabels = ({
  max,
  min,
  scale,
  side,
  startValue,
  endValue
}) => {
  const startLabel = useRef(null);
  const endLabel = useRef(null);
  const dateFormat = "MMM DD, YYYY";

  // Attempt to put labels where they should be according to the scale
  let startLeft = scale(startValue);
  let endLeft = scale(endValue);

  if (startLabel.current && endLabel.current) {
    let pinnedStart = false;
    let pinnedEnd = false;

    // If start label would run off to the left, pin it to the left
    const startWidth = startLabel.current.clientWidth;
    startLeft -= startWidth;
    if (startLeft < scale(min)) {
      startLeft = scale(min);
      pinnedStart = true;
    }

    // If end label would run off to the right, pin it to the right
    const endWidth = startLabel.current.clientWidth;
    if (endLeft + endWidth > scale(max)) {
      endLeft = scale(max) - endWidth;
      pinnedEnd = true;
    }

    // If end label running into start label, move it to the right
    if (startLeft + startWidth > endLeft && pinnedStart) {
      endLeft = startLeft + startWidth + 10;
    }

    // If start label running into end label, move it to the left
    if (startLeft > endLeft - endWidth && pinnedEnd) {
      startLeft = endLeft - (endWidth + 10);
    }
  }

  return (
    <>
      <div
        className="timeline-time-label"
        style={{ left: startLeft }}
        ref={startLabel}
      >
        {moment(startValue).format(dateFormat)}
      </div>
      <div
        className="timeline-time-label"
        style={{ left: endLeft }}
        ref={endLabel}
      >
        {moment(endValue).format(dateFormat)}
      </div>
    </>
  );
};

const mapStateToProps = state => state;

const mapDispatchToProps = dispatch => ({
  goTo: layerName => {
    dispatch(push("/" + layerName));
  },

  setTimeline: (bounds, push) => dispatch(setTimeline(bounds, push)),

  setActiveLayers(newLayers, path) {
    dispatch(setActiveLayers(newLayers));
  },

  updateOpacity(layer, opacity) {
    dispatch(setLayerOpacity({ [layer]: opacity }));
  },

  updateVisibleLayers: (bounds, props) => {
    const activeLayers = props.layerSelector.active;

    activeLayers.forEach(layerName => {
      const layer = layers[layerName];
      const additionalArguments = layer.additionalAsyncArguments
        ? layer.additionalAsyncArguments(props)
        : [];

      if (layer.timeline && layer.timeline.action) {
        dispatch(layer.timeline.action(...additionalArguments));
      }

      if (layer.map && layer.map.action) {
        if (Array.isArray(layer.map.action)) {
          layer.map.action.forEach(a =>
            dispatch(a(bounds, ...additionalArguments))
          );
        } else {
          dispatch(layer.map.action(bounds, ...additionalArguments));
        }
      }
    });
  }
});

class TimelineContainer extends Component {
  constructor(props) {
    super(props);

    const { min, max } = props;

    this.state = {
      selectionStarted: false,
      scale: d3
        .scaleTime()
        .domain([min, max])
        .range([props.margins.left, props.size.width - props.margins.right]),
      dragged: false,
      dragStartTime: Date.now()
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.min.format() !== this.props.min.format() ||
      prevProps.max.format() !== this.props.max.format() ||
      prevProps.margins.left !== this.props.margins.left ||
      prevProps.margins.right !== this.props.margins.right ||
      prevProps.size.width !== this.props.size.width
    ) {
      this.setState({
        scale: d3
          .scaleTime()
          .domain([this.props.min, this.props.max])
          .range([
            this.props.margins.left,
            this.props.size.width - this.props.margins.right
          ])
      });
    }
  }

  mid(pos) {
    if (!this.state.selectionStarted) return;

    this.setState({
      bounds: [this.state.bounds[0], this.state.scale.invert(pos)]
    });
  }

  start(pos) {
    if (pos < 0) return;

    this.setState({
      selectionStarted: true,
      bounds: [this.state.scale.invert(pos - 1), this.state.scale.invert(pos)]
    });
  }

  end() {
    if (!this.state.selectionStarted) return;

    const sortedBounds = this.state.bounds.slice().sort((a, b) => a - b);
    this.props.setTimeline(sortedBounds, true);
    this.props.updateVisibleLayers(sortedBounds, this.props);

    this.setState({ selectionStarted: false });
  }

  drag(e) {
    this.mid(e.pointers[0].clientX - 228);
  }

  dragStarted(e) {
    this.start(e.pointers[0].clientX - 228);
  }

  tap(e) {
    let clickedDate = this.state.scale.invert(e.pointers[0].clientX - 228);

    this.setState(
      {
        selectionStarted: true,
        bounds: [
          moment(clickedDate).subtract(7, "d"),
          moment(clickedDate).add(7, "d")
        ]
      },
      () => {
        this.end();
      }
    );
  }

  render() {
    const { margins, size } = this.props;

    const timelines = [...this.props.layerSelector.active].reverse().map(l => (
      <Timeline
        component={layers[l].timeline.component}
        data={
          layers[l].timeline.transform
            ? layers[l].timeline.transform(this.props)
            : null
        }
        id={l}
        name={layers[l].name}
        key={"timeline_" + l}
        setLayerInactive={() =>
          this.props.setLayerInactive(
            l,
            this.props.layerSelector.active,
            this.props.router.location.pathname
          )
        }
        dragOver={() => {
          if (Date.now() - this.state.dragStartTime > 100) {
            this.setState({ dragStartTime: Date.now() }, () => {
              let from = this.props.layerSelector.active.indexOf(
                this.state.dragged
              );
              let to = this.props.layerSelector.active.indexOf(l);

              let newLayers = [...this.props.layerSelector.active];
              let temp = newLayers[to];
              newLayers[to] = newLayers[from];
              newLayers[from] = temp;

              // Only set active layers if layer order changed
              const sameOrder = this.props.layerSelector.active.every(
                (value, index) => value === newLayers[index]
              );
              if (!sameOrder) {
                this.props.setActiveLayers(newLayers);
              }
            });
          }
        }}
        dragStart={() => this.setState({ dragged: l })}
        dragEnd={() => this.setState({ dragged: false })}
        margins={margins}
        size={size}
        scale={this.state.scale}
        opacity={layers[l].opacity}
        currentOpacity={this.props.layerSelector.opacity[l]}
        updateOpacity={opacity => this.props.updateOpacity(l, opacity)}
        onClick={() => this.props.goTo(l)}
      />
    ));

    const ticks = [...this.state.scale.ticks(5)];

    const tick_objs = ticks.map(t => (
      <div
        className="tick"
        key={moment(t)}
        style={{ transform: "translate(" + this.state.scale(t) + "px,0px)" }}
      >
        <div className="label">
          {t.getMonth() === 0 && t.getDate() === 1
            ? moment(t).format("YYYY")
            : moment(t).format("MMMM Do YYYY")}
        </div>
      </div>
    ));

    let sortedBounds = this.state.selectionStarted
      ? this.state.bounds.slice().sort((a, b) => a - b)
      : this.props.timeline.bounds.slice().sort((a, b) => a - b);

    let height = this.props.layerSelector.active.reduce(
      (acc, l) => acc + (layers[l].timeline.transform ? 50 : 35),
      16
    );

    return (
      <Hammer
        onPanStart={this.dragStarted.bind(this)}
        onPanEnd={this.end.bind(this)}
        onPan={this.drag.bind(this)}
        options={{
          recognizers: {
            pan: {
              threshold: 0
            }
          }
        }}
        onTap={this.tap.bind(this)}
      >
        <div
          className="Timeline"
          onMouseLeave={this.end.bind(this)}
          onMouseEnter={() => this.setState({ selectionStarted: false })}
          style={{ height }}
        >
          <div className="label-selector" />
          <div className="drag-selector" />
          <div className="timeline-selector" />
          <div
            className="selection"
            style={{
              left: Math.max(-4, this.state.scale(sortedBounds[0])),
              width: Math.max(
                0,
                this.state.scale(sortedBounds[1]) -
                  Math.max(-4, this.state.scale(sortedBounds[0])) -
                  3
              )
            }}
          />
          {timelines.length && sortedBounds.length ? (
            <TimelineTimeLabels
              min={this.props.min}
              max={this.props.max}
              scale={this.state.scale}
              side={"start"}
              startValue={sortedBounds[0]}
              endValue={sortedBounds[1]}
            />
          ) : null}
          {timelines}
          <div className="ticks">{tick_objs}</div>
        </div>
      </Hammer>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TimelineContainer);
