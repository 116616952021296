import * as actions from "../../constants/action_names";
import * as config from "../../constants/config";
import { fetchSql } from "../../constants/carto";

export const fetchMotionOverview = bounds => {
  const sql = `SELECT date_trunc('week', date::date) AS weekly, COUNT(light) AS cnt
        FROM ${config.trailcam_photos_table}
        WHERE EXTRACT(SECOND FROM date) != 0 AND active = true
        GROUP BY weekly`;

  return dispatch => {
    return fetchSql(sql, "json")
      .then(json => {
        json.rows = json.rows.map(r => ({ ...r, weekly: new Date(r.weekly) }));
        dispatch({
          type: actions.MOTION_OVERVIEW_RESPONSE,
          json
        });
      })
      .catch(error =>
        dispatch({
          type: actions.MOTION_OVERVIEW_RESPONSE,
          error: error.message
        })
      );
  };
};

const combineByHour = geojson => {
  let newFeatures = {};
  geojson.features.forEach(f => {
    if (!(f.properties.id in newFeatures)) {
      newFeatures[f.properties.id] = {
        ...f,
        properties: {
          ...f.properties,
          cnt: 0,
          hour: Array(24).fill(0)
        }
      };
    }

    newFeatures[f.properties.id].properties.hour[f.properties.hour] =
      f.properties.cnt;
    newFeatures[f.properties.id].properties.cnt += f.properties.cnt;
  });

  return { ...geojson, features: Object.values(newFeatures) };
};

export const fetchTrailcamTriggerDetail = bounds => {
  const sql = `SELECT COUNT(photos.date) as cnt, locs.the_geom, locs.name AS text, photos.buckeyecam_cartodb_id AS id, EXTRACT(HOUR FROM photos.date) AS hour
        FROM ${config.trailcam_photos_table} photos
        JOIN ${config.trailcam_cameras_table} locs
        ON photos.buckeyecam_cartodb_id = locs.cartodb_id
        WHERE photos.active = true AND EXTRACT(SECOND FROM photos.date) != 0 AND
          photos.date > '${bounds[0].toISOString()}' AND photos.date < '${bounds[1].toISOString()}'
        GROUP BY photos.buckeyecam_cartodb_id, locs.the_geom, locs.name, hour`;

  return dispatch => {
    return fetchSql(sql, "geojson")
      .then(json => {
        const newJson = combineByHour(json);
        dispatch({
          type: actions.MOTION_POINTS_RESPONSE,
          json: newJson
        });
      })
      .catch(error =>
        dispatch({
          type: actions.MOTION_POINTS_RESPONSE_ERROR,
          error: error.message
        })
      );
  };
};

export const fetchTrailcamCategories = () => {
  const sql = `WITH categories AS (
    SELECT distinct unnest(string_to_array(categories, ',')) AS category
    FROM ${config.trailcam_cameras_table}
  ) SELECT * FROM categories ORDER BY category`;

  return dispatch => {
    dispatch({
      type: actions.TRAILCAM_CATEGORIES_REQUEST
    });

    return fetchSql(sql, "json")
      .then(json => {
        return dispatch({
          type: actions.TRAILCAM_CATEGORIES_RESPONSE,
          categories: json.rows.map(r => r.category)
        });
      })
      .catch(error => {
        return dispatch({
          type: actions.TRAILCAM_CATEGORIES_ERROR,
          error: error.message
        });
      });
  };
};

export const fetchTrailcamImages = (
  bounds,
  camSlug,
  canViewProtected = false
) => {
  const sql = `SELECT photos.date, photos.servername, photos.camera_number, photos.filename, photos.light, photos.moon, photos.tempf, locs.name AS text
        FROM ${config.trailcam_photos_table} photos
        JOIN ${config.trailcam_cameras_table} locs
        ON photos.buckeyecam_cartodb_id = locs.cartodb_id ${
          canViewProtected ? "" : "AND NOT locs.protected"
        }
        WHERE photos.active = true AND 
          date > '${bounds[0].toISOString()}' AND date < '${bounds[1].toISOString()}'
        AND regexp_replace(regexp_replace(lower(locs.name),  E'[^\\\\w\\\\s-]', '', 'gi'), E'[-\\\\s]+', '-', 'gi') = '${camSlug}'
        ORDER BY photos.date DESC`;

  return dispatch => {
    dispatch({
      type: actions.TRAILCAM_IMAGE_REQUEST
    });

    return fetchSql(sql, "json")
      .then(json => {
        json.rows = json.rows.map(r => ({
          ...r,
          date: new Date(r.date)
        }));
        dispatch({
          type: actions.TRAILCAM_IMAGE_RESPONSE,
          json: json.rows
        });
      })
      .catch(error =>
        dispatch({
          type: actions.TRAILCAM_IMAGE_ERROR,
          error: error.message
        })
      );
  };
};

export const fetchTrailcamCategoryImages = (
  bounds,
  category,
  canViewProtected = false
) => {
  const sql = `SELECT photos.date, photos.servername, photos.camera_number, photos.filename, locs.name AS text
    FROM ${config.trailcam_photos_table} photos
    JOIN ${config.trailcam_cameras_table} locs
      ON photos.buckeyecam_cartodb_id = locs.cartodb_id ${
        canViewProtected ? "" : "AND NOT locs.protected"
      }
    WHERE 
      photos.active = true
      AND date > '${bounds[0].toISOString()}'
      AND date < '${bounds[1].toISOString()}'
      AND string_to_array(locs.categories, ',') @> ARRAY['${category}']
    ORDER BY photos.date DESC`;

  return dispatch => {
    dispatch({
      type: actions.TRAILCAM_IMAGE_REQUEST
    });

    return fetchSql(sql, "json")
      .then(json => {
        json.rows = json.rows.map(r => ({
          ...r,
          date: new Date(r.date)
        }));
        dispatch({
          type: actions.TRAILCAM_IMAGE_RESPONSE,
          json: json.rows
        });
      })
      .catch(error =>
        dispatch({
          type: actions.TRAILCAM_IMAGE_ERROR,
          error: error.message
        })
      );
  };
};

const timelapseClause = `EXISTS (SELECT NULL
  FROM ${config.trailcam_photos_table} t2
  WHERE t2.date = (t1.date + INTERVAL '1 DAY')
  AND t2.buckeyecam_cartodb_id = t1.buckeyecam_cartodb_id)
AND EXISTS (SELECT NULL
  FROM ${config.trailcam_photos_table} t2
  WHERE t2.date = (t1.date - INTERVAL '1 DAY')
  AND t2.buckeyecam_cartodb_id = t1.buckeyecam_cartodb_id)`;

export const fetchTimelapseOverview = bounds => {
  const sql = `SELECT date_trunc('week', photos.date::date) AS weekly, COUNT(photos.light) AS cnt
    FROM (SELECT t1.* FROM ${config.trailcam_photos_table} t1 WHERE ${timelapseClause} AND t1.active = true) photos
    GROUP BY weekly
    ORDER BY weekly DESC`;

  return dispatch => {
    return fetchSql(sql, "json")
      .then(json => {
        json.rows = json.rows.map(r => ({ ...r, weekly: new Date(r.weekly) }));
        dispatch({
          type: actions.TIMELAPSE_OVERVIEW_RESPONSE,
          json
        });
      })
      .catch(error =>
        dispatch({
          type: actions.TIMELAPSE_OVERVIEW_ERROR,
          error: error.message
        })
      );
  };
};

export const fetchTimelapseDetail = bounds => {
  const sql = `SELECT first.*, agg.cnt AS cnt FROM (
    SELECT DISTINCT ON (photos.buckeyecam_cartodb_id) photos.buckeyecam_cartodb_id, photos.date, photos.servername, photos.camera_number, photos.filename, locs.the_geom, locs.name AS text
    FROM (
        SELECT t1.* FROM ${config.trailcam_photos_table} t1
        WHERE ${timelapseClause}
        AND t1.active = true
        AND t1.date > '${bounds[0].toISOString()}' AND t1.date < '${bounds[1].toISOString()}'
      ) photos
      JOIN mpg_ranch_buckeyecams locs
      ON photos.buckeyecam_cartodb_id = locs.cartodb_id
    ) first
    INNER JOIN (
      SELECT COUNT(t1.light) AS cnt, t1.buckeyecam_cartodb_id FROM ${
        config.trailcam_photos_table
      } t1
      WHERE ${timelapseClause}
      AND t1.active = true
      AND t1.date > '${bounds[0].toISOString()}' AND t1.date < '${bounds[1].toISOString()}'
      GROUP BY t1.buckeyecam_cartodb_id
    ) agg
    ON agg.buckeyecam_cartodb_id = first.buckeyecam_cartodb_id
    WHERE agg.cnt >= 10
  `;

  return dispatch => {
    return fetchSql(sql, "geojson")
      .then(json => {
        dispatch({
          type: actions.TIMELAPSE_DETAIL_RESPONSE,
          json
        });
      })
      .catch(error =>
        dispatch({
          type: actions.TIMELAPSE_DETAIL_ERROR,
          error: error.message
        })
      );
  };
};

export const fetchTimelapseImages = (bounds, camSlug) => {
  const sql = `SELECT photos.date, photos.servername, photos.camera_number, photos.filename, locs.name AS text, locs.the_geom
    FROM (
      SELECT t1.* FROM ${config.trailcam_photos_table} t1
      WHERE ${timelapseClause}
      AND t1.active = true
      AND t1.date > '${bounds[0].toISOString()}' AND t1.date < '${bounds[1].toISOString()}'
    ) photos
    JOIN ${config.trailcam_cameras_table} locs
    ON photos.buckeyecam_cartodb_id = locs.cartodb_id
    AND regexp_replace(regexp_replace(lower(locs.name),  E'[^\\\\w\\\\s-]', '', 'gi'), E'[-\\\\s]+', '-', 'gi') = '${camSlug}'
    ORDER BY photos.date ASC`;

  return dispatch => {
    return fetchSql(sql, "json")
      .then(json => {
        json.rows = json.rows.map(r => ({ ...r, date: new Date(r.date) }));
        dispatch({
          type: actions.TIMELAPSE_IMAGE_RESPONSE,
          json
        });
      })
      .catch(error =>
        dispatch({
          type: actions.TIMELAPSE_IMAGE_ERROR,
          error: error.message
        })
      );
  };
};

export const disableTrailcamImage = (key, cameraName, photoDate) => {
  return dispatch => {
    dispatch({
      type: actions.TRAILCAM_IMAGE_DISABLE_REQUEST,
      cameraName,
      photoDate
    });

    return fetch(config.disableTrailcamImageUrl, {
      method: "POST",
      body: JSON.stringify({ cameraName, key, photoDate }),
      headers: new Headers({ "Content-Type": "application/json" })
    })
      .then(response => response.json())
      .then(json => {
        dispatch({
          type: actions.TRAILCAM_IMAGE_DISABLE_RESPONSE,
          cameraName,
          photoDate,
          json
        });
      })
      .catch(error => {
        dispatch({
          type: actions.TRAILCAM_IMAGE_DISABLE_ERROR_RESPONSE,
          cameraName,
          photoDate,
          error
        });
      });
  };
};
