import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { pointColors } from "../../constants/layers_static";
import "./RaptorModal.scss";

const mapStateToProps = state => ({
  raptors: state.raptors
});

const mapDispatchToProps = dispatch => ({});

class RaptorModal extends Component {
  render() {
    if (!this.props.raptors.tracks) return <div />;

    let colors = {};

    this.props.raptors.tracks.features.forEach(f => {
      colors[f.properties.species] = pointColors.raptors[f.properties.species];
    });

    return (
      <div className="RaptorModal">
        <div className="title">Raptors</div>
        <div className="description">
          <p>
            MPG Ranch works with{" "}
            <a href="https://www.raptorview.org/">
              Raptor View Research Institute
            </a>
            , which runs research conservation and education projects on raptors
            and the ecosystems that support them.
          </p>
          <p>
            To better understand their habits and how habitat loss is impacting
            the thousands-mile-long migrations of these birds of prey, RVRI has
            attached GPS loggers to several birds. Explore their range here, or
            visit our{" "}
            <a href="http://raptortracker.mpgranch.com">companion map</a> to
            follow the journey of an individual animal.
          </p>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RaptorModal)
);
