import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import classNames from "classnames";

import { changeMobilePanel } from "../../actions/mobile";
import "./MobileNavigation.scss";

const NavigationItem = ({ label, mobilePanel, selected }) => {
  const dispatch = useDispatch();

  return (
    <button
      className={classNames(
        "navigation-item",
        `navigation-item-${mobilePanel}`,
        { selected }
      )}
      onClick={() => dispatch(changeMobilePanel(mobilePanel))}
    >
      {label}
    </button>
  );
};

const MobileNavigation = () => {
  const mobilePanel = useSelector(
    state => state.mobile.mobilePanel,
    shallowEqual
  );

  return (
    <nav className="MobileNavigation">
      <div className="MobileNavigation-left">
        <NavigationItem
          label="sources"
          mobilePanel="sources"
          selected={"sources" === mobilePanel}
        />
      </div>
      <div className="MobileNavigation-right">
        <NavigationItem
          label="Selected Data"
          mobilePanel="modal"
          selected={"modal" === mobilePanel}
        />
        <NavigationItem
          label="map"
          mobilePanel="map"
          selected={"map" === mobilePanel}
        />
      </div>
    </nav>
  );
};

export default MobileNavigation;
