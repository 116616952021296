import React, { Component } from "react";
import { Route, withRouter } from "react-router";
import ReactResizeDetector from "react-resize-detector";
import { connect } from "react-redux";
import moment from "moment";
import { fetchTrailcamImages } from "../../actions/async/trailcam";
import { selectTrailcam } from "../../actions/trailcam";
import { slugify, trailcamUrlDateString } from "../../constants/app";
import { hasRole, ROLE_VIEW_PROTECTED_BUCKEYECAM } from "../../constants/auth";
import push from "../../constants/push";
import "./TrailcamModal.scss";
import GraphDateTime from "./GraphDateTime";
import ImageList from "./ImageList";
import * as d3 from "d3";

const mapStateToProps = state => ({
  auth: state.auth,
  trailcams: state.trailcams,
  timeline: state.timeline
});

const mapDispatchToProps = dispatch => ({
  fetchImages: (bounds, camSlug, canViewProtected = false) => {
    dispatch(fetchTrailcamImages(bounds, camSlug, canViewProtected));
  },
  goToDate: (cam, date) => {
    dispatch(
      push(
        `/trailcams/${cam}/${encodeURIComponent(
          moment(date).format(trailcamUrlDateString)
        )}`
      )
    );
  },
  goToPhoto: (cam, date, photo) => {
    dispatch(push(`/trailcams/${cam}/${date}/${photo.filename}`));
  },
  goToOverview: date => {
    dispatch(selectTrailcam("none"));
    dispatch(push(`/trailcams/by-category/${date}`));
  },
  selectTrailcam: trailcam => dispatch(selectTrailcam(trailcam))
});

class TrailcamModal extends Component {
  static selectCurrentTrailcam(props) {
    if (!(props.trailcams && props.trailcams.points)) return null;
    const slug = props.match.params.cam;
    const match = props.trailcams.points.features.filter(
      feature => slugify(feature.properties.text) === slug
    )[0];
    if (match && !match.properties._actionMapSelected) {
      props.selectTrailcam(match.properties.text);
    }
  }

  constructor(props) {
    super(props);

    this.state = {
      start: null
    };
  }

  componentDidMount() {
    this.props.fetchImages(
      this.props.timeline.bounds,
      this.props.match.params.cam,
      this.canViewProtected()
    );
    TrailcamModal.selectCurrentTrailcam(this.props);
  }

  componentDidUpdate(prevProps) {
    // NB: Go to date if images have changed and a specific filename is not
    // selected. If a filename is selected, we don't go to the date as this can
    // lead to redirecting away from the image. This can happen specifically
    // when following a direct link to an image.
    if (
      !this.props.match.params.filename &&
      ((this.props.trailcams.images && !prevProps.trailcams.images) ||
        (this.props.trailcams.images &&
          prevProps.trailcams.images &&
          this.props.trailcams.images.length !==
            prevProps.trailcams.images.length))
    ) {
      const maxDate = d3.max(this.props.trailcams.images.map(i => i.date));
      this.props.goToDate(this.props.match.params.cam, maxDate);
    }

    if (
      this.props.match.params.cam !== prevProps.match.params.cam ||
      this.props.timeline.bounds !== prevProps.timeline.bounds
    ) {
      this.props.fetchImages(
        this.props.timeline.bounds,
        this.props.match.params.cam,
        this.canViewProtected()
      );
      this.props.goToDate(
        this.props.match.params.cam,
        Math.max(this.props.timeline.bounds[0], this.props.timeline.bounds[1])
      );
    }

    if (
      this.props.match.params.cam !== prevProps.match.params.cam ||
      this.props.trailcams.points !== prevProps.trailcams.points
    ) {
      TrailcamModal.selectCurrentTrailcam(this.props);
    }
  }

  canViewProtected() {
    return hasRole(this.props.auth, ROLE_VIEW_PROTECTED_BUCKEYECAM);
  }

  render() {
    let text = "No photos for this time period";

    if (this.props.trailcams.images && this.props.trailcams.images[0]) {
      text = this.props.trailcams.images[0].text;
      if (text === "") text = this.props.trailcams.images[0].id;
    } else if (
      this.props.trailcams.images &&
      this.props.trailcams.images.length === 0 &&
      !this.canViewProtected()
    ) {
      text = "No photos. This camera may be offline for the hunting season.";
    }

    const { params } = this.props.match;
    const endDate = params.date
      ? moment(params.date, trailcamUrlDateString)
      : moment(this.props.timeline.bounds[0]);

    return (
      <div className="TrailcamModal IndividualTrailcamModal">
        <header>
          <div className="title">{text}</div>
          <div
            className="opt"
            onClick={() => this.props.goToOverview(params.date)}
          >
            &larr; Back to Overview
          </div>
        </header>
        <Route
          path="/trailcams/:cam/:date?"
          render={props => (
            <ReactResizeDetector
              handleWidth
              render={({ width, height }) => (
                <React.Fragment>
                  <GraphDateTime
                    data={this.props.trailcams.images || []}
                    width={width}
                    height={250}
                    onClick={d => this.props.goToDate(params.cam, d)}
                    start={endDate}
                  />
                  <ImageList
                    end={endDate}
                    width={width}
                    images={this.props.trailcams.images || []}
                    callback={d => this.props.goToDate(params.cam, d)}
                    goToPhoto={photo => {
                      this.props.goToPhoto(params.cam, params.date, photo);
                    }}
                  />
                </React.Fragment>
              )}
            />
          )}
        />
      </div>
    );
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(TrailcamModal)
);
