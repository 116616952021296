import { singleLineString } from "../constants/app.js";
import * as config from "../constants/config.js";

export const fetchSql = (sql, format) => {
  const encodedSql = singleLineString([sql]);
  const url = `${config.sql_endpoint}format=${format}&q=${encodedSql}`;
  return fetch(url).then(response => response.json());
};

export const fetchSqlPost = (sql, format) => {
  const encodedSql = singleLineString([sql]);
  const url = `${config.sql_endpoint.split("?")[0]}`;

  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
    },
    referrerPolicy: "no-referrer",
    body: `q=${encodedSql}&format=${format}`
  }).then(response => response.json());
};
