import React, { Component } from "react";
import "./BlockedLivecamVideo.scss";

export class BlockedLivecamVideo extends Component {
  render() {
    return (
      <div className="blocked-livecam-video">
        <div>This camera is offline for the hunting season.</div>
      </div>
    );
  }
}
