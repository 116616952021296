import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, withRouter } from "react-router";
import * as actions from "../../constants/action_names.js";
import push from "../../constants/push.js";
import "./BiocrustModal.scss";

class ValueFilter extends Component {
  render() {
    return (
      <div className="filter">
        {this.props.values.map(l => (
          <div
            onClick={() => {
              this.props.setFilter(l);
            }}
            key={l}
            className={
              "opt " + (this.props.filter === l ? "active" : "inactive")
            }
          >
            {l.charAt(0).toUpperCase() + l.slice(1)}
          </div>
        ))}
      </div>
    );
  }
}

class ViewFilter extends Component {
  render() {
    const categories = [
      "bryophyte cover",
      "bryophyte richness",
      "lichen cover",
      "lichen richness"
    ];

    return (
      <React.Fragment>
        <div className="category-filter">Select predicted attribute</div>

        <ValueFilter
          values={categories}
          filter={this.props.biocrust.filter}
          setFilter={this.props.setFilter.bind(this)}
        />
      </React.Fragment>
    );
  }
}

class BiocrustModal extends Component {
  render() {
    return (
      <div className="BiocrustModal">
        <div className="title">Ground Cover predictions</div>
        <div className="description">
          <p>
            Using multispectral imagery and machine learning, Kyle Doherty, a
            Ph.D candidate at Northern Arizona University, has predicted
            biocrust cover across MPG Ranch.
          </p>
        </div>

        <Route
          path={"/biocrust"}
          exact={true}
          render={() => (
            <ViewFilter
              biocrust={this.props.biocrust}
              setFilter={this.props.setFilter.bind(this)}
            />
          )}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  biocrust: state.biocrust
});

const mapDispatchToProps = dispatch => ({
  setFilter: filter =>
    dispatch({
      type: actions.BIOCRUST_SET_FILTER,
      filter,
      pushToUrl: { biocrustfilter: filter }
    }),
  goBack: () => dispatch(push("/biocrust"))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BiocrustModal)
);
