import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import ShareButton from "./ShareButton";
import { layers } from "../../constants/layers.js";
import { categories } from "../../constants/categories.js";
import { setActiveLayers } from "../../actions/layer_actions.js";
import push from "../../constants/push.js";
import helpIcon from "../../resources/icons/help.svg";
import InfoButton from "./InfoButton";
import "./LayerSelector.scss";

const mapStateToProps = state => ({
  layerSelector: state.layerSelector,
  router: state.router,
  timeline: state.timeline
});

const mapDispatchToProps = dispatch => ({
  activate: (layerName, previousLayers, bounds) => {
    let newLayers = [...previousLayers];

    if (newLayers.indexOf(layerName) < 0) {
      newLayers.push(layerName);
    }

    const layer = layers[layerName];
    dispatch(setActiveLayers(newLayers));

    if (layer.timeline && layer.timeline.action) {
      dispatch(layer.timeline.action());
    }

    if (layer.map && layer.map.action) {
      if (Array.isArray(layer.map.action)) {
        layer.map.action.forEach(a => dispatch(a(bounds)));
      } else {
        dispatch(layer.map.action(bounds));
      }
    }

    dispatch(push("/" + layerName));
  }
});

const LayerTitle = ({ active, layer, layerName, onClick, disabled }) => {
  return (
    <div
      onClick={disabled ? null : onClick}
      className={classNames("layer-title", { active, disabled })}
      id={`${layerName}-layer`}
    >
      <span className="layer-indicator" />
      <span className="layer-name">{layer.name}</span>
    </div>
  );
};

class LayerSelector extends Component {
  toggle(l) {
    if (!(this.props.layerSelector.active.indexOf(l) >= 0)) {
      this.props.activate(
        l,
        this.props.layerSelector.active,
        this.props.timeline.bounds
      );
    } else {
      this.props.setLayerInactive(
        l,
        this.props.layerSelector.active,
        this.props.router.location.pathname
      );
    }
  }

  render() {
    const menu = categories.map(c => {
      return (
        <div className="category" key={"layer_selector_" + c.name}>
          <div className="category-header">
            <img
              alt={c.name + " icon"}
              className="category-image"
              src={require("./icons/" + c.icon).default}
            />
            <div className="category-title">{c.name}</div>
          </div>
          {c.layers.map(l => (
            <LayerTitle
              active={this.props.layerSelector.active.indexOf(l) >= 0}
              key={"layer_selector_" + l}
              layer={layers[l]}
              layerName={l}
              onClick={() => this.toggle(l)}
              disabled={layers[l].inactive}
            />
          ))}
        </div>
      );
    });

    return (
      <React.Fragment>
        <div className="info">
          <img
            src={require("../../resources/MPG_logo.png").default}
            alt="MPG logo"
          />
        </div>
        {menu}
        <div className="footer">
          <ShareButton />
          <InfoButton />
          <div
            className="help"
            onClick={() => localStorage.setItem("skip-joyride", false)}
          >
            <a href={process.env.PUBLIC_URL}>
              <img src={helpIcon} alt="Restart tour" />
            </a>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LayerSelector);
