import React, { Component } from "react";
import copy from "clipboard-copy";
import shareIcon from "../../resources/icons/share.svg";

class ShareButton extends Component {
  state = {
    copied: false
  };

  componentDidUpdate() {
    if (this.state.copied) {
      window.setTimeout(() => {
        this.setState({ copied: false });
      }, 500);
    }
  }

  render() {
    return (
      <div
        className="share"
        onClick={() => {
          copy(window.location);
          this.setState({ copied: true });
        }}
      >
        <img src={shareIcon} alt="Share" />
        <div className={"copied " + (this.state.copied ? "visible" : "")}>
          <div>Copied to clipboard</div>
        </div>
      </div>
    );
  }
}

export default ShareButton;
