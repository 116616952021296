import React, { Component } from "react";
import { connect } from "react-redux";
import { Route } from "react-router";
import moment from "moment";
import { withRouter } from "react-router";
import { fetchRestorationReports } from "../../actions/async/restoration";
import { selectManagementUnit } from "../../actions/restoration";
import { slugify } from "../../constants/app";
import "./RestorationModal.scss";
import showdown from "showdown";
import push from "../../constants/push.js";

const mapStateToProps = state => ({
  restoration: state.restoration,
  timeline: state.timeline
});

const mapDispatchToProps = dispatch => ({
  fetchReports: (bounds, mu) => {
    dispatch(fetchRestorationReports(bounds, mu));
  },
  selectManagementUnit: managementUnit => {
    dispatch(selectManagementUnit(managementUnit));
  },
  goToAll: () => {
    dispatch(push("/restoration"));
  }
});

class RestorationReport extends Component {
  constructor(props) {
    super(props);

    this.converter = new showdown.Converter();
  }

  shouldComponentUpdate(nextProps) {
    if (
      this.props.report.report_id !== nextProps.report.report_id ||
      this.props.displayMu !== nextProps.displayMu
    ) {
      return true;
    }

    return false;
  }

  render() {
    let report_data;

    if (
      this.props.report.report_type === "action" ||
      this.props.report.report_type === "plan"
    ) {
      report_data = JSON.parse(
        decodeURIComponent(this.props.report.report_data)
      );
    } else {
      report_data = [
        {
          slideTitle: this.props.report.report_data,
          imgToken: this.props.report.img_token,
          slideText: this.props.report.img_caption
        }
      ];
    }

    if (report_data.length > 1) {
      report_data = report_data.filter(s => s.slideType !== "title");
    }

    const report = report_data.map((d, i) => (
      <div
        className={
          "slide " + (d.slideType === "title" ? "titleSlide" : d.slideType)
        }
        key={"slide_" + i + d.slideText}
      >
        {d.slideType === "img-only-landscape" ||
        d.slideType === "img-only-portrait" ? null : (
          <div className={"slideInner" + (d.imgToken ? "" : " noImage")}>
            <div
              className="slideTitle"
              dangerouslySetInnerHTML={{ __html: d.slideTitle }}
            />
            {d.slideText.trim() !== "" ? (
              <div
                className="description"
                dangerouslySetInnerHTML={{
                  __html: this.converter.makeHtml(d.slideText)
                }}
              />
            ) : null}
          </div>
        )}
        {d.imgToken ? (
          <img
            src={`https://mpgcloud.egnyte.com/dd/${d.imgToken}/?thumbNail=1&w=1200&h=1200&type=proportional&preview=true`}
            key={d.imgToken}
            alt={d.imgToken}
          />
        ) : null}
      </div>
    ));

    return (
      <div className="RestorationReport">
        <div className="intro">
          <div className="author">
            {decodeURIComponent(this.props.report.report_authors)}
          </div>
          <div className="single-line">
            <div className="date">
              {moment(this.props.report.report_date).format("MMMM DD, YYYY")}
            </div>
            <div className="title">
              {decodeURIComponent(this.props.report.report_name)}
            </div>
          </div>
          {this.props.displayMu ? (
            <div className="mu">
              {"Management Unit: " + this.props.report.report_mu}
            </div>
          ) : null}
        </div>
        {report}
      </div>
    );
  }
}

class UpdateList extends Component {
  static getCurrentManagementUnit(props) {
    if (!(props.restoration && props.restoration.points)) return null;
    const slug = props.mu;
    const match = props.restoration.points.features.filter(
      feature => slugify(feature.properties.report_mu) === slug
    );
    return match.length > 0 ? match[0] : null;
  }

  static selectCurrentManagementUnit(props) {
    const managementUnit = UpdateList.getCurrentManagementUnit(props);
    if (managementUnit && !managementUnit.properties._actionMapSelected) {
      props.selectManagementUnit(managementUnit.properties.report_mu);
    }
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.restoration.reports) {
      return true;
    }

    if (
      this.props.mu !== nextProps.mu ||
      this.props.restoration.reports.length !==
        nextProps.restoration.reports.length ||
      this.props.timeline.bounds[0] !== nextProps.timeline.bounds[0] ||
      this.props.timeline.bounds[1] !== nextProps.timeline.bounds[1] ||
      this.props.restoration.points !== nextProps.restoration.points ||
      (this.props.restoration.points &&
        nextProps.restoration.points &&
        this.props.restoration.points.features.length !==
          nextProps.restoration.points.features.length)
    ) {
      return true;
    }

    return false;
  }

  componentDidMount() {
    this.props.fetchReports(this.props.timeline.bounds, this.props.mu);
    UpdateList.selectCurrentManagementUnit(this.props);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.mu !== prevProps.mu ||
      this.props.timeline.bounds !== prevProps.timeline.bounds
    ) {
      this.props.fetchReports(this.props.timeline.bounds, this.props.mu);
    }

    UpdateList.selectCurrentManagementUnit(this.props);
  }

  render() {
    const startDate = moment(this.props.bounds[0]).format("MMM YYYY");
    const endDate = moment(this.props.bounds[1]).format("MMM YYYY");

    let updateCount = this.props.restoration.reports
      ? this.props.restoration.reports.length
      : 0;

    let displayMu = false;

    if (this.props.restoration.reports) {
      let mus = this.props.restoration.reports.map(r => r.report_mu);

      displayMu = !mus.reduce((acc, cur, i) => {
        return acc & (i > 0 ? cur === mus[i - 1] : true);
      }, true);
    }

    if (this.props.restoration.reports && this.props.restoration.reports[0]) {
      return (
        <div className="RestorationReports">
          <div className="subheader">
            <div className="header">
              {`${
                this.props.restoration.reports
                  ? this.props.mu === "all"
                    ? "All"
                    : this.props.restoration.reports[0].report_mu
                  : ""
              } Updates`}
            </div>
            <div className="overview">
              {`${startDate}-${endDate} • ${updateCount} updates`}
            </div>
            <div
              className={
                "back opt" + (this.props.mu === "all" ? " hidden" : "")
              }
              onClick={this.props.goToAll}
            >
              &lt; All Locations
            </div>
          </div>
          {this.props.restoration.reports
            ? this.props.restoration.reports.map(r => (
                <RestorationReport
                  report={r}
                  key={r.report_id}
                  displayMu={displayMu}
                />
              ))
            : null}
        </div>
      );
    } else {
      return <div>No reports in this management area.</div>;
    }
  }
}

class RestorationModal extends Component {
  shouldComponentUpdate(nextProps) {
    if (!this.props.restoration.reports) {
      return true;
    }

    if (
      this.props.match.params.mu !== nextProps.match.params.mu ||
      this.props.restoration.reports.length !==
        nextProps.restoration.reports.length ||
      this.props.timeline.bounds[0] !== nextProps.timeline.bounds[0] ||
      this.props.timeline.bounds[1] !== nextProps.timeline.bounds[1] ||
      this.props.restoration.points !== nextProps.restoration.points ||
      (this.props.restoration.points &&
        nextProps.restoration.points &&
        this.props.restoration.points.features.length !==
          nextProps.restoration.points.features.length)
    ) {
      return true;
    }

    return false;
  }

  render() {
    return (
      <div className="RestorationModal">
        <div className="title">Habitat restoration updates</div>
        <p>
          MPG strives to preserve the natural communities that make this area
          beautiful and focuses on research to restore and protect native
          diversity. This layer describes some of MPG's ongoing management
          plans, actions, and habitat restoration research. Select a specific
          time period or management unit to view detailed reports. For more
          information, visit the{" "}
          <a href="https://restorationmap.mpgranch.com/">
            MPG Habitat Restoration Map
          </a>
          .
        </p>

        <Route
          path="/restoration/:mu?"
          render={props => {
            return (
              <UpdateList
                mu={props.match.params.mu || "all"}
                fetchReports={this.props.fetchReports}
                timeline={this.props.timeline}
                restoration={this.props.restoration}
                selectManagementUnit={this.props.selectManagementUnit}
                goToAll={
                  props.match.params.mu
                    ? () => {
                        this.props.selectManagementUnit("all");
                        this.props.goToAll();
                      }
                    : null
                }
                bounds={this.props.timeline.bounds}
              />
            );
          }}
        />
      </div>
    );
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RestorationModal)
);
