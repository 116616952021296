import * as actions from "../constants/action_names";

export const selectInaturalistObservation = observation => {
  return dispatch => {
    dispatch({
      type: actions.INATURALIST_SELECT,
      observation
    });
  };
};
