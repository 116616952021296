import * as actions from "../constants/action_names.js";
import queryString from "query-string";

const query = queryString.parse(window.location.href.split("?")[1] || "");

const initialState = {
  filter: query.ebirdfilter ? query.ebirdfilter : null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.EBIRD_OVERVIEW_REQUEST:
      return {
        ...state,
        overview: null
      };
    case actions.EBIRD_OVERVIEW_RESPONSE:
      return {
        ...state,
        overview: action.json,
        selected: action.selected
      };
    case actions.EBIRD_OVERVIEW_ERROR_RESPONSE:
      return {
        ...state,
        overview: action.error
      };
    case actions.EBIRD_POINTS_RESPONSE:
      return {
        ...state,
        points: action.geojson
      };
    case actions.EBIRD_DETAIL_RESPONSE:
      return {
        ...state,
        species: action.species,
        speciesSite: action.speciesSite,
        families: action.families
      };
    case actions.EBIRD_SET_FILTER:
      return {
        ...state,
        filter: action.filter
      };
    case actions.EBIRD_SELECT:
      return {
        ...state,
        points: {
          ...state.points,
          features: state.points.features.map(feature => {
            return {
              ...feature,
              properties: {
                ...feature.properties,
                _actionMapSelected:
                  feature.properties.loc_id === action.location
              }
            };
          })
        }
      };
    default:
      return state;
  }
};
