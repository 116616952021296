import React from "react";
import * as d3 from "d3";
import { getCorrectedDegrees, headingPath } from "./LivecamLayer";
import "./LivecamMiniMap.scss";

export const LivecamMiniMap = ({ boundaries, livecam, property, width }) => {
  const unitName = property === "mpg" ? "MPG Ranch" : "MPG North";

  // Get latest boundary for the given property
  const boundary = boundaries.features
    .filter(b => b.properties.name === unitName)
    .sort((a, b) => {
      return b.properties.valid_start.localeCompare(a.properties.valid_start);
    })[0];

  const height = 300;
  const projection = d3
    .geoMercator()
    .fitExtent([[0, 0], [width, height]], boundary);

  const { coordinates } = livecam.geometry;
  const projectedCoordinates = projection(coordinates);

  const { pan, deg_to_north } = livecam.properties;
  const heading = getCorrectedDegrees(pan, deg_to_north);

  return (
    <div className="livecam-minimap">
      {width && height ? (
        <svg
          preserveAspectRatio="xMidYMid meet"
          viewBox={`0 0 ${width} ${height}`}
        >
          {boundary ? (
            <g>
              <path
                className="livecam-minimap-boundary"
                d={d3.geoPath(projection)(boundary)}
              />
              {projectedCoordinates ? (
                <React.Fragment>
                  <circle
                    className="livecam-minimap-livecam-circle"
                    r={8}
                    cx={projectedCoordinates[0]}
                    cy={projectedCoordinates[1]}
                  />
                  <path
                    className="livecam-minimap-heading"
                    d={headingPath(15, heading, 120)}
                    transform={`translate(${projectedCoordinates[0]}, ${
                      projectedCoordinates[1]
                    })`}
                  />
                </React.Fragment>
              ) : null}
            </g>
          ) : null}
        </svg>
      ) : null}
    </div>
  );
};
