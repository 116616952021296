const AXIS_VIDEO_PATH = "mjpg/video.mjpg";
const AXIS_THERMAL_VIDEO_PATH = "mjpg/video.mjpg?camera=2";
const AXIS_IMAGE_PATH = "axis-cgi/jpg/image.cgi";
const AXIS_MOTOR_PATH = "axis-cgi/com/ptz.cgi";
const AXIS_ZOOM_SPEED = 100;
const AXIS_PAN_SPEED = 20;

const parsePairs = (text, splitter) => {
  if (!text) return [];
  let lines = text.split(splitter),
    tokens,
    out = {};

  lines.forEach(line => {
    tokens = line.split("=");
    if (tokens.length === 2) {
      out[tokens[0]] = tokens[1];
    }
  });

  return out;
};

function parseResponse(response) {
  /*
   * Data looks like:
   * a=3.4
   * b=2.34
   *
   * Returns a dictionary of values.
   */
  let out = parsePairs(response, "\n");
  let presets = Object.keys(out)
    .sort()
    .filter(k => k.startsWith("presetposno"))
    .map(k => {
      return {
        id: k.split("presetposno")[1],
        name: out[k]
      };
    });

  return {
    focus: parseFloat(out.focus),
    focusRange: [parseFloat(out.MinFocus), parseFloat(out.MaxFocus)],
    presets: presets,
    pan: parseFloat(out.pan),
    tilt: parseFloat(out.tilt),
    zoom: parseFloat(out.zoom),
    zoomRange: [parseFloat(out.MinZoom), parseFloat(out.MaxZoom)]
  };
}

class AxisCamera {
  constructor(host) {
    this.host = host;
  }

  get videoUrl() {
    return `${this.host}/${AXIS_VIDEO_PATH}`;
  }

  get thermalVideoUrl() {
    return `${this.host}/${AXIS_THERMAL_VIDEO_PATH}`;
  }

  buildUrl(queryString) {
    return `${this.host}/${AXIS_MOTOR_PATH}?${queryString}`;
  }

  request(queryString) {
    return fetch(this.buildUrl(queryString));
  }

  query() {
    return fetch(this.buildUrl("query=limits,position,presetposall"))
      .then(response => response.text())
      .then(parseResponse);
  }

  goToPreset(preset) {
    return this.request(`gotoserverpresetno=${preset}`);
  }

  center(x, y, width, height) {
    return this.request(
      `center=${x},${y}&imagewidth=${width}&imageheight=${height}`
    );
  }

  centerAndZoom(x, y, width, height, factor) {
    return this.request(
      `areazoom=${x},${y},${factor}&imagewidth=${width}&imageheight=${height}`
    );
  }

  zoomIn() {
    return this.request(`continuouszoommove=${AXIS_ZOOM_SPEED}`);
  }

  zoomOut() {
    return this.request(`continuouszoommove=-${AXIS_ZOOM_SPEED}`);
  }

  stopZoom() {
    return this.request(`continuouszoommove=0`);
  }

  zoomTo(zoom) {
    return this.request(`zoom=${zoom}`);
  }

  focusTo(focus) {
    return this.request(`focus=${focus}`);
  }

  left() {
    return this.request(`continuouspantiltmove=-${AXIS_PAN_SPEED},0`);
  }

  right() {
    return this.request(`continuouspantiltmove=${AXIS_PAN_SPEED},0`);
  }

  down() {
    return this.request(`continuouspantiltmove=0,-${AXIS_PAN_SPEED}`);
  }

  up() {
    return this.request(`continuouspantiltmove=0,${AXIS_PAN_SPEED}`);
  }

  stopPanTilt() {
    return this.request(`continuouspantiltmove=0,0`);
  }
}

export const liveCamImageURL = (host, thermal = false) => {
  const timestamp = Math.round(new Date().getTime() / 10000);
  let url = `${host}/${AXIS_IMAGE_PATH}?${timestamp}`;
  if (!thermal) {
    url += "&resolution=320x180";
  }
  return url;
};

export const getCamera = ({ host }) => {
  return new AxisCamera(host);
};
