import React from "react";
import * as d3 from "d3";

const AerialTimeline = ({ data, scale, size }) => {
  const lineGenerator = d3
    .line()
    .x(d => scale(d))
    .y(d => size.height / 2);

  const lineData = data.map(d => [d.start, d.end]);

  return (
    <>
      {lineData.map(line => (
        <path key={line[0].format()} stroke="gray" d={lineGenerator(line)} />
      ))}
    </>
  );
};

export default AerialTimeline;
