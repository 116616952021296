import React, { Component } from "react";
import copy from "clipboard-copy";
import FullscreenModal from "../modals/FullscreenModal";
import infoIcon from "../../resources/icons/info.svg";
import stamen from "./stamen.svg";

class InfoButton extends Component {
  constructor(props) {
    super(props);

    this.state = { open: false };
  }

  close() {
    this.setState({ open: false }, () => console.log("state sett"));
  }

  render() {
    return (
      <div
        className="info"
        onClick={() => {
          copy(window.location);
          if (!this.state.open) this.setState({ open: true });
        }}
      >
        <img src={infoIcon} alt="Info" />
        {this.state.open ? (
          <FullscreenModal onClose={this.close.bind(this)}>
            <div className="info-box">
              <div className="logos">
                <div className="mpg">
                  <img
                    src={require("../../resources/MPG_logo.png").default}
                    alt="MPG logo"
                  />
                </div>
                <div className="stamen">
                  <img src={stamen} alt="Stamen logo" />
                </div>
              </div>
              <p>
                With more than 15,000 acres of conservation property in Western
                Montana, <a href="https://www.mpgranch.com/">MPG Ranch</a>{" "}
                stewards large, undeveloped landscapes for wildlife habitat and
                the enjoyment of future generations.
              </p>
              <p>
                The MPG Live Map, developed in collaboration with{" "}
                <a href="http://stamen.com">Stamen Design</a>, provides a
                uniquely detailed view into the landscape, biodiversity and
                ongoing research projects on MPG Ranch.
              </p>
              <p>
                This map is currently in beta. If you encounter a bug or
                unexpected behavior, please report it to{" "}
                <a href="mailto:mpg@stamen.com">mpg@stamen.com</a> so that it
                can be addressed.
              </p>
            </div>
          </FullscreenModal>
        ) : null}
      </div>
    );
  }
}

export default InfoButton;
