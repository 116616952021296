import * as actions from "../constants/action_names";

export const selectEBirdLocation = location => {
  return dispatch => {
    dispatch({
      type: actions.EBIRD_SELECT,
      location
    });
  };
};
