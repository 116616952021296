import * as actions from "../constants/action_names.js";
import queryString from "query-string";

const query = queryString.parse(window.location.href.split("?")[1] || "");

let initialState = {
  active: query.layers
    ? Array.isArray(query.layers)
      ? query.layers
      : [query.layers]
    : [],
  opacity: {}
};

initialState.active.forEach(l => {
  if (query[l + "Opacity"]) initialState.opacity[l] = query[l + "Opacity"];
});

export default (state = initialState, action) => {
  let newState = { ...state };
  switch (action.type) {
    case actions.SET_ACTIVE_LAYERS:
      return { ...newState, active: action.layers };
    case actions.SET_LAYER_OPACITY:
      return {
        ...newState,
        opacity: { ...newState.opacity, ...action.opacityUpdate }
      };
    case actions.UNSET_LAYER_OPACITY:
      delete newState.opacity[action.layer];
      return newState;
    default:
      return state;
  }
};
