import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, withRouter } from "react-router";
import { selectVideo } from "../../actions/video";
import { slugify } from "../../constants/app";
import push from "../../constants/push";
import AllVideosModal from "./AllVideosModal";
import IndividualVideoModal from "./IndividualVideoModal";

const mapStateToProps = state => ({
  video: state.video
});

const mapDispatchToProps = dispatch => ({
  selectVideo: video => dispatch(selectVideo(video)),

  onClose: (camera, date) => {
    dispatch(push(`/videos/`));
  },

  push: url => dispatch(push(url))
});

class VideoModal extends Component {
  render() {
    return (
      <>
        <Route
          path="/videos"
          render={() => (
            <AllVideosModal
              videos={
                this.props.video && this.props.video.points
                  ? this.props.video.points.features
                  : []
              }
              selectVideo={name => {
                this.props.push(`/videos/${slugify(name)}`);
              }}
            />
          )}
        />

        <Route
          path="/videos/:video"
          render={props => (
            <IndividualVideoModal
              onClose={this.props.onClose}
              selectVideo={this.props.selectVideo}
              video={this.props.video}
              videoSlug={props.match.params.video}
            />
          )}
        />
      </>
    );
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(VideoModal)
);
