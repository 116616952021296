import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import ReactResizeDetector from "react-resize-detector";
import { fetchSoilData } from "../../actions/async/soil";
import { selectSoilStation } from "../../actions/soil";
import { slugify } from "../../constants/app";
import { SoilChart } from "./SoilChart";
import "./SoilModal.scss";

const mapStateToProps = state => ({
  soil: state.soil,
  timeline: state.timeline
});

const mapDispatchToProps = dispatch => ({
  fetchSoilData: (bounds, stationSlug) => {
    dispatch(fetchSoilData(bounds, stationSlug));
  },
  selectSoilStation: station => dispatch(selectSoilStation(station))
});

class SoilModal extends Component {
  static getCurrentSoilStation(props) {
    if (!(props.soil && props.soil.points)) return null;
    const slug = props.match.params.station;
    const match = props.soil.points.features.filter(
      feature => slugify(feature.properties.name) === slug
    );
    return match.length > 0 ? match[0] : null;
  }

  static selectCurrentSoilStation(props) {
    const station = SoilModal.getCurrentSoilStation(props);
    if (station && !station.properties._actionMapSelected) {
      props.selectSoilStation(station.properties.name);
    }
  }

  componentDidMount() {
    this.updateData();
    SoilModal.selectCurrentSoilStation(this.props);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.match.params.station !== prevProps.match.params.station ||
      this.props.timeline.bounds !== prevProps.timeline.bounds
    ) {
      this.updateData();
    }

    if (
      this.props.match.params.station !== prevProps.match.params.station ||
      this.props.soil.points !== prevProps.soil.points
    ) {
      SoilModal.selectCurrentSoilStation(this.props);
    }
  }

  updateData() {
    const { match, timeline } = this.props;
    this.props.fetchSoilData(timeline.bounds, match.params.station);
  }

  render() {
    const station = SoilModal.getCurrentSoilStation(this.props);
    const data = this.props.soil.data;
    const bounds = this.props.timeline.bounds;
    const dateFormat = "MMMM DD, YYYY";

    return (
      <div className="soil-modal">
        {station ? (
          <React.Fragment>
            <div className="soil-modal-header">
              <div className="title">{station.properties.name}</div>
              <div className="date-range">
                {moment(bounds[0]).format(dateFormat)} to{" "}
                {moment(bounds[1]).format(dateFormat)}
              </div>
            </div>
            {data && data.rows ? (
              <ReactResizeDetector
                handleWidth
                render={({ width, height }) => (
                  <SoilChart data={data.rows} height={height} width={width} />
                )}
              />
            ) : null}
          </React.Fragment>
        ) : null}
      </div>
    );
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SoilModal)
);
