import { withLeaflet } from "react-leaflet";
import { connect } from "react-redux";
import push from "../../constants/push.js";
import { slugify } from "../../constants/app.js";
import makeComponent from "../CritterMap/makeComponent.js";
import SvgLayer from "../CritterMap/SvgLayer";
import { pointColors } from "../../constants/layers_static.js";
import * as d3 from "d3";

class SoilLayer extends SvgLayer {
  updateData(data) {
    super.updateData(data);

    let rScale = d3
      .scaleSqrt()
      .domain([3, 0.7])
      .range([5, 20])
      .clamp(true);

    let d = this.g
      .selectAll("g")
      .data(this.data.features, d => d.properties.name);

    let g = d
      .enter()
      .append("g")
      .attr("class", "translategroup");

    g.append("circle")
      .attr("cy", 0)
      .attr("cx", 0)
      .style("fill", pointColors.soil)
      .style("mix-blend-mode", "overlay")
      .on("click", d => this.onClick(d.properties.name));

    const merged = g
      .merge(d)
      .attr("transform", this.translate.bind(this))
      .classed("selected", d => d.properties._actionMapSelected);

    merged
      .select("circle")
      .style("stroke", "#fff")
      .style("stroke-width", d => (d.properties._actionMapSelected ? 5 : 1))
      .style("opacity", d => (d.properties._actionMapSelected ? 1 : 0.5))
      .attr("r", d => rScale(Math.log10(Math.abs(d.properties.value || 0))));

    this.g.select(".selected").raise();

    d.exit().remove();
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  go: soilStationName => {
    dispatch(push("/soil/" + slugify(soilStationName)));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLeaflet(makeComponent(SoilLayer)));
