import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import ResizeDetector from "react-resize-detector";
import { Route, withRouter } from "react-router";
import Joyride from "react-joyride";
import TimelineContainer from "./components/timeline/TimelineContainer.js";
import CritterMap from "./components/CritterMap/CritterMap.js";
import LayerSelector from "./components/LayerSelector/LayerSelector.js";
import MobileNavigation from "./components/mobile/MobileNavigation";
import { layers } from "./constants/layers";
import { modalSizes } from "./constants/layers_static";
import push from "./constants/push.js";
import { fetchAuth } from "./actions/async/auth";
import { fetchRanchBoundary } from "./actions/async/ranch";
import { setActiveLayers, unsetLayerOpacity } from "./actions/layer_actions";
import { setModalRef } from "./actions/critterMap_actions";

import "./App.scss";

const mapStateToProps = state => state;

const mapDispatchToProps = dispatch => ({
  setLayerInactive(layerName, previousLayers, path) {
    let newLayers = previousLayers.filter(l => l !== layerName);

    dispatch(setActiveLayers(newLayers));
    dispatch(unsetLayerOpacity(layerName));

    // Only push root if this is the layer we're deactivating
    if (path.indexOf(`/${layerName}`) === 0) {
      dispatch(push("/"));
    }
  },

  activate: (layerName, previousLayers, bounds) => {
    let newLayers = [...previousLayers];

    if (newLayers.indexOf(layerName) < 0) {
      newLayers.push(layerName);
    }

    const layer = layers[layerName];
    dispatch(setActiveLayers(newLayers));

    if (layer.timeline && layer.timeline.action) {
      dispatch(layer.timeline.action());
    }

    if (layer.map && layer.map.action) {
      if (Array.isArray(layer.map.action)) {
        layer.map.action.forEach(a => dispatch(a(bounds)));
      } else {
        dispatch(layer.map.action(bounds));
      }
    }

    dispatch(push("/" + layerName));
  },

  fetchAuth: key => {
    dispatch(fetchAuth(key));
  },

  fetchRanchBoundary: () => {
    dispatch(fetchRanchBoundary());
  },

  fetchAppData: props => {
    const bounds = props.timeline.bounds;
    const activeLayers = props.layerSelector.active;

    activeLayers.forEach(layerName => {
      const layer = layers[layerName];
      const additionalArguments = layer.additionalAsyncArguments
        ? layer.additionalAsyncArguments(props)
        : [];

      if (layer.timeline && layer.timeline.action) {
        dispatch(layer.timeline.action(...additionalArguments));
      }

      if (layer.map && layer.map.action) {
        if (Array.isArray(layer.map.action)) {
          layer.map.action.forEach(a =>
            dispatch(a(bounds, ...additionalArguments))
          );
        } else {
          dispatch(layer.map.action(bounds, ...additionalArguments));
        }
      }
    });
  },

  goTo: path => {
    dispatch(push(path));
  },

  setModalRef: ref => {
    dispatch(setModalRef(ref));
  }
});

class App extends Component {
  constructor(props) {
    super(props);

    this.modalRef = React.createRef();

    this.state = {
      mobilePlaceholderDismissed: false,
      shouldJoyride: true,
      step: 0,
      steps: [
        {
          target: ".info",
          content:
            "Welcome to the MPG Live Map beta! This map has several new features. Follow along to learn more or skip to go straight to the map. The tour may be replayed later by pressing the help button.",
          disableBeacon: true,
          spotlightClicks: false,
          wait: () => false
        },
        {
          target: "#aerial-layer",
          content:
            "Many types of content and data are available to browse on the map. To continue, activate the aerial imagery layer by clicking on it.",
          wait: props => props.layerSelector.active.indexOf("aerial") < 0,
          waitText: 'Click "Aerial imagery"',
          placement: "right"
        },
        {
          target: "#birds-layer",
          content:
            "Let's add another layer. To continue, activate the eBird layer.",
          wait: props => props.layerSelector.active.indexOf("birds") < 0,
          waitText: 'Click "eBird"'
        },
        {
          target: ".Timeline",
          content:
            "The timeline shows selected layers and the time periods for which they contain data.",

          spotlightClicks: false,
          wait: () => false,
          placement: "top"
        },
        {
          target: ".timeline-selector",
          content: "Click and drag to filter map data by a time range.",
          wait: () => false,
          spotlightPadding: 20,
          placement: "top"
        },
        {
          target: ".drag-selector",
          content: "Click and drag on the handles to re-order layers.",
          wait: () => false,
          placement: "top"
        },
        {
          target: ".timeline-opacity",
          content:
            "Image layers have adjustable opacity — hover over the layer name to reveal the slider.",
          wait: () => false,
          placement: "top"
        },
        {
          target: ".timeline-label-container",
          content: "Hovering over any layer brings up a close button.",
          wait: () => false,
          placement: "top"
        },
        {
          target: ".Modal",
          content:
            "The right hand side of the Live Map displays additional information about the selected layer.",
          placement: "left",
          requiredPath: "/birds",
          requiredLayer: "birds",
          spotlightClicks: false,
          wait: () => false
        },
        {
          target: ".species",
          content:
            "Click an observation category to filter observations for the current layer. For this layer, eBird observations are categorized by family.",
          wait: props => !this.props.eBird.filter,
          placement: "left",
          waitText: "Click a filter category"
        },
        {
          target: "#birds-timeline-layer",
          content:
            "The timeline updates to show the selected category of data over time.",
          spotlightClicks: false,
          wait: () => false
        },
        {
          target: ".legend-container",
          content: "Legends are displayed on the bottom of map.",
          spotlightClicks: false,
          wait: () => false
        },
        {
          target: ".map-inset .leaflet-container",
          content: "Clicking the map inset switches to MPG North.",
          wait: () => false
        },
        {
          target: ".share",
          content:
            "Everything you see can be shared! Click the share button to copy a link to the current view.",
          wait: () => false
        },
        {
          target: ".help",
          content:
            "This tour will be hidden for future visits. To restart the tour, press the help button.",
          placement: "right",
          wait: () => false
        },
        {
          target: ".info",
          content:
            "Please explore and enjoy this beta map! Any bugs or issues can be reported to mpg@stamen.com and we will investigate.",
          wait: () => false
        }
      ]
    };
  }

  componentDidMount() {
    if (this.props.auth.key && this.props.auth.match == null) {
      this.props.fetchAuth(this.props.auth.key);
    }

    this.props.fetchRanchBoundary();
    this.props.fetchAppData(this.props);

    if (
      this.props.location.search !== "" ||
      localStorage.getItem("skip-joyride") === "true" ||
      window.innerWidth <= 480
    ) {
      this.setState({ shouldJoyride: false });
    }

    this.props.setModalRef(this.modalRef);
  }

  joyrideCallback(state) {
    if (state.action === "skip") {
      localStorage.setItem("skip-joyride", true);
    }

    if (state.lifecycle === "complete") {
      if (state.index + 1 < this.state.steps.length) {
        if (this.state.steps[state.index + 1].requiredPath) {
          this.props.goTo(this.state.steps[state.index + 1].requiredPath);
        }

        if (this.state.steps[state.index + 1].requiredLayer) {
          this.props.activate(
            this.state.steps[state.index + 1].requiredLayer,
            this.props.layerSelector.active,
            this.props.timeline.bounds
          );
        }

        this.setState({ step: state.index + 1 });
      } else {
        localStorage.setItem("skip-joyride", true);
        this.setState({ shouldJoyride: false });
      }
    }
  }

  render() {
    const routes = this.props.layerSelector.active.map(l => (
      <Route
        path={"/" + l}
        render={layers[l].modal.component}
        key={"modal_route_" + l}
      />
    ));

    const { steps, step, shouldJoyride } = this.state;
    const currentStep = steps[step];

    return (
      <Route
        path={"/:activeModal?/:site?"}
        render={props => {
          let modalSize = this.props.critterMap.modalSize;

          if (!modalSize) {
            modalSize = props.match.params.activeModal
              ? modalSizes[props.match.params.activeModal]
              : "hidden";
          }

          if (modalSize.hasOwnProperty("site")) {
            modalSize = props.match.params.site
              ? modalSize.site
              : modalSize.nosite;
          }

          return (
            <ResizeDetector
              handleWidth
              render={({ width }) => (
                <React.Fragment>
                  <div className={`App`}>
                    {shouldJoyride ? (
                      <Joyride
                        steps={steps}
                        stepIndex={step}
                        showSkipButton
                        continuous
                        spotlightClicks
                        hideBackButton
                        showProgress
                        disableOverlayClose
                        disableCloseOnEsc
                        getHelpers={helpers => {
                          this.setState({ helpers });
                        }}
                        floaterProps={{
                          styles: {
                            wrapper: {
                              zIndex: 5000
                            }
                          }
                        }}
                        locale={{
                          back: "Back",
                          close: "Close",
                          last: "Last",
                          next: currentStep.wait(this.props)
                            ? currentStep.waitText
                            : "Next",
                          skip: "Skip"
                        }}
                        styles={{
                          options: {
                            zIndex: 5000
                          },
                          buttonClose: {
                            display: "none"
                          },
                          buttonNext: currentStep.wait(this.props)
                            ? {
                                backgroundColor: "darkgray",
                                cursor: "default",
                                pointerEvents: "none"
                              }
                            : {}
                        }}
                        callback={this.joyrideCallback.bind(this)}
                      />
                    ) : null}

                    <div className="content-container">
                      <MobileNavigation />
                      <div
                        id="grid-container"
                        className={`mobile-panel-${this.props.mobile.mobilePanel}`}
                      >
                        <div className="LayerSelector">
                          <LayerSelector
                            setLayerInactive={this.props.setLayerInactive}
                          />
                        </div>
                        <CritterMap />
                        <div
                          id="modal"
                          className={`Modal ${modalSize}`}
                          ref={this.modalRef}
                        >
                          {routes}
                        </div>
                        <ResizeDetector
                          handleWidth
                          render={({ width }) => (
                            <TimelineContainer
                              min={moment("2015-01-01")}
                              max={moment()}
                              size={{ width: width - 228 || 600, height: 50 }}
                              margins={{
                                left: 0,
                                right: 0,
                                top: 0,
                                bottom: 0
                              }}
                              setLayerInactive={this.props.setLayerInactive}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )}
            />
          );
        }}
      />
    );
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);
