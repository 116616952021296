import React, { Component } from "react";
import { GeoJSON } from "react-leaflet";
import { connect } from "react-redux";
import push from "../../constants/push.js";
import { slugify } from "../../constants/app.js";
import "./RestorationLayer.css";
import { pointColors } from "../../constants/layers_static.js";

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  go: managementUnit => {
    dispatch(push("/restoration/" + slugify(managementUnit)));
  }
});

class RestorationLayer extends Component {
  constructor(props) {
    super(props);

    // Get GeoJSON as a reference so we can interact with the Leaflet object
    this.geoJsonLayer = React.createRef();
  }

  onEachFeature(feature, layer) {
    layer.on({
      click: () => this.props.go(feature.properties.id)
    });
  }

  componentDidUpdate(prevProps) {
    // When the data updates, update the data on the GeoJSON layer
    if (this.props.data !== prevProps.data) {
      this.geoJsonLayer.current.leafletElement
        .clearLayers()
        .addData(this.props.data);
    }

    // this took way too long to figure out, but seems to be the only way of
    // updating the styles on the GeoJSON layer
    if (this.geoJsonLayer.current.leafletElement.getLayers()[0]) {
      this.geoJsonLayer.current.leafletElement.getLayers()[0]._renderer._container.style.zIndex = this.props.zIndex;
    }
  }

  render() {
    return (
      <GeoJSON
        ref={this.geoJsonLayer}
        data={this.props.data}
        style={feature => {
          let style = {
            weight: Math.min(8, feature.properties.count),
            fillColor: pointColors.restoration,
            fillOpacity: Math.min(0.5, feature.properties.count / 8),

            fill: feature.properties.id === "MPG Ranch" ? false : true,
            opacity: 1.0,
            color: pointColors.restoration
          };

          if (feature.properties._actionMapSelected) {
            style = {
              ...style,
              fillColor: "rgba(0, 0, 0, 1)",
              color: "black"
            };
          }
          return style;
        }}
        onEachFeature={this.onEachFeature.bind(this)}
        pane="markerPane"
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RestorationLayer);
