import * as actions from "../constants/action_names.js";

export default (state = { overview: { rows: [] } }, action) => {
  switch (action.type) {
    case actions.RAPTOR_OVERVIEW_RESPONSE:
      return {
        ...state,
        overview: action.json
      };
    case actions.RAPTOR_OVERVIEW_ERROR_RESPONSE:
      return {
        ...state,
        overview: action.error
      };
    case actions.RAPTOR_POINTS_RESPONSE:
      return {
        ...state,
        tracks: action.tracks,
        points: action.points
      };
    case actions.RAPTOR_POINTS_ERROR_RESPONSE:
      return {
        ...state
      };
    default:
      return state;
  }
};
