import * as actions from "../constants/action_names";

export const selectBeeSite = site => {
  return dispatch => {
    dispatch({
      type: actions.BEE_POINT_SELECT,
      site
    });
  };
};

export const setBeeFilter = filter => {
  return dispatch => {
    dispatch({
      type: actions.SET_BEE_FILTER,
      filter,
      pushToUrl: { beefilter: filter }
    });
  };
};
