import * as actions from "../constants/action_names.js";

export default (state = {}, action) => {
  switch (action.type) {
    case actions.VIDEO_OVERVIEW_RESPONSE:
      return {
        ...state,
        overview: action.json
      };
    case actions.VIDEO_OVERVIEW_ERROR_RESPONSE:
      return {
        ...state,
        error: action.error
      };
    case actions.VIDEO_POINTS_RESPONSE:
      return {
        ...state,
        _fetchingVideoPoints: true,
        points: action.geojson
      };
    case actions.VIDEO_POINTS_ERROR_RESPONSE:
      return {
        ...state,
        error: action.error
      };
    case actions.VIDEO_SELECT:
      return {
        ...state,
        points: {
          ...state.points,
          features: state.points.features.map(feature => {
            return {
              ...feature,
              properties: {
                ...feature.properties,
                _actionMapSelected: feature.properties.name === action.video
              }
            };
          })
        }
      };
    default:
      return state;
  }
};
