import React, { Component } from "react";
import { pointColors } from "../../constants/layers_static";
import PointLegend from "../SpeciesList/PointLegend";
import { filterGeodata } from "./BeeStatic";

class BeeLegend extends Component {
  render() {
    const { filter, points } = this.props;

    let { max } = points ? filterGeodata(filter, points) : 0;

    return max ? (
      <PointLegend
        max={max}
        label={"Number of bees collected"}
        color={pointColors.bees}
      />
    ) : (
      <div />
    );
  }
}

export default BeeLegend;
