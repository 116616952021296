import * as d3 from "d3";

export const categorical = d3.schemeDark2;

export const gradientScaleViridis = [
  "#440154",
  "#482475",
  "#414487",
  "#355f8d",
  "#2a788e",
  "#21908d",
  "#22a884",
  "#42be71",
  "#7ad151",
  "#bddf26",
  "#bddf26"
];

export const gradientScaleOne = ["#666096", "#004A57", "#05280A"];
