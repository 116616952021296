import * as d3 from "d3";
import { withLeaflet } from "react-leaflet";
import { connect } from "react-redux";
import push from "../../constants/push.js";
import makeComponent from "../CritterMap/makeComponent.js";
import SvgLayer from "../CritterMap/SvgLayer";
import { pointColors } from "../../constants/layers_static";
import { filterGeodata } from "./BeeStatic";
import { selectBeeSite } from "../../actions/bees.js";

class BeeLayer extends SvgLayer {
  constructor(opts) {
    super(opts);

    this.colorScale = d3.scaleOrdinal(d3.schemeCategory10);
    this.sizeScale = d3
      .scaleSqrt()
      .domain([0, 10])
      .range([0, 10]);
  }

  updateData(data, props) {
    super.updateData(data);

    const { features, max } = filterGeodata(props.bees.filter, data);

    let g = this.g.selectAll("circle").data(features, d => d.properties.id);

    let rScale = d3
      .scaleLinear()
      .range([5, 30])
      .domain([0, max]);

    let genter = g
      .enter()
      .append("circle")
      .attr("class", "translategroup")
      .on("click", d => {
        this.onClick(d.properties.id.replace(/\D/g, ""));
      })
      .attr("r", 0);

    g.merge(genter)
      .classed("selected", d => d.properties._actionMapSelected)
      .attr("transform", this.translate.bind(this))
      .style("opacity", d => (d.properties._actionMapSelected ? 0.8 : 0.6))
      .style("fill", pointColors.bees)
      .style("stroke-width", d => (d.properties._actionMapSelected ? 5 : 1))
      .transition()
      .duration(300)
      .attr("r", d => rScale(d.properties.cnt));

    this.g.select(".selected").raise();

    g.exit()
      .transition()
      .duration(300)
      .attr("r", 0)
      .remove();
  }
}

const mapStateToProps = state => ({
  bees: state.bees,
  timeline: state.timeline
});

const mapDispatchToProps = dispatch => ({
  go: obs => {
    dispatch(push("/bees/" + obs));
    dispatch(selectBeeSite(obs));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLeaflet(makeComponent(BeeLayer)));
