import React, { Component } from "react";
import { TileLayer } from "react-leaflet";
import { connect } from "react-redux";
import * as config from "../../constants/config.js";
import moment from "moment";

import { getImageryUrl } from "./imagery";

const mapStateToProps = state => ({
  timeline: state.timeline
});

const mapDispatchToProps = dispatch => ({});

class AerialLayer extends Component {
  render() {
    const bounds = this.props.timeline.bounds;
    const year = moment(bounds[0]).year();
    const endYear = moment(bounds[1]).year();
    const opacity =
      endYear === year
        ? 1
        : endYear > year
        ? Math.min(1, moment(bounds[1]).dayOfYear() / 365)
        : Math.max(0, 1 - moment(bounds[1]).dayOfYear() / 365);

    const globalOpacity = this.props.opacity || 1;

    let secondLayers = [];

    if (endYear >= year) {
      for (let y = year; y <= endYear; y++) {
        secondLayers.push(
          <TileLayer
            url={getImageryUrl(y)}
            opacity={(y === endYear ? opacity : 1) * globalOpacity}
            key={"aerial_layer_" + y + (y === year)}
            className={
              "aerial-layer" + (y === year ? "" : " aerial-layer-overlay")
            }
            detectRetina={true}
            maxNativeZoom={18}
            maxZoom={22}
            zIndex={this.props.style.zIndex}
            pane={"overlayPane"}
          />
        );
      }
    } else {
      for (let y = year; y >= endYear; y--) {
        secondLayers.push(
          <TileLayer
            url={getImageryUrl(y)}
            opacity={(y === endYear ? opacity : 1) * globalOpacity}
            key={"aerial_layer_" + y + (y === year)}
            className={
              "aerial-layer" + (y === year ? "" : " aerial-layer-overlay")
            }
            detectRetina={true}
            maxNativeZoom={18}
            maxZoom={22}
            zIndex={this.props.style.zIndex}
            pane={"overlayPane"}
          />
        );
      }
    }

    return (
      <React.Fragment>
        <TileLayer
          url={config.customAerialNorth2016}
          key="aerial_layer_north"
          className="aerial-layer north"
          detectRetina={true}
          maxNativeZoom={18}
          maxZoom={22}
          zIndex={this.props.style.zIndex}
          pane={"overlayPane"}
          opacity={globalOpacity}
        />
        {secondLayers}
      </React.Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AerialLayer);
