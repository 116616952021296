import { withLeaflet } from "react-leaflet";
import { connect } from "react-redux";
import push from "../../constants/push.js";
import { slugify } from "../../constants/app.js";
import makeComponent from "../CritterMap/makeComponent.js";
import SvgLayer from "../CritterMap/SvgLayer";
import { pointColors } from "../../constants/layers_static.js";

class VideoLayer extends SvgLayer {
  updateData(data) {
    super.updateData(data);

    let d = this.g.selectAll("circle").data(this.data.features, d => d.id);

    let g = d
      .enter()
      .append("circle")
      .attr("class", "translategroup")
      .on("click", d => this.onClick(d.properties.name));

    d.merge(g)
      .attr("transform", this.translate.bind(this))
      .attr("r", d => (d.properties._actionMapSelected ? 20 : 10))
      .attr("cy", 0)
      .attr("cx", 0)
      .style("fill", pointColors.video)
      .style("mix-blend-mode", d =>
        d.properties._actionMapSelected ? "normal" : "overlay"
      )
      .style("opacity", d => (d.properties._actionMapSelected ? 0.8 : 0.6))
      .style("stroke-width", d => (d.properties._actionMapSelected ? 5 : 1))
      .style("stroke", "white")
      .classed("selected", d => d.properties._actionMapSelected);

    this.g.select(".selected").raise();

    d.exit().remove();
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  go: video => {
    dispatch(push("/videos/" + slugify(video)));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLeaflet(makeComponent(VideoLayer)));
