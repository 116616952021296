import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { slugify, trailcamUrl } from "../../constants/app.js";
import push from "../../constants/push.js";
import FullscreenModal from "../modals/FullscreenModal";
import "./FullscreenTrailcamModal.scss";

const mapStateToProps = state => ({
  trailcams: state.trailcams
});

const mapDispatchToProps = dispatch => ({
  goToPhoto: (byCategory, camera, date, photo) => {
    if (camera) {
      if (!byCategory) {
        dispatch(push(`/trailcams/${camera}/${date}/${photo.filename}`));
      } else {
        dispatch(
          push(`/trailcams/by-category/${camera}/${date}/${photo.filename}`)
        );
      }
    } else {
      if (!byCategory) {
        dispatch(push(`/trailcams/${date}/${photo.filename}`));
      } else {
        dispatch(push(`/trailcams/by-category/${date}/${photo.filename}`));
      }
    }
  },
  onClose: (byCategory, camera, date) => {
    if (camera && !byCategory) {
      dispatch(push(`/trailcams/${camera}/${date}/`));
    } else {
      dispatch(push(`/trailcams/by-category/${date}/`));
    }
  }
});

class FullscreenTrailcamModal extends Component {
  constructor(props) {
    super(props);

    this.keyDown = this.keyDown.bind(this);
  }

  getImage(cam, filename) {
    const { images } = this.props.trailcams;
    if (!images) return null;
    return images.filter(
      image => slugify(image.text) === cam && image.filename === filename
    )[0];
  }

  getImageAtIndex(currentImage, indexDelta) {
    let { images } = this.props.trailcams;

    if (!this.props.byCategory) {
      images = images.filter(
        i => i.camera_number === currentImage.camera_number
      );
    }
    const index = images.map(i => i.date).indexOf(currentImage.date);
    return images[index + indexDelta];
  }

  getNextImage(currentImage) {
    return this.getImageAtIndex(currentImage, +1);
  }

  getPreviousImage(currentImage) {
    return this.getImageAtIndex(currentImage, -1);
  }

  keyDown(e) {
    const { cam, date, filename } = this.props.match.params;
    const { byCategory } = this.props;

    if (e.key === "ArrowRight" || e.key === "ArrowLeft") {
      let image = this.getImage(cam, filename);
      let next;

      if (e.key === "ArrowRight") {
        next = this.getNextImage(image);
      } else if (e.key === "ArrowLeft") {
        next = this.getPreviousImage(image);
      }

      if (next) {
        this.props.goToPhoto(byCategory, slugify(next.text), date, next);
      }
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.keyDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyDown, false);
  }

  render() {
    const { cam, date, filename } = this.props.match.params;
    const { byCategory } = this.props;
    const image = this.getImage(cam, filename);
    let nextImage, previousImage;
    if (image) {
      nextImage = this.getNextImage(image);
      previousImage = this.getPreviousImage(image);
    }

    return (
      <FullscreenModal
        onClose={() => this.props.onClose(byCategory, cam, date)}
      >
        <div className="trailcam-fullscreen">
          {image ? (
            <React.Fragment>
              <h1>{image.text}</h1>
              {previousImage ? (
                <div
                  className="trailcam-fullscreen-previous"
                  onClick={() =>
                    this.props.goToPhoto(
                      byCategory,
                      slugify(previousImage.text),
                      date,
                      previousImage
                    )
                  }
                >
                  〈
                </div>
              ) : null}
              <img
                alt="MPG Ranch motion triggered"
                src={trailcamUrl(
                  image.servername,
                  image.camera_number,
                  image.filename
                )}
              />
              {nextImage ? (
                <div
                  className="trailcam-fullscreen-next"
                  onClick={() =>
                    this.props.goToPhoto(
                      byCategory,
                      slugify(nextImage.text),
                      date,
                      nextImage
                    )
                  }
                >
                  〉
                </div>
              ) : null}
            </React.Fragment>
          ) : null}
        </div>
      </FullscreenModal>
    );
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(FullscreenTrailcamModal)
);
