import React, { Component } from "react";
import "./LivecamOverlayControl.scss";

export class LivecamOverlayControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mouseDownPosition: null,
      mouseCurrentPosition: null
    };
  }

  getLivecamPosition(mouseEvent) {
    let element = mouseEvent.target;
    if (element.tagName !== "svg") element = element.parentNode;

    // Get the position of the given *page* coordinates on the livecam image
    const boundingRect = element.getBoundingClientRect();
    return {
      x: mouseEvent.pageX - boundingRect.left,
      y: mouseEvent.pageY - boundingRect.top
    };
  }

  getDraggedRectangle() {
    // Get the coordinates and dimensions of the currently drawn rectangle
    if (!(this.state.mouseDownPosition && this.state.mouseCurrentPosition)) {
      return {};
    }
    const downX = this.state.mouseDownPosition[0];
    const upX = this.state.mouseCurrentPosition[0];
    const downY = this.state.mouseDownPosition[1];
    const upY = this.state.mouseCurrentPosition[1];
    return {
      x0: Math.min(downX, upX),
      y0: Math.min(downY, upY),
      x1: Math.max(downX, upX),
      y1: Math.max(downY, upY),
      width: Math.abs(downX - upX),
      height: Math.abs(downY - upY)
    };
  }

  handleLivecamMouseDown(e) {
    const livecamPosition = this.getLivecamPosition(e);
    this.setState({
      mouseDownPosition: [livecamPosition.x, livecamPosition.y]
    });
  }

  handleLivecamMouseUp(e) {
    const { cameraModel } = this.props;

    if (this.state.mouseDownPosition) {
      let target = e.target;
      if (target.tagName !== "svg") target = target.parentNode;
      const boundingRect = target.getBoundingClientRect();
      const imageWidth = Math.round(boundingRect.width);
      const imageHeight = Math.round(boundingRect.height);
      const rectangle = this.getDraggedRectangle();

      if (rectangle.width >= 10 && rectangle.height >= 10) {
        // If rectangle large enough, zoom to center of rectangle, find zoom
        // factor (100 == no zoom, 200 == 2x zoom)
        const centerX = Math.round(rectangle.x0 + rectangle.width / 2);
        const centerY = Math.round(rectangle.y0 + rectangle.height / 2);
        const factor = Math.round((imageWidth / rectangle.width) * 100);
        cameraModel.centerAndZoom(
          centerX,
          centerY,
          imageWidth,
          imageHeight,
          factor
        );
      } else {
        // If rectangle too small, just center on the position clicked
        const livecamPosition = this.getLivecamPosition(e);
        cameraModel.center(
          Math.round(livecamPosition.x),
          Math.round(livecamPosition.y),
          imageWidth,
          imageHeight
        );
      }
    }
    this.setState({ mouseDownPosition: null, mouseCurrentPosition: null });
  }

  handleLivecamMouseMove(e) {
    if (this.state.mouseDownPosition) {
      const livecamPosition = this.getLivecamPosition(e);
      this.setState({
        mouseCurrentPosition: [livecamPosition.x, livecamPosition.y]
      });
    }
  }

  render() {
    return (
      <svg
        className="livecam-overlay-control"
        onMouseDown={this.handleLivecamMouseDown.bind(this)}
        onMouseUp={this.handleLivecamMouseUp.bind(this)}
        onMouseMove={this.handleLivecamMouseMove.bind(this)}
      >
        {this.state.mouseDownPosition && this.state.mouseCurrentPosition && (
          <rect
            x={this.getDraggedRectangle().x0}
            y={this.getDraggedRectangle().y0}
            width={this.getDraggedRectangle().width}
            height={this.getDraggedRectangle().height}
          />
        )}
      </svg>
    );
  }
}
