import React, { Component } from "react";
import { connect } from "react-redux";
import push from "../../constants/push";
import FullscreenModal from "../modals/FullscreenModal";
import LivecamModalContent from "./LivecamModalContent";
import "./FullscreenLivecamModal.scss";

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  onClose: url => dispatch(push(url))
});

class FullscreenLivecamModal extends Component {
  render() {
    return (
      <FullscreenModal onClose={() => this.props.onClose(this.props.closeUrl)}>
        <LivecamModalContent fullscreen={true} {...this.props} />
      </FullscreenModal>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FullscreenLivecamModal);
