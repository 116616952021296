import Species from "./Species";
import React, { Component } from "react";
import * as d3 from "d3";
import push from "../../constants/push";
import { connect } from "react-redux";

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  goToObservation: observationId => {
    dispatch(push("/inaturalist/" + observationId));
  }
});

class SpeciesDrilldown extends Component {
  constructor(props) {
    super(props);
    this.container = React.createRef();
  }

  render() {
    const {
      location,
      species,
      categories,
      filter,
      setFilter,
      siteTerminology,
      goTo,
      valueFilter,
      unitTerminology
    } = this.props;

    let filtered = [];
    let back;
    let title;

    // if there is a location, than we should display SPECIES in SITE
    if (location) {
      filtered = species.filter(s => s.location === location);
      title = (
        <div className="currentFilter">
          {"Filtering to " + siteTerminology + ": " + location}
        </div>
      );

      back = (
        <div className="opt" onClick={() => goTo("")}>
          {`Back to all ${siteTerminology}s`}
        </div>
      );

      // if there is a filter value then we should display SPECIES in CATEGORY
    } else if (filter && !valueFilter) {
      filtered = species.filter(
        s => s.category === filter || s.species === filter
      );
      let newFilter = null;

      if (filtered[0] && filtered[0].species === filter) {
        newFilter = filtered[0].category;
        filtered = species.filter(s => s.category === newFilter);
      }

      title = (
        <div className="currentFilter">
          {"Filtering species list to " +
            (filtered[0] ? filtered[0].category_display : "")}
        </div>
      );
      back = newFilter ? (
        <div
          className="opt"
          onClick={() => this.props.setFilter(filtered[0].category)}
        >
          Back to family filter
        </div>
      ) : (
        <div className="opt" onClick={() => this.props.setFilter(null)}>
          Clear filter
        </div>
      );

      // otherwise, we will just display CATEGORIES, assumed provided
    } else {
      filtered = categories;
    }

    filtered.sort((a, b) => b.count - a.count);

    const heightScale = d3
      .scaleSqrt()
      .domain([0, d3.max(filtered.map(f => f.count))])
      .range([80, 250])
      .clamp(true);

    return (
      <div className="SpeciesDrilldown" ref={this.container}>
        {title}
        {back}
        <div className="speciesList">
          {filtered.map((s, i) => (
            <Species
              key={s.title + s.species + s.category + s.photo}
              alt={i % 2 === 0}
              title={s.title}
              height={heightScale(s.count)}
              info={s.count}
              selected={
                this.props.observation
                  ? s.observation === this.props.observation
                  : s.species
                  ? s.species === filter
                  : s.category === filter
              }
              setFilter={() => {
                if (s.observation) {
                  this.props.goToObservation(s.observation);
                } else {
                  setFilter(s.code || s.species || s.category);
                }
              }}
              photo={s.photo}
              description={s.description}
              container={this.container}
              unitTerminology={unitTerminology}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpeciesDrilldown);
