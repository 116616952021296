import React, { Component } from "react";
import Select from "react-select";
import { LivecamSlider } from "./LivecamSlider";
import "./LivecamControls.scss";
import { slugify } from "../../constants/app";

export class LivecamControls extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPreset: null
    };
  }

  onPresetClick(preset) {
    this.setState({ selectedPreset: preset });
    this.props.cameraModel.goToPreset(preset.value);
  }

  componentDidMount() {
    if (
      this.props.cameraModel &&
      this.props.initialPreset &&
      this.props.cameraState.presets
    ) {
      this.goToPreset(
        this.props.cameraModel,
        this.props.cameraState.presets,
        this.props.initialPreset
      );
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.cameraState.name !== this.props.cameraState.name) {
      this.setState({ selectedPreset: null });
    }
    if (
      nextProps.cameraModel &&
      nextProps.initialPreset &&
      JSON.stringify(nextProps.cameraState.presets) !==
        JSON.stringify(this.props.cameraState.presets)
    ) {
      this.goToPreset(
        nextProps.cameraModel,
        nextProps.cameraState.presets,
        nextProps.initialPreset
      );
    }
  }

  shouldComponentUpdate(nextProps) {
    const oldCameraState = this.props.cameraState;
    const newCameraState = nextProps.cameraState;
    if (
      newCameraState.focus === oldCameraState.focus &&
      newCameraState.focusRange &&
      newCameraState.focusRange[0] === oldCameraState.focusRange[0] &&
      newCameraState.focusRange[1] === oldCameraState.focusRange[1] &&
      newCameraState.zoom === oldCameraState.zoom &&
      newCameraState.zoomRange &&
      newCameraState.zoomRange[0] === oldCameraState.zoomRange[0] &&
      newCameraState.zoomRange[1] === oldCameraState.zoomRange[1]
    ) {
      return false;
    }
    return true;
  }

  goToPreset(cameraModel, presets, selectedPreset) {
    const preset = this.findPreset(presets, selectedPreset);
    cameraModel.goToPreset(preset.id);
    this.setState({
      selectedPreset: { value: preset.id, label: preset.name }
    });
  }

  findPreset(presets, slug) {
    return presets.filter(({ name }) => slugify(name.trim()) === slug)[0];
  }

  render() {
    const { cameraModel } = this.props;
    const {
      focus,
      focusRange,
      presets,
      zoom,
      zoomRange
    } = this.props.cameraState;
    const { selectedPreset } = this.state;
    const presetOptions = presets
      ? presets.map(p => ({ value: p.id, label: p.name }))
      : [];

    return (
      <div className="livecam-controls">
        <div className="livecam-controls-row">
          <div className="livecam-controls-pan">
            <img
              className="control-btn ring"
              src={require("./icons/control-ring.svg").default}
              alt="Livecam ring"
            />
            <img
              className="control-btn arrow up"
              src={require("./icons/control-up.svg").default}
              onMouseDown={() => cameraModel.up()}
              onMouseUp={() => cameraModel.stopPanTilt()}
              alt="Livecam up button"
            />
            <img
              className="control-btn arrow down"
              src={require("./icons/control-down.svg").default}
              onMouseDown={() => cameraModel.down()}
              onMouseUp={() => cameraModel.stopPanTilt()}
              alt="Livecam down button"
            />
            <img
              className="control-btn arrow left"
              src={require("./icons/control-left.svg").default}
              onMouseDown={() => cameraModel.left()}
              onMouseUp={() => cameraModel.stopPanTilt()}
              alt="Livecam left button"
            />
            <img
              className="control-btn arrow right"
              src={require("./icons/control-right.svg").default}
              onMouseDown={() => cameraModel.right()}
              onMouseUp={() => cameraModel.stopPanTilt()}
              alt="Livecam right button"
            />
            <label className="control-label livecam-controls-label">
              Tilt/Pan
            </label>
          </div>
          <div className="livecam-controls-sliders">
            <div className="control-zoom-slider">
              <label className="livecam-controls-label">Zoom</label>
              <LivecamSlider
                onSliderMove={value => cameraModel.zoomTo(value)}
                value={zoom}
                range={zoomRange}
              />
            </div>
            <div className="control-focus-slider">
              <label className="livecam-controls-label">Focus</label>
              <LivecamSlider
                onSliderMove={value => cameraModel.focusTo(value)}
                value={focus}
                range={focusRange}
              />
            </div>
          </div>
        </div>
        <label className="livecam-controls-label">Camera Presets</label>
        <Select
          className="livecam-presets"
          classNamePrefix="react-select-presets"
          placeholder="Go to preset"
          value={selectedPreset}
          options={presetOptions}
          onChange={v => this.onPresetClick(v)}
          maxMenuHeight={150}
          menuPlacement="auto"
          clearable={false}
          searchable={false}
        />
      </div>
    );
  }
}
