import * as actions from "../constants/action_names";

export const selectSoilStation = station => {
  return dispatch => {
    dispatch({
      type: actions.SOIL_STATION_SELECT,
      station
    });
  };
};
