import React, { Component } from "react";
import * as d3 from "d3";
import "./WeatherSummary.scss";

class Aggregate extends Component {
  render() {
    const { label, units, value } = this.props;
    return (
      <div className="weather-summary-aggregate">
        <div className="weather-summary-aggregate-label">{label}</div>
        <div>
          <span className="weather-summary-aggregate-value">{value}</span>
          <span className="weather-summary-aggregate-units">{units}</span>
        </div>
      </div>
    );
  }
}

export class WeatherSummary extends Component {
  getAggregates(data) {
    return [
      {
        label: "Average Temperature",
        units: "ºF",
        value: d3.format(".1f")(d3.mean(data, d => d.temperature))
      },
      {
        label: "Total Precipitation",
        units: '"',
        value: d3.format(".1f")(d3.sum(data, d => d.precipitation))
      },
      {
        label: "Average Dewpoint",
        units: "ºF",
        value: d3.format(".1f")(d3.mean(data, d => d.dewPoint))
      },
      {
        label: "Average Wind Speed",
        units: "MPH",
        value: d3.format(".1f")(d3.mean(data, d => d.windSpeed))
      },
      {
        label: "Average Humidity",
        units: "%",
        value: d3.format(".1f")(d3.mean(data, d => d.humidity))
      }
    ];
  }

  render() {
    const aggregates = this.getAggregates(this.props.data);
    return (
      <div className="weather-summary">
        <div className="weather-summary-title">
          Conditions for this time period:
        </div>
        <div className="weather-summary-values">
          {aggregates.map(aggregate => (
            <Aggregate key={aggregate.label} {...aggregate} />
          ))}
        </div>
      </div>
    );
  }
}
