import React, { Component } from "react";
import * as d3 from "d3";
import classNames from "classnames";
import MouseoverIndicatorChart from "../charts/MouseoverIndicatorChart";
import WeatherConditions from "./WeatherConditions";
import "./WeatherChart.scss";

class MetricLine extends Component {
  shouldComponentUpdate(nextProps) {
    return (
      this.props.data !== nextProps.data ||
      this.props.selected !== nextProps.selected
    );
  }

  render() {
    const { data, metric, selected } = this.props;

    return (
      <path
        className={classNames(
          "weather-metric-line",
          `weather-${metric.id}-data`,
          { selected: selected }
        )}
        d={metric.lineGenerator(data)}
      />
    );
  }
}

class WeatherChart extends Component {
  constructor(props) {
    super(props);
    this.marginBottom = 20;
    this.marginLeft = 60;
    this.state = {
      selectedMetric: "temperature",
      selectedTime: null
    };
  }

  componentDidMount() {
    if (this.props.width && this.props.height) {
      this.chartHeight = this.calculateChartHeight();
      this.drawXAxis();
      this.dates = this.getDates();

      const currentMetric = this.metrics.filter(
        metric => metric.id === this.state.selectedMetric
      )[0];
      this.drawYAxis(currentMetric.yScale, currentMetric.yTickFormat);
    }
  }

  componentDidUpdate() {
    if (this.props.data.length && this.props.width && this.props.height) {
      this.chartHeight = this.calculateChartHeight();
      this.drawXAxis();
      this.dates = this.getDates();

      const currentMetric = this.metrics.filter(
        metric => metric.id === this.state.selectedMetric
      )[0];
      this.drawYAxis(currentMetric.yScale, currentMetric.yTickFormat);
    }
  }

  getDates() {
    return d3
      .set(this.props.data.map(d => d.time_stamp.toISOString()))
      .values()
      .sort();
  }

  calculateChartHeight() {
    return this.props.height - 250;
  }

  drawXAxis() {
    const xAxis = d3.axisBottom(this.xScale).ticks(4);

    const selection = d3
      .select(this.chartSvg)
      .selectAll(".x-axis")
      .data([xAxis]);

    const enter = selection
      .enter()
      .append("g")
      .classed("x-axis", true)
      .attr(
        "transform",
        `translate(0, ${this.chartHeight - this.marginBottom})`
      );

    enter.merge(selection).call(xAxis);
  }

  drawYAxis(scale, tickFormat) {
    const yAxis = d3.axisLeft(scale).ticks(4, tickFormat);
    d3.select(this.yAxisRoot).call(yAxis);
  }

  getMetrics(data) {
    const temperatureValues = data
      .map(d => d.temperature)
      .concat(data.map(d => d.dewPoint));

    const temperatureYExtent = d3.extent(temperatureValues);
    temperatureYExtent[0] = Math.min(0, temperatureYExtent[0]);
    const temperatureY = d3
      .scaleLinear()
      .domain(temperatureYExtent)
      .range([this.chartHeight - this.marginBottom, this.marginBottom])
      .nice();

    const temperatureLineGenerator = d3
      .line()
      .defined(d => d.temperature !== null && d.temperature !== 0)
      .curve(d3.curveBasis)
      .x(d => this.xScale(d.time_stamp))
      .y(d => temperatureY(d.temperature));

    const dewPointLineGenerator = d3
      .line()
      .defined(d => d.dewPoint !== null && d.dewPoint !== 0)
      .curve(d3.curveBasis)
      .x(d => this.xScale(d.time_stamp))
      .y(d => temperatureY(d.dewPoint));

    const humidityYExtent = d3.extent(data, d => d.humidity);
    humidityYExtent[0] = Math.min(0, humidityYExtent[0]);
    const humidityY = d3
      .scaleLinear()
      .domain(humidityYExtent)
      .range([this.chartHeight - this.marginBottom, this.marginBottom])
      .nice();

    const humidityLineGenerator = d3
      .line()
      .defined(d => d.humidity !== null)
      .curve(d3.curveBasis)
      .x(d => this.xScale(d.time_stamp))
      .y(d => humidityY(d.humidity));

    const precipitationYExtent = d3.extent(data, d => d.precipitation);
    precipitationYExtent[0] = Math.min(0, precipitationYExtent[0]);
    const precipitationY = d3
      .scaleLinear()
      .domain(precipitationYExtent)
      .range([this.chartHeight - this.marginBottom, this.marginBottom])
      .nice();

    const precipitationLineGenerator = d3
      .line()
      .defined(d => d.precipitation !== null)
      .curve(d3.curveBasis)
      .x(d => this.xScale(d.time_stamp))
      .y(d => precipitationY(d.precipitation));

    const pressureYExtent = d3.extent(data, d => d.pressure);
    pressureYExtent[0] = Math.min(0, pressureYExtent[0]);
    const pressureY = d3
      .scaleLinear()
      .domain(pressureYExtent)
      .range([this.chartHeight - this.marginBottom, this.marginBottom])
      .nice();

    const pressureLineGenerator = d3
      .line()
      .defined(d => d.pressure !== null)
      .curve(d3.curveBasis)
      .x(d => this.xScale(d.time_stamp))
      .y(d => pressureY(d.pressure));

    const windSpeedYExtent = d3.extent(data, d => d.windSpeed);
    windSpeedYExtent[0] = Math.min(0, windSpeedYExtent[0]);
    const windSpeedY = d3
      .scaleLinear()
      .domain(windSpeedYExtent)
      .range([this.chartHeight - this.marginBottom, this.marginBottom])
      .nice();

    const windSpeedLineGenerator = d3
      .line()
      .defined(d => d.windSpeed !== null)
      .curve(d3.curveBasis)
      .x(d => this.xScale(d.time_stamp))
      .y(d => windSpeedY(d.windSpeed));

    return [
      {
        id: "temperature",
        label: "Temperature",
        lineGenerator: temperatureLineGenerator,
        yLabel: "Temperature (ºF)",
        yScale: temperatureY,
        yTickFormat: "d"
      },
      {
        id: "dewPoint",
        label: "Dew point",
        lineGenerator: dewPointLineGenerator,
        yLabel: "Dew Point (ºF)",
        yScale: temperatureY,
        yTickFormat: "d"
      },
      {
        id: "pressure",
        label: "Pressure",
        lineGenerator: pressureLineGenerator,
        yLabel: 'Pressure (")',
        yScale: pressureY,
        yTickFormat: "d"
      },
      {
        id: "precipitation",
        label: "Precipitation",
        lineGenerator: precipitationLineGenerator,
        yLabel: 'Precipitation (")',
        yScale: precipitationY,
        yTickFormat: "0.3f"
      },
      {
        id: "humidity",
        label: "Humidity",
        lineGenerator: humidityLineGenerator,
        yLabel: "Humidity (%)",
        yScale: humidityY,
        yTickFormat: "d"
      },
      {
        id: "windSpeed",
        label: "Wind speed",
        lineGenerator: windSpeedLineGenerator,
        yLabel: "Wind speed (mph)",
        yScale: windSpeedY,
        yTickFormat: "d"
      }
    ];
  }

  render() {
    const { data, height, width } = this.props;
    const { selectedMetric, selectedTime } = this.state;
    if (data.length === 0 || !width || !height) {
      return <div>No data</div>;
    }

    this.chartHeight = this.calculateChartHeight();

    this.xScale = d3
      .scaleTime()
      .domain(d3.extent(data, d => new Date(d.time_stamp)))
      .range([this.marginLeft, width - this.marginLeft])
      .nice();

    this.metrics = this.getMetrics(data);

    const currentMetric = this.metrics.filter(
      metric => metric.id === selectedMetric
    )[0];

    return (
      <React.Fragment>
        <div className="weather-metrics">
          {this.metrics.map(metric => (
            <span
              key={metric.id}
              className={classNames("weather-metric", "opt", {
                selected: metric.id === selectedMetric
              })}
              onClick={() => this.setState({ selectedMetric: metric.id })}
            >
              {metric.label}
            </span>
          ))}
        </div>
        <MouseoverIndicatorChart
          dates={this.getDates()}
          height={this.chartHeight}
          onXValue={selectedTime => this.setState({ selectedTime })}
          width={width}
          xScale={this.xScale}
        >
          <g
            transform="translate(0, 0)"
            className="weather-temperature"
            height={this.chartHeight}
            ref={r => (this.chartSvg = r)}
          >
            <text
              className="y-label"
              transform={`translate(10, ${this.chartHeight / 2}) rotate(-90)`}
            >
              {currentMetric.yLabel}
            </text>

            <g
              className="y-axis"
              ref={r => (this.yAxisRoot = r)}
              transform={`translate(${this.marginLeft}, 0)`}
            />

            {this.metrics.map(metric => (
              <MetricLine
                key={metric.id}
                metric={metric}
                data={data}
                selected={metric.id === selectedMetric}
              />
            ))}
          </g>
        </MouseoverIndicatorChart>
        {selectedTime ? (
          <WeatherConditions
            time={selectedTime}
            data={
              data.filter(
                d => d.time_stamp.toISOString() === selectedTime.toISOString()
              )[0]
            }
          />
        ) : null}
      </React.Fragment>
    );
  }
}

export default class WeatherChartWithSampleControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      samplePercentage: 100
    };
  }

  componentDidMount() {
    if (this.props.data.length > 2000) {
      this.setState({ samplePercentage: 5 });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.data.length !== this.props.data.length &&
      this.props.data.length > 2000
    ) {
      this.setState({ samplePercentage: 5 });
    }
  }

  render() {
    const { data, height, width } = this.props;
    const { samplePercentage } = this.state;

    const chartData = d3.quantize(
      d3.interpolateDiscrete(data),
      Math.floor(data.length * (samplePercentage / 100))
    );

    return (
      <div className="weather-chart">
        <WeatherChart data={chartData} height={height} width={width} />
      </div>
    );
  }
}
