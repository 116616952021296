import React, { useState } from "react";
import * as d3 from "d3";
import "./MouseoverIndicatorChart.scss";

const MouseoverIndicatorChart = ({
  children,
  dates,
  height,
  onXValue,
  width,
  xScale
}) => {
  const [mouseOverTime, setMouseOverTime] = useState(null);

  const onMouseMove = e => {
    const left = e.currentTarget.getBoundingClientRect().left;
    let x = e.clientX - left;
    let nextMouseOverTime = xScale.invert(x);
    let nearestIndex = d3.bisect(dates, nextMouseOverTime.toISOString());

    const [min, max] = xScale.domain();
    if (nextMouseOverTime < min) {
      nextMouseOverTime = min;
    }
    if (nextMouseOverTime > max) {
      nextMouseOverTime = max;
    }
    nearestIndex = Math.min(nearestIndex, dates.length - 1);

    const nextTime = new Date(dates[nearestIndex]);
    setMouseOverTime(nextMouseOverTime);
    onXValue(nextTime);
  };

  return (
    <svg
      className="MouseoverIndicatorChart"
      viewBox={`0 0 ${width} ${height}`}
      onMouseMove={onMouseMove}
    >
      {children}

      {mouseOverTime ? (
        <g className="selected-time">
          <line
            x1={xScale(mouseOverTime)}
            x2={xScale(mouseOverTime)}
            y1={0}
            y2={height}
            className="selected-time-line"
          />
        </g>
      ) : null}
    </svg>
  );
};

export default MouseoverIndicatorChart;
