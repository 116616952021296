import * as actions from "../../constants/action_names";
import * as config from "../../constants/config";
import { fetchSql } from "../../constants/carto";

const column_list = [
  "plot_id",
  "latitude",
  "longitude",
  "total_lichen",
  "total_moss",
  "acasch",
  "artgle",
  "asprep",
  "brycha",
  "brypru",
  "bueele",
  "buel_sp",
  "buepun",
  "bueter",
  "calamm",
  "caljun",
  "calo_sp",
  "calo_sp1",
  "calsti",
  "caltir",
  "canagg",
  "cand_sp",
  "cetacu",
  "clacar",
  "clacen",
  "clachl",
  "clad_sp",
  "clad_sp_2",
  "clafim",
  "clamac",
  "clapoc",
  "clapyx",
  "clapyx_clacar",
  "claver",
  "colten",
  "dipmus",
  "encvul",
  "endpus",
  "hypo_sp",
  "leca_sp",
  "leca_sp4",
  "leclax",
  "lecmur",
  "lecwul",
  "leczos",
  "lepalb",
  "lepint",
  "leplic",
  "lepra_sp",
  "lepten",
  "lept_sp",
  "lept_sp1",
  "mascar",
  "megver",
  "ochups",
  "parsul",
  "pelaph",
  "pelcan",
  "peldid",
  "pelext",
  "pelkri",
  "pelmal",
  "pelpon",
  "pelruf",
  "pelt_sp",
  "phanim",
  "phasar",
  "phymus",
  "placi_sp",
  "placy_sp",
  "plaicm",
  "plalach",
  "plalacin",
  "plasqu",
  "plauli",
  "polmus",
  "psocer",
  "psoglo",
  "psomon",
  "psor_sp",
  "rincon",
  "rinoli",
  "rinter",
  "squlen",
  "themus",
  "xanwyo",
  "auland",
  "brafen",
  "bryarg",
  "bryu_sp",
  "cerpur",
  "dicmon",
  "dicsco",
  "didvin",
  "encvul",
  "eurpul",
  "foss_sp",
  "funhyg",
  "gemcae",
  "homaur",
  "poljun",
  "polpil",
  "pteova",
  "pter_sp",
  "ptesub",
  "syncan",
  "synmon",
  "synrur",
  "synt_sp"
];

export const fetchBiocrustDetail = () => {
  const sql = `SELECT ${column_list.map(c => `b.${c}`)},
    (CASE WHEN b.the_geom IS NOT NULL THEN b.the_geom ELSE points.the_geom END) AS the_geom
          FROM ${config.biocrust_data_table} b
          LEFT OUTER JOIN ${config.observation_points_table} points
          ON b.plot_id = points.n::text`;

  const speciesSql = `SELECT sp.*, inat.default_photo, inat.default_photo_attribution, inat.wikipedia_summary, inat_genus.default_photo AS default_photo_genus, inat_genus.default_photo_attribution AS default_photo_attribution_genus, inat_genus.wikipedia_summary AS wikipedia_summary_genus
    FROM ${config.biocrust_species_list_table} sp
    LEFT JOIN ${config.inaturalist_taxa_table} inat
    ON inat.name = CONCAT(sp.genus, ' ', sp.species)
    LEFT JOIN ${config.inaturalist_taxa_table} inat_genus
    ON inat_genus.name = sp.genus`;

  return dispatch => {
    dispatch({
      type: actions.BIOCRUST_POINTS_GEOJSON_REQUEST,
      geojson: null
    });

    return Promise.all([fetchSql(sql, "geojson"), fetchSql(speciesSql, "json")])
      .then(data => {
        let geojson = data[0];
        let species = data[1];

        let rows = species.rows.map(s => {
          const { code } = s;
          const { max, total, n } = geojson.features.reduce(
            (acc, f) => ({
              total: acc.total + f.properties[code.toLowerCase()],
              max: Math.max(acc.max, f.properties[code.toLowerCase()]),
              n: acc.n + (f.properties[code.toLowerCase()] > 0 ? 1 : 0)
            }),
            { max: 0, total: 0, n: 0 }
          );

          return { ...s, total, max, n };
        });

        rows = rows.filter(s => s.total > 0);

        dispatch({
          type: actions.BIOCRUST_POINTS_GEOJSON_RESPONSE,
          geojson,
          species: rows
        });
      })
      .catch(error =>
        dispatch({
          type: actions.BIOCRUST_POINTS_GEOJSON_ERROR_RESPONSE,
          error: error.message
        })
      );
  };
};
