import React, { Component } from "react";
import moment from "moment";
import "./TimelapseAnimation.css";
import play from "../../resources/icons/play.svg";
import pause from "../../resources/icons/pause.svg";

class TimelapseAnimation extends Component {
  constructor() {
    super();

    this.state = { i: 0, images: [], loaded: -1, playing: true };
  }

  componentDidMount() {
    this.incInterval = window.setInterval(this.inc.bind(this), 100);

    // Update state based on images props as they may have already been loaded
    this.setState({
      images: this.props.images.slice(),
      i: this.props.images.length,
      loaded: this.props.images.length - 1
    });
  }

  componentWillUnmount() {
    window.clearInterval(this.incInterval);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.images[0] !== prevProps.images[0] ||
      this.props.images.length !== prevProps.images.length
    ) {
      // clear old onload functions... this isn't super ideal but it works
      for (let i = 0; i < this.state.images.length; i++) {
        // eslint-disable-next-line
        this.state.images[i].onload = null;
      }

      if (this.props.images[0]) {
        this.setState(
          { images: [], loaded: -1, i: 0 },
          this.loadNext.bind(this)
        );
      }
    }
  }

  loadNext() {
    if (this.state.loaded < this.props.images.length - 1) {
      let images = this.state.images;
      const loaded = this.state.loaded + 1;

      images[loaded] = new Image();
      images[loaded].src = this.props.images[loaded].url;
      images[loaded].onload = this.loadNext.bind(this);

      this.setState({
        loaded,
        images
      });
    } else {
      this.setState({
        loaded: this.state.loaded + 1
      });
    }
  }

  inc() {
    if (this.state.playing) {
      if (this.state.i < this.state.loaded - 1) {
        this.setState({ i: this.state.i + 1 });
      } else {
        this.setState({ i: 0 });
      }
    }
  }

  render() {
    const loaded = this.state.loaded >= 0 ? this.state.loaded : 0;
    const image = this.props.images[this.state.i] ? (
      <img
        src={this.props.images[this.state.i].url}
        alt="Timelapse animation"
      />
    ) : null;

    return (
      <div className="TimelapseAnimation" key="TimelapseAnimation">
        {image}
        <div className="controls">
          <div
            className="play-pause"
            onClick={() => {
              this.setState({ playing: !this.state.playing });
            }}
          >
            <img
              src={this.state.playing ? pause : play}
              alt={this.state.playing ? "Pause" : "Play"}
            />
          </div>
          <div className="loadingBar">
            <div className="loading-background">Loading...</div>
            <div
              className="loading"
              style={{
                width: (loaded / this.props.images.length) * 100 + "%"
              }}
            />
            <div
              className="playback"
              style={{
                left: (this.state.i / this.props.images.length) * 100 + "%"
              }}
            />
          </div>
        </div>
        <div className="date">
          {this.props.images[this.state.i]
            ? moment(this.props.images[this.state.i].date).format(
                "MMMM DD, YYYY"
              )
            : ""}
        </div>
      </div>
    );
  }
}

export default TimelapseAnimation;
