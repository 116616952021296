import * as actions from "../../constants/action_names";
import * as config from "../../constants/config";
import { fetchSql } from "../../constants/carto";

export const fetchInaturalistPoints = (bounds, filter) => {
  const sql = `SELECT DISTINCT ON (o.id)
       o.time, o.created_time, o.time_observed_at, o.id, o.species_guess, o.user_name, o.the_geom, o.description,
       t.iconic_taxon_name, t.name AS taxon_name, p.url, t.wikipedia_summary, it.wikipedia_summary AS iconic_description, t.preferred_common_name,
      CASE 
        WHEN t.preferred_common_name IS NOT NULL THEN t.preferred_common_name
        WHEN o.species_guess IS NOT NULL THEN o.species_guess
        WHEN t.name IS NOT NULL THEN t.name
        ELSE NULL
      END AS display_species_name
    FROM ${config.inaturalist_observations_table} o
    LEFT OUTER JOIN ${config.inaturalist_taxa_table} t ON o.taxon_id = t.id
    LEFT OUTER JOIN ${
      config.inaturalist_taxa_table
    } it ON t.iconic_taxon_name = it.name
    LEFT OUTER JOIN ${
      config.inaturalist_photos_table
    } p on o.id = p.observation_id
    WHERE o.time > '${bounds[0].toISOString()}' AND o.time < '${bounds[1].toISOString()}' AND o.the_geom IS NOT NULL
    AND o.taxon_id IS NOT NULL
    ${
      filter
        ? `AND (
      t.preferred_common_name = '${filter}' OR
      o.species_guess = '${filter}' OR
      t.name = '${filter}' OR
      t.iconic_taxon_name = '${filter}' OR
      o.id = '${parseInt(filter)}'
    )`
        : ``
    }`;

  return dispatch => {
    dispatch({
      type: actions.INATURALIST_POINTS_REQUEST,
      geojson: null
    });

    dispatch(fetchInaturalistPhotos(bounds));

    return fetchSql(sql, "geojson")
      .then(geojson => {
        dispatch({
          type: actions.INATURALIST_POINTS_RESPONSE,
          geojson
        });
      })
      .catch(error =>
        dispatch({
          type: actions.INATURALIST_POINTS_ERROR_RESPONSE,
          error
        })
      );
  };
};

const fetchInaturalistPhotos = bounds => {
  const sql = `SELECT p.id, p.observation_id, p.attribution, p.url
    FROM ${config.inaturalist_photos_table} p
    LEFT OUTER JOIN ${
      config.inaturalist_observations_table
    } o ON o.id = p.observation_id
    WHERE o.time > '${bounds[0].toISOString()}' AND o.time < '${bounds[1].toISOString()}' AND o.the_geom IS NOT NULL`;

  return dispatch => {
    dispatch({
      type: actions.INATURALIST_PHOTOS_REQUEST
    });

    return fetchSql(sql, "json")
      .then(json => {
        dispatch({
          type: actions.INATURALIST_PHOTOS_RESPONSE,
          json
        });
      })
      .catch(error =>
        dispatch({
          type: actions.INATURALIST_PHOTOS_ERROR_RESPONSE,
          error
        })
      );
  };
};

export const fetchInaturalistOverview = filter => {
  const sql = `SELECT date_trunc('week', time) AS week, COUNT(*) AS count
      FROM ${config.inaturalist_observations_table}
      GROUP BY week`;

  if (filter) {
    const filteredSql = `SELECT date_trunc('week', o.time) AS week, COUNT(*) AS count
      FROM ${config.inaturalist_observations_table} o
      LEFT OUTER JOIN ${config.inaturalist_taxa_table} t ON o.taxon_id = t.id
      WHERE (
        t.preferred_common_name = '${filter}' OR
        o.species_guess = '${filter}' OR
        t.name = '${filter}' OR
        t.iconic_taxon_name = '${filter}' OR
        o.id = '${parseInt(filter)}')
      GROUP BY week`;

    return dispatch => {
      return Promise.all([fetchSql(sql, "json"), fetchSql(filteredSql, "json")])
        .then(([json, filteredjson]) => {
          json.rows = json.rows.map(r => ({
            ...r,
            date: new Date(r.week)
          }));
          filteredjson.rows = filteredjson.rows.map(r => ({
            ...r,
            date: new Date(r.week)
          }));

          dispatch({
            type: actions.INATURALIST_OVERVIEW_RESPONSE,
            json,
            selected: filteredjson
          });
        })
        .catch(error =>
          dispatch({
            type: actions.INATURALIST_OVERVIEW_ERROR_RESPONSE,
            error: error.message
          })
        );
    };
  }

  return dispatch => {
    dispatch({
      type: actions.INATURALIST_OVERVIEW_REQUEST,
      json: null
    });

    return fetchSql(sql, "json")
      .then(json => {
        json.rows = json.rows.map(row => ({
          ...row,
          date: new Date(row.week)
        }));
        dispatch({
          type: actions.INATURALIST_OVERVIEW_RESPONSE,
          json
        });
      })
      .catch(error =>
        dispatch({
          type: actions.INATURALIST_OVERVIEW_ERROR_RESPONSE,
          error: error.message
        })
      );
  };
};
