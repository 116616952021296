import React, { Component, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { disableTrailcamImage } from "../../actions/async/trailcam";
import { trailcamUrl } from "../../constants/app";
import { hasRole, ROLE_DISABLE_BUCKEYECAM_PHOTO } from "../../constants/auth";
import fullscreen from "../../resources/icons/fullscreen.svg";

const Image = ({
  canDisablePhoto,
  date,
  disableImage,
  fullscreen,
  image,
  goToPhoto,
  style
}) => {
  const [deleted, setDeleted] = useState(false);
  if (deleted) return null;

  return (
    <div className="image" style={style} onClick={goToPhoto}>
      <img
        src={trailcamUrl(image.servername, image.camera_number, image.filename)}
        alt="MPG Ranch motion triggered"
      />
      {canDisablePhoto ? (
        <div
          className="delete-button"
          onClick={e => {
            e.stopPropagation();
            setDeleted(true);
            disableImage();
          }}
        >
          delete
        </div>
      ) : null}
      <div className="time">
        <div>{date.format("h:mm a")}</div>
      </div>
      <div className="fullscreen">
        <img src={fullscreen} alt="" />
      </div>
    </div>
  );
};

class ImageList extends Component {
  constructor(props) {
    super(props);

    this.state = { numImages: 10 };
  }

  shouldComponentUpdate(newProps, newState) {
    if (
      newProps.images.length !== this.props.images.length ||
      newProps.end.valueOf() !== this.props.end.valueOf() ||
      newProps.canDisablePhoto !== this.props.canDisablePhoto ||
      newProps.auth.match !== this.props.auth.match ||
      newProps.width !== this.props.width ||
      newState.numImages !== this.state.numImages
    ) {
      return true;
    }

    return false;
  }

  goToDate(target) {
    this.setState({ numImages: 10 }, () => {
      if (target) this.props.callback(target);
    });
  }

  goToPhoto(photo) {
    this.props.goToPhoto(photo);
  }

  checkScroll() {
    const el = this.props.critterMap.modalRef.current;

    if (el.scrollTop + el.offsetHeight === el.scrollHeight) {
      this.setState({ numImages: this.state.numImages + 10 });
    }
  }

  componentDidMount() {
    if (this.props.critterMap.modalRef) {
      this.props.critterMap.modalRef.current.onscroll = this.checkScroll.bind(
        this
      );
    }
  }

  componentDidUpdate() {
    if (this.props.critterMap.modalRef) {
      this.props.critterMap.modalRef.current.onscroll = this.checkScroll.bind(
        this
      );
    }

    // Keep track of how many images we are displaying so that it can be increased
    let { nImages } = this.makeImageList(
      this.props.images,
      moment(this.props.end)
    );

    nImages = nImages || 10;
    if (nImages !== this.state.numImages) {
      this.setState({ numImages: nImages });
    }
  }

  componentWillUnmount() {
    this.props.critterMap.modalRef.current.onscroll = null;
  }

  makeImageList(images, end) {
    let modifiedEnd = moment(end).add(1, "hour");

    // To get the date that the back button should go to,
    // find the most recent date that we didn't display
    let laterImages = images.filter(i => i.date > modifiedEnd);

    let nextImageDate;
    let nextDate;
    let nextDay;

    if (laterImages.length > 0) {
      if (moment(end).hours() === 23) {
        nextDay = moment(end)
          .hours(23)
          .minutes(0)
          .seconds(0)
          .add(1, "day");
      } else {
        nextDay = moment(end)
          .hours(23)
          .minutes(0)
          .seconds(0);
      }

      nextImageDate = moment(laterImages[laterImages.length - 1].date);
      nextDate = Math.max(nextDay, nextImageDate);
    }

    // Now filter for the images that we want to display
    images = images.filter(i => i.date <= moment(end).add(1, "hour"));

    // Loop through images, grouping by date and building up
    // DOM for displaying images
    let nImages = 0;
    let i = 0;
    let dom = [];
    let lastDate = null;
    let imageBlock = [];
    let previousDate = null;

    const canDisablePhoto = hasRole(
      this.props.auth,
      ROLE_DISABLE_BUCKEYECAM_PHOTO
    );
    const disableImage = (cameraName, date) => {
      this.props.disableImage(this.props.auth.key, cameraName, date);
    };

    while (nImages < this.state.numImages && i < images.length) {
      let date = moment(images[i].date);

      if (lastDate !== date.dayOfYear()) {
        nImages += imageBlock.length;

        dom.push(
          <div className="imageBlock" key={"image_block_" + date.format()}>
            {imageBlock}
          </div>
        );

        imageBlock = [];
        if (nImages < this.state.numImages) {
          dom.push(
            <div className="date" key={"date_" + date.format()}>
              {date.format("MMMM DD, YYYY")}
            </div>
          );
        }
      }

      if (dom.length === 2) {
        // The forward button should go to the date of the next image that would
        // have been rendered
        if (i + 1 < images.length) {
          previousDate = images[i + 1].date;
        }
      }

      lastDate = date.dayOfYear();
      const image = images[i];

      imageBlock.push(
        <Image
          key={trailcamUrl(
            image.servername,
            image.camera_number,
            image.filename
          )}
          image={image}
          goToPhoto={() => this.goToPhoto(image)}
          style={
            this.props.width > 1100
              ? { width: "calc(20% - 0.5em)" }
              : this.props.width > 850
              ? { width: "calc(25% - 0.5em)" }
              : this.props.width > 700
              ? { width: "calc(33% - 0.5em)" }
              : this.props.width > 500
              ? { width: "calc(50% - 0.5em)" }
              : { width: "100%" }
          }
          canDisablePhoto={canDisablePhoto}
          date={date}
          disableImage={() => disableImage(image.text, image.date)}
          fullscreen={fullscreen}
        />
      );

      i++;
    }

    if (nImages < this.state.numImages) dom.push(imageBlock);

    if (!previousDate) {
      // The forward button should go to the date of the next image that would
      // have been rendered
      if (i + 1 < images.length) {
        previousDate = images[i + 1].date;
      }
    }

    // previousDate only goes one day backwards, this goes multiple days if there
    // were multiple days displayed on the list
    let fullPreviousDate = images[i] ? images[i].date : previousDate;
    fullPreviousDate = moment(fullPreviousDate)
      .add(1, "day")
      .hours(0)
      .minutes(0)
      .seconds(0)
      .subtract(1, "hour");

    return { dom, previousDate, nextDate, fullPreviousDate, nImages };
  }

  render() {
    let end = moment(this.props.end);

    let { dom, nextDate, previousDate, fullPreviousDate } = this.makeImageList(
      this.props.images,
      end
    );

    return (
      <React.Fragment>
        <div className="nav">
          <div className="opt" onClick={() => this.goToDate(previousDate)}>
            Previous
          </div>
          <div className="next">
            <div className="opt" onClick={() => this.goToDate(nextDate)}>
              Next
            </div>
            <div
              className="opt"
              onClick={() =>
                this.goToDate(
                  this.props.images[0] ? this.props.images[0].date : null
                )
              }
            >
              Most Recent
            </div>
          </div>
        </div>
        <div className="thumbs">{dom}</div>
        <div className="nav bottom">
          <div
            className="opt"
            onClick={() => {
              this.goToDate(fullPreviousDate);
              this.props.critterMap.modalRef.current.scrollTop = 0;
            }}
          >
            Previous
          </div>
          <div className="next">
            <div
              className="opt"
              onClick={() => {
                this.goToDate(nextDate);
                this.props.critterMap.modalRef.current.scrollTop = 0;
              }}
            >
              Next
            </div>
            <div
              className="opt"
              onClick={() => {
                this.goToDate(
                  this.props.images[0] ? this.props.images[0].date : null
                );
                this.props.critterMap.modalRef.current.scrollTop = 0;
              }}
            >
              Most Recent
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  critterMap: state.critterMap
});

const mapDispatchToProps = dispatch => ({
  disableImage: (key, cameraName, photoDate) => {
    dispatch(disableTrailcamImage(key, cameraName, photoDate));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImageList);
