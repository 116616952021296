import React, { Component } from "react";
import * as d3 from "d3";
import ReactResizeDetector from "react-resize-detector";
import "./LivecamSlider.scss";

export class LivecamSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPosition: 0,
      domain: [0, 0],
      updateThreshold: null,
      width: 0
    };
    this.lineGenerator = d3.line();
  }

  componentDidMount() {
    const { value } = this.props;
    let { range } = this.props;
    const width = this.svgRoot ? this.svgRoot.getBoundingClientRect().width : 0;

    if (!range) {
      range = [0, 0];
    }
    this.scale = d3
      .scaleLinear()
      .domain(this.getDomain())
      .range(range);

    this.setState({
      currentPosition: this.scale.invert(value || 0),
      width
    });

    d3.select(this.handle).call(
      d3.drag().on("drag", d => {
        this.updatePosition(d3.event.x);
      })
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.range !== this.props.range && this.props.range) {
      this.scale.range(this.props.range);
    }

    if (prevState.width !== this.state.width) {
      this.scale.domain(this.getDomain());
      this.setState({
        currentPosition: this.scale.invert(this.props.value)
      });
    }

    if (prevProps.value !== this.props.value && this.props.value) {
      // Only update positions if update threshold has passed. Otherwise the
      // user might drag handle, then see it update to in-between positions.
      if (new Date() > this.state.updateThreshold) {
        this.setState({
          currentPosition: this.scale.invert(this.props.value)
        });
      }
    }
  }

  getDomain() {
    return [2, this.state.width - 2];
  }

  updatePosition(newPosition) {
    const domain = this.getDomain();
    const correctedNewPosition = Math.min(
      Math.max(newPosition, domain[0]),
      domain[1]
    );

    this.setState({
      currentPosition: correctedNewPosition,
      updateThreshold: d3.timeMinute.offset(new Date(), 1)
    });
    if (this.scale) {
      this.props.onSliderMove(this.scale(correctedNewPosition));
    }
  }

  handleClick(e) {
    this.updatePosition(e.nativeEvent.offsetX);
  }

  onResize() {
    const width = this.svgRoot ? this.svgRoot.getBoundingClientRect().width : 0;
    this.setState({ width });
  }

  advancePosition(direction) {
    const { currentPosition } = this.state;
    const domain = this.getDomain();
    const advanceAmount = (domain[1] - domain[0]) * 0.1;
    this.updatePosition(currentPosition + direction * advanceAmount);
  }

  render() {
    const { currentPosition, width } = this.state;
    const height = 15;

    return (
      <ReactResizeDetector handleWidth onResize={this.onResize.bind(this)}>
        <div className="livecam-slider-control">
          <div
            className="livecam-slider-control-button"
            onClick={() => this.advancePosition(-1)}
          >
            -
          </div>
          <svg
            className="track-container"
            style={{ height: `${height}px` }}
            onClick={this.handleClick.bind(this)}
            ref={svgRoot => (this.svgRoot = svgRoot)}
          >
            <path
              className="track"
              d={this.lineGenerator([[0, height / 2], [width, height / 2]])}
            />
            <path
              className="handle"
              ref={handle => (this.handle = handle)}
              d={this.lineGenerator([
                [currentPosition, 0],
                [currentPosition, height]
              ])}
            />
          </svg>
          <div
            className="livecam-slider-control-button"
            onClick={() => this.advancePosition(+1)}
          >
            +
          </div>
        </div>
      </ReactResizeDetector>
    );
  }
}
