import * as actions from "../constants/action_names.js";

export const setActiveLayers = layers => dispatch => {
  return dispatch({
    type: actions.SET_ACTIVE_LAYERS,
    layers,
    pushToUrl: { layers }
  });
};

export const unsetLayerOpacity = layer => dispatch => {
  return dispatch({
    type: actions.UNSET_LAYER_OPACITY,
    layer,
    pushToUrl: { [layer + "Opacity"]: null }
  });
};

export const setLayerOpacity = opacityUpdate => dispatch => {
  let pushToUrl = {
    [Object.keys(opacityUpdate).map(o => o + "Opacity")[0]]: Object.values(
      opacityUpdate
    )[0]
  };

  return dispatch({
    type: actions.SET_LAYER_OPACITY,
    opacityUpdate,
    pushToUrl
  });
};
