import * as d3 from "d3";
import { withLeaflet } from "react-leaflet";
import { connect } from "react-redux";
import push from "../../constants/push.js";
import { slugify } from "../../constants/app.js";
import makeComponent from "../CritterMap/makeComponent.js";
import SvgLayer from "../CritterMap/SvgLayer";
import { selectBiocrust } from "../../actions/biocrust";
import { pointColors } from "../../constants/layers_static";

class BiocrustPointsLayer extends SvgLayer {
  updateData(data, props) {
    super.updateData(data);

    let filter = props.biocrustPoints.filter;
    if (filter === "lichen" || filter === "moss") {
      filter = `total_${filter}`;
    }
    if (filter === "") filter = "total";

    let max = 0;
    let getValueForFilter = () => 0;

    if (data.features && props.biocrustPoints.species) {
      let matchedSpecies = props.biocrustPoints.species.filter(
        s => s.genus + " " + s.species === filter
      );

      if (matchedSpecies.length !== 1) {
        matchedSpecies = props.biocrustPoints.species
          .filter(s => s.genus === filter)
          .map(s => s.code.toLowerCase());
      }

      getValueForFilter = d => {
        if (
          filter === "total_lichen" ||
          filter === "total" ||
          filter === "total_moss"
        ) {
          return d.properties[filter];
        }

        if (matchedSpecies.length === 1 && matchedSpecies[0].code) {
          return d.properties[matchedSpecies[0].code.toLowerCase()];
        } else {
          return matchedSpecies.reduce(
            (acc, cur) => d.properties[cur] + acc,
            0
          );
        }
      };

      max = d3.max(data.features.map(d => getValueForFilter(d)));
    }

    let d = this.g
      .selectAll("circle")
      .data(data.features, d => d.properties.plot_id);

    let g = d
      .enter()
      .append("circle")
      .on("click", d => this.onClick(d.properties.plot_id))
      .attr("r", 0);

    d.merge(g)
      .classed("selected", d => d.properties._actionMapSelected)
      .attr("transform", this.translate.bind(this))
      .style("stroke", "white")
      .style("opacity", d => (d.properties._actionMapSelected ? 0.8 : 0.5))
      .style("fill", pointColors.biocrustPoints)
      .style("stroke-width", d => (d.properties._actionMapSelected ? 5 : 1))
      .transition()
      .duration(300)
      .attr("r", d => {
        let value = (getValueForFilter(d) || 0) / max;

        if (!filter) {
          value = 1;
        }
        return value * 40;
      });

    this.g.select(".selected").raise();

    d.exit()
      .transition()
      .duration(300)
      .attr("r", 0)
      .remove();
  }
}

const mapStateToProps = state => ({
  biocrustPoints: state.biocrustPoints
});

const mapDispatchToProps = dispatch => ({
  go: plot => {
    dispatch(push("/biocrustPoints/" + slugify(plot)));
    dispatch(selectBiocrust(plot));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLeaflet(makeComponent(BiocrustPointsLayer)));
