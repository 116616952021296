import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { selectEBirdLocation } from "../../actions/ebird";
import SpeciesDrilldown from "../SpeciesList/SpeciesDrilldown";
import * as actions from "../../constants/action_names.js";
import {
  fetchEBirdPoints,
  fetchEBirdOverview
} from "../../actions/async/ebird";
import push from "../../constants/push";

const mapStateToProps = state => ({
  eBird: state.eBird,
  timeline: state.timeline
});

const mapDispatchToProps = dispatch => ({
  selectEBirdLocation: location => dispatch(selectEBirdLocation(location)),
  setFilter: (filter, bounds, loc) => {
    dispatch({
      type: actions.EBIRD_SET_FILTER,
      filter,
      pushToUrl: { ebirdfilter: filter }
    });

    dispatch(fetchEBirdPoints(bounds, filter, loc));

    dispatch(fetchEBirdOverview(filter));
  },

  goTo: site => {
    dispatch(push(`/birds/${site}`));
  }
});

class BirdModal extends Component {
  componentDidUpdate(prevProps) {
    if (
      (this.props.eBird.points &&
        JSON.stringify(this.props.eBird.points) !==
          JSON.stringify(prevProps.eBird.points)) ||
      this.props.match.params.locId !== prevProps.match.params.locId
    ) {
      this.props.selectEBirdLocation(this.props.match.params.locId);
    }
  }
  render() {
    const families = this.props.eBird.families
      ? this.props.eBird.families.map(f => ({
          ...f,
          family_common:
            f.family === "undefined" ? "Not specified" : f.family_common
        }))
      : [];

    return (
      <div className="BirdModal">
        <div className="title">eBird observations</div>
        <div className="description">
          <p>
            Many researchers and hobbyist birders on MPG Ranch use{" "}
            <a href="https://ebird.org/home">eBird</a>, developed by the{" "}
            <a href="https://www.birds.cornell.edu/home/">
              Cornell Lab of Ornithology
            </a>{" "}
            to track observations. This data provides a window into the avian
            biodiversity in and around MPG Ranch. Try selecting different
            seasons on the timeline or looking at different regions of the ranch
            and notice how the bird communities change.
          </p>
        </div>

        <SpeciesDrilldown
          categories={families.map(d => ({
            location: d.loc_id,
            category: d.family,
            category_display: d.family_common,
            count: d.obs_qty,
            title: d.family === "undefined" ? "Not specified" : d.family_common,
            photo: d.photo,
            description: d.description
          }))}
          species={(
            (this.props.match.params.locId
              ? this.props.eBird.speciesSite
              : this.props.eBird.species) || []
          ).map(d => ({
            location: d.loc_id,
            category: d.family,
            category_display: d.family_common,
            species: d.scientific_name,
            count: d.obs_qty,
            title: d.common_name,
            photo: d.photo,
            description: d.description
          }))}
          setFilter={filter =>
            this.props.setFilter(
              filter,
              this.props.timeline.bounds,
              this.props.match.params.locId
            )
          }
          location={this.props.match.params.locId}
          filter={this.props.eBird.filter}
          siteTerminology={"eBird hotspot"}
          goTo={this.props.goTo}
          unitTerminology={" observed"}
        />
      </div>
    );
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BirdModal)
);
