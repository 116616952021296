import queryString from "query-string";
import * as actions from "../constants/action_names.js";

const query = queryString.parse(window.location.href.split("?")[1] || "");

const initialState = {
  fullscreen: query.fullscreen
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.TIMELAPSE_OVERVIEW_RESPONSE:
      return {
        ...state,
        overview: action.json
      };
    case actions.TIMELAPSE_OVERVIEW_ERROR:
      return {
        ...state,
        overview: action.json
      };
    case actions.TIMELAPSE_DETAIL_RESPONSE:
      return {
        ...state,
        points: action.json
      };
    case actions.TIMELAPSE_DETAIL_ERROR:
      return {
        ...state,
        points: action.json
      };
    case actions.TIMELAPSE_IMAGE_RESPONSE:
      return {
        ...state,
        images: action.json.rows
      };
    case actions.TIMELAPSE_IMAGE_ERROR:
      return {
        ...state,
        images: action.json
      };
    case actions.TIMELAPSE_SET_FULLSCREEN:
      return {
        ...state,
        fullscreen: action.fullscreen
      };
    case actions.TIMELAPSE_SELECT:
      return {
        ...state,
        points: {
          ...state.points,
          features: state.points.features.map(feature => {
            return {
              ...feature,
              properties: {
                ...feature.properties,
                _actionMapSelected: feature.properties.text === action.timelapse
              }
            };
          })
        }
      };
    default:
      return state;
  }
};
