import React, { Component } from "react";
import { connect } from "react-redux";
import ReactResizeDetector from "react-resize-detector";
import { Link } from "react-router-dom";
import push from "../../constants/push.js";
import { getCamera } from "../../loaders/livecamLoader";
import { BlockedLivecamVideo } from "./BlockedLivecamVideo";
import { LivecamControls } from "./LivecamControls";
import { LivecamMiniMap } from "./LivecamMiniMap";
import { LivecamVideo } from "./LivecamVideo";
import "./LivecamModalContent.scss";
import fullscreenImg from "../../resources/icons/fullscreen.svg";

class LivecamModalContent extends Component {
  componentDidMount() {
    const { livecam } = this.props;
    this.cameraModel = livecam ? getCamera(livecam.properties) : null;

    this.intervalId = window.setInterval(
      () => this.props.fetchLivecamDetails(this.props.livecam),
      1000
    );
  }

  componentWillUnmount() {
    window.clearInterval(this.intervalId);
  }

  componentDidUpdate(prevProps) {
    if (this.props.livecam !== prevProps.livecam) {
      const { livecam } = this.props;
      this.cameraModel = livecam ? getCamera(livecam.properties) : null;
    }
  }

  render() {
    const {
      canControlProtected,
      canViewProtected,
      fullscreen,
      fullscreenUrl,
      livecam,
      preset
    } = this.props;

    if (!livecam || !this.cameraModel) return null;

    let canControl = false;
    let canView = false;
    if (livecam) {
      canView = !livecam.properties.protected || canViewProtected;
      canControl =
        canView &&
        (!livecam.properties.controls_protected || canControlProtected);
    }

    return (
      <div className="livecam-modal-content">
        <header>
          <div className="title">{livecam.properties.name}</div>
          {!fullscreen ? (
            <div className="opt" onClick={() => this.props.push("/livecams")}>
              &larr; Back to Overview
            </div>
          ) : null}
        </header>
        <div className="livecam-main">
          <div className="livecam-video">
            {canView ? (
              <React.Fragment>
                <LivecamVideo
                  cameraModel={this.cameraModel}
                  canControl={canControl}
                />
                <div className="fullscreen">
                  {!fullscreen ? (
                    <Link to={fullscreenUrl}>
                      <img src={fullscreenImg} alt="View in fullscreen" />
                    </Link>
                  ) : null}
                </div>
              </React.Fragment>
            ) : (
              <BlockedLivecamVideo />
            )}
          </div>
          <div className="livecam-meta">
            {canControl ? (
              <LivecamControls
                cameraModel={this.cameraModel}
                cameraState={livecam.properties}
                initialPreset={preset}
              />
            ) : null}
            <div className="livecam-meta-minimap-wrapper">
              <ReactResizeDetector handleWidth>
                <LivecamMiniMap
                  boundaries={this.props.critterMap.boundary}
                  livecam={livecam}
                  property={this.props.critterMap.property}
                />
              </ReactResizeDetector>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  critterMap: state.critterMap
});

const mapDispatchToProps = dispatch => ({
  push: url => dispatch(push(url))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LivecamModalContent);
