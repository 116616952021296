import * as actions from "../constants/action_names.js";
import moment from "moment";
import queryString from "query-string";

const query = queryString.parse(window.location.href.split("?")[1] || "");

const initialState = {
  bounds: query.timelineBounds
    ? query.timelineBounds.map(d => moment.unix(d))
    : [moment().subtract(3, "months"), moment()]
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_TIMELINE:
      return {
        ...state,
        bounds: action.bounds
      };
    default:
      return state;
  }
};
