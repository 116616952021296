import React, { Component } from "react";
import { pointColors } from "../../constants/layers_static";
import GradientLegend from "../SpeciesList/GradientLegend";
import * as d3 from "d3";

class FloraLegend extends Component {
  render() {
    const { filter, geojson, species } = this.props;

    let nameForPlantCode = filter === "graminoid" ? "grass" : filter;

    if (filter) {
      let filteredSpecies = species.filter(s => s.plantcode === filter);
      if (filteredSpecies.length === 1) {
        nameForPlantCode = filteredSpecies[0].namescientific;
      }
    }

    return (
      <GradientLegend
        max={
          filter !== "diversity" && geojson
            ? Math.round(
                d3.max(
                  geojson.features
                    .map(f => f.properties[filter.toLowerCase()])
                    .filter(v => v > 0)
                )
              )
            : 100
        }
        label={
          nameForPlantCode.charAt(0).toUpperCase() +
          nameForPlantCode.slice(1) +
          " cover"
        }
        scale={`linear-gradient(to right, rgba(0, 0, 0, 0), ${
          pointColors.flora
        })`}
        unit="%"
      />
    );
  }
}

export default FloraLegend;
