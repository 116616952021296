import * as d3 from "d3";
import { withLeaflet } from "react-leaflet";
import { connect } from "react-redux";
import push from "../../constants/push.js";
import { slugify, trailcamUrlDateString } from "../../constants/app.js";
import makeComponent from "../CritterMap/makeComponent.js";
import SvgLayer from "../CritterMap/SvgLayer";
import moment from "moment";
import "./TrailcamLayer.css";
import { pointColors } from "../../constants/layers_static.js";

class TrailcamLayer extends SvgLayer {
  constructor(opts) {
    super(opts);

    this.sizeScale = d3
      .scaleSqrt()
      .domain([0, 300])
      .range([0, 10]);
  }

  updateData(data, props) {
    super.updateData(data);

    let maxVal = d3.max(data.features.map(f => f.properties.cnt));

    let rScale = d3
      .scaleSqrt()
      .domain([0, maxVal])
      .range([5, 30]);

    let colorScale = v => pointColors.trailcam(v / maxVal);

    let d = this.g
      .selectAll("circle")
      .data(this.data.features, d => d.properties.id);

    let g = d
      .enter()
      .append("circle")
      .attr("class", "translategroup trailcam")
      .on("click", d => {
        this.onClick(
          d.properties.text,
          Math.min(props.timeline.bounds[0], props.timeline.bounds[1])
        );
      })
      .attr("r", 0);

    d.merge(g)
      .attr("transform", this.translate.bind(this))
      .classed("selected", d => d.properties._actionMapSelected)
      .style("stroke-width", d => (d.properties._actionMapSelected ? 5 : 1))
      .transition()
      .duration(300)
      .attr("r", d => rScale(d.properties.cnt))
      .style("fill", d => colorScale(d.properties.cnt));

    this.g.select(".selected").lower();

    d.exit().remove();
  }
}

const mapStateToProps = state => ({
  trailcams: state.trailcams,
  timeline: state.timeline
});

const mapDispatchToProps = dispatch => ({
  go: (cam, date) => {
    dispatch(
      push(
        "/trailcams/" +
          slugify(cam) +
          "/" +
          moment(date).format(trailcamUrlDateString)
      )
    );
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLeaflet(makeComponent(TrailcamLayer)));
