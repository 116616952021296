import React from "react";
import { MapLayer, LeafletProvider } from "react-leaflet";

function makeComponent(leafletLayer) {
  return class extends MapLayer {
    createLeafletElement(props) {
      const el = new leafletLayer({
        data: props.data,
        onClick: this.props.go
      });
      el.setZIndex(props.zIndex);
      this.contextValue = { ...props.leaflet, popupContainer: el };
      return el;
    }

    updateLeafletElement(fromProps, toProps) {
      if (fromProps.data !== toProps.data) {
        this.contextValue.popupContainer.updateData(toProps.data, this.props);
      }

      this.contextValue.popupContainer.setZIndex(toProps.zIndex);
    }

    render() {
      const { children } = this.props;
      return children == null || this.contextValue == null ? null : (
        <LeafletProvider value={this.contextValue}>{children}</LeafletProvider>
      );
    }
  };
}

export default makeComponent;
