import React, { Component } from "react";
import { connect } from "react-redux";
import GeoTiffLayer from "../CritterMap/GeoTiffLayer";

const layers = {
  "bryophyte cover": {
    displayMin: 6.6,
    displayMax: 43,
    url: require("./img/bryophyte_cover.tif").default
  },
  "bryophyte richness": {
    displayMin: 2.7,
    displayMax: 9.3,
    url: require("./img/bryophyte_richness.tif").default
  },
  "lichen cover": {
    displayMin: 1.8,
    displayMax: 41.5,
    url: require("./img/lichen_cover.tif").default
  },
  "lichen richness": {
    displayMin: 3.2,
    displayMax: 18.1,
    url: require("./img/lichen_richness.tif").default
  }
};

class BiocrustLayer extends Component {
  render() {
    const layer = layers[this.props.biocrust.filter];
    return (
      <GeoTiffLayer
        displayMin={layer.displayMin}
        displayMax={layer.displayMax}
        url={layer.url}
        zIndex={this.props.zIndex}
        opacity={this.props.opacity}
      />
    );
  }
}

const mapStateToProps = state => ({
  biocrust: state.biocrust
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BiocrustLayer);
