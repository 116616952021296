import * as actions from "../constants/action_names.js";

export const setCenter = center => dispatch => {
  return dispatch({
    type: actions.SET_CENTER,
    center,
    pushToUrl: { center }
  });
};

export const setZoom = zoom => dispatch => {
  return dispatch({
    type: actions.SET_ZOOM,
    zoom,
    pushToUrl: { zoom }
  });
};

export const setProperty = property => dispatch => {
  return dispatch({
    type: actions.SET_PROPERTY,
    property,
    pushToUrl: { property }
  });
};

export const setModalRef = ref => dispatch => {
  return dispatch({
    type: actions.SET_MODAL_REF,
    ref
  });
};

export const setModalSize = size => dispatch => {
  return dispatch({
    type: actions.SET_MODAL_SIZE,
    size
  });
};

export const clearModalSize = () => dispatch => {
  return dispatch({
    type: actions.CLEAR_MODAL_SIZE
  });
};
