import React, { Component } from "react";
import { LivecamOverlayControl } from "./LivecamOverlayControl";
import "./LivecamVideo.scss";

export class LivecamVideo extends Component {
  render() {
    const { cameraModel, canControl } = this.props;

    return (
      <div className="livecam-video-container">
        <img
          alt="Livecam is offline"
          className="livecam-video"
          key={cameraModel.videoUrl}
          src={cameraModel.videoUrl}
        />
        {canControl ? (
          <LivecamOverlayControl cameraModel={cameraModel} />
        ) : null}
      </div>
    );
  }
}
