import React, { Component } from "react";
import TimelinePointLayer from "./TimelinePointLayer.js";
import drag from "../../resources/icons/drag.svg";

class Timeline extends Component {
  constructor(props) {
    super(props);
    this.label = React.createRef();
  }

  onDragStart(e) {
    this.props.dragStart();
    e.dataTransfer.setData("text/html", this.label.current);
    e.dataTransfer.setDragImage(this.label.current, 20, 20);
  }

  onDragOver(e) {
    this.props.dragOver();
  }

  render() {
    const data = this.props.data
      ? this.props.data.all
        ? this.props.data.all
        : this.props.data
      : [];

    const selected = (this.props.data ? this.props.data.selected : []) || [];

    return (
      <div className="timeline-layer" id={`${this.props.id}-timeline-layer`}>
        <div
          className="timeline-label-container"
          onDragOver={this.onDragOver.bind(this)}
          ref={this.label}
        >
          <div
            className="timeline-drag"
            draggable
            onDragStart={this.onDragStart.bind(this)}
            alt={null}
          >
            <img src={drag} alt="" />
            <div className="guideline" />
          </div>
          {this.props.opacity ? (
            <div className="timeline-opacity">
              <input
                type="range"
                min="1"
                max="100"
                defaultValue={(this.props.currentOpacity || 1) * 100}
                className="opacity-slider"
                onChange={e => {
                  let opacity = e.nativeEvent.target.value / 100;
                  this.props.updateOpacity(opacity);
                }}
              />
            </div>
          ) : null}
          <div className="timeline-label" onClick={this.props.onClick}>
            {this.props.name}
          </div>
          <div
            className="timeline-close"
            onClick={() => this.props.setLayerInactive()}
          >
            &times;
          </div>
        </div>
        <svg
          className="timeline-svg"
          width={this.props.size.width}
          height={
            this.props.data
              ? this.props.size.height
              : this.props.size.height / 2
          }
          style={{
            width: this.props.size.width,
            height: this.props.data
              ? this.props.size.height
              : this.props.size.height / 2
          }}
        >
          {this.props.component ? (
            <this.props.component
              data={data}
              scale={this.props.scale}
              size={this.props.size}
            />
          ) : (
            <TimelinePointLayer
              data={data.map((r, i) => ({
                date: r.date,
                r: Math.min(40, Math.sqrt(r.radius)),
                key: i
              }))}
              margins={this.props.margins}
              size={this.props.size}
              scale={this.props.scale}
            />
          )}
          {selected ? (
            <TimelinePointLayer
              data={selected.map((r, i) => ({
                date: r.date,
                r: Math.min(40, Math.sqrt(r.radius)),
                key: i
              }))}
              scale={this.props.scale}
              margins={this.props.margins}
              size={this.props.size}
              color="#0000ff"
            />
          ) : null}
        </svg>
      </div>
    );
  }
}

export default Timeline;
