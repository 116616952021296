import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { layers } from "../../constants/layers.js";
import { setActiveLayers } from "../../actions/layer_actions.js";
import ValueFilter from "../SpeciesList/ValueFilter";
import * as actions from "../../constants/action_names.js";
import push from "../../constants/push.js";
import "./FloraModal.scss";
import SpeciesDrilldown from "../SpeciesList/SpeciesDrilldown";

const SQ_M_PER_POINT = 200 * 0.09 * 0.01;

const mapStateToProps = state => ({
  flora: state.flora,
  layerSelector: state.layerSelector,
  timeline: state.timeline
});

const mapDispatchToProps = dispatch => ({
  setFilter: filter =>
    dispatch({
      type: actions.FLORA_SET_FILTER,
      filter,
      pushToUrl: { florafilter: filter }
    }),
  goBack: () => dispatch(push("/flora")),
  activate: (layerName, previousLayers, bounds) => {
    let newLayers = [...previousLayers];

    if (newLayers.indexOf(layerName) < 0) {
      newLayers.push(layerName);
    }

    const layer = layers[layerName];
    dispatch(setActiveLayers(newLayers));

    if (layer.timeline && layer.timeline.action) {
      dispatch(layer.timeline.action());
    }

    if (layer.map && layer.map.action) {
      if (Array.isArray(layer.map.action)) {
        layer.map.action.forEach(a => dispatch(a(bounds)));
      } else {
        dispatch(layer.map.action(bounds));
      }
    }

    dispatch(push("/" + layerName));
  }
});

class FloraModal extends Component {
  componentDidUpdate(prevProps) {
    if (!this.props.flora.filter) {
      this.props.setFilter("native");
    }
  }
  render() {
    let species = this.props.flora.species || [];
    species = species.sort((a, b) => b.total - a.total);

    const lifeforms = ["graminoid", "forb", "vine", "shrub", "tree"];
    const nativestatus = ["native", "nonnative"];

    if (this.props.flora.filter) {
      if (
        lifeforms.indexOf(this.props.flora.filter) >= 0 ||
        this.props.flora.filter === "graminoid"
      ) {
        species = species.filter(s => s.lifeform === this.props.flora.filter);
      }

      if (nativestatus.indexOf(this.props.flora.filter) >= 0) {
        species = species.filter(
          s => s.nativestatus === this.props.flora.filter
        );
      }
    }

    let totals = species.reduce(
      (acc, cur) => ({
        ...acc,
        [cur.namefamily]: {
          ...cur,
          photo:
            acc[cur.namefamily] && acc[cur.namefamily].photo
              ? acc[cur.namefamily].photo
              : cur.photo === ""
              ? null
              : cur.photo,
          description: cur.family_description,
          total:
            (acc[cur.namefamily] ? acc[cur.namefamily].total : 0) + cur.total
        }
      }),
      {}
    );

    let categories = Object.keys(totals)
      .reduce((acc, cur) => [...acc, { ...totals[cur], family: cur }], [])
      .sort((a, b) => b.total - a.total);

    if (this.props.flora.geojson && this.props.match.params.n) {
      let activePoint = this.props.flora.geojson.features.filter(
        f => f.properties.n === +this.props.match.params.n
      );

      if (activePoint.length > 0) {
        activePoint = activePoint[0];

        species = Object.keys(activePoint.properties)
          .reduce((acc, cur) => {
            let sp = this.props.flora.species.filter(
              s => s.plantcode.toLowerCase() === cur
            );

            if (sp.length > 0) {
              sp = sp[0];
              return [
                ...acc,
                {
                  ...sp,
                  total: activePoint.properties[cur],
                  n: +this.props.match.params.n
                }
              ];
            } else {
              return acc;
            }
          }, [])
          .filter(s => s.total > 0);
      }
    }

    return (
      <div className="FloraModal">
        <div className="title">Flora measurements</div>
        <div className="description">
          <p>
            Researchers and field workers at MPG Ranch regularly inventory plant
            cover by measuring a 100ft crosshair around more than 500 points on
            the ranch. Use the map and interface below to explore the foral
            biodiversity of the ranch and to provide context to the problem
            plants discussed in the{" "}
            <span
              className="link"
              onClick={() => {
                this.props.activate(
                  "restoration",
                  this.props.layerSelector.active,
                  this.props.timeline.bounds
                );
              }}
            >
              Restoration updates
            </span>
            .
          </p>
          <p>
            Regions around each sample point are colored according to the
            prevalence of the currently selected organism or category. Click
            below to change the selection.
          </p>
        </div>

        <ValueFilter
          values={nativestatus}
          filter={this.props.flora.filter}
          setFilter={this.props.setFilter.bind(this)}
        />

        <ValueFilter
          values={lifeforms.map(l => (l === "graminoid" ? "grass" : l))}
          filter={
            this.props.flora.filter === "graminoid"
              ? "grass"
              : this.props.flora.filter
          }
          setFilter={l =>
            l === "grass"
              ? this.props.setFilter("graminoid")
              : this.props.setFilter(l)
          }
        />

        <SpeciesDrilldown
          categories={categories.map(d => ({
            category: d.family,
            category_display: d.family,
            count: d.total * SQ_M_PER_POINT,
            title: d.family,
            photo: d.photo,
            description: d.description
          }))}
          species={species.map(d => ({
            location: d.n,
            category: d.namefamily,
            category_display: d.namefamily,
            species: d.plantcode,
            count: d.total * SQ_M_PER_POINT,
            title: d.namescientific,
            photo: d.photo,
            description: d.description
          }))}
          setFilter={filter => this.props.setFilter(filter || "native")}
          location={+this.props.match.params.n}
          filter={this.props.flora.filter}
          siteTerminology={"transect point"}
          unitTerminology={" square meters covered"}
          goTo={this.props.goBack.bind(this)}
          valueFilter={
            this.props.flora.filter === "diversity" ||
            lifeforms.indexOf(this.props.flora.filter) >= 0 ||
            nativestatus.indexOf(this.props.flora.filter) >= 0
          }
        />
      </div>
    );
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(FloraModal)
);
