import React, { useState } from "react";

import { BlockedLivecamVideo } from "./BlockedLivecamVideo";
import { hasRole, ROLE_VIEW_PROTECTED_LIVECAM } from "../../constants/auth";
import { liveCamImageURL } from "../../loaders/livecamLoader";
import "./AllLivecamModal.scss";

const LivecamItem = ({ canView, name, host, thermal, onClick }) => {
  const [imageUrl, setImageUrl] = useState(null);
  const [imageLoading, setImageLoading] = useState(false);
  const [imageError, setImageError] = useState(false);

  if (!imageUrl && !imageLoading) {
    setImageLoading(true);

    const url = liveCamImageURL(host, thermal);
    fetch(url)
      .then(r => r.blob())
      .then(blob => {
        setImageUrl(URL.createObjectURL(blob));
        setImageLoading(false);
      });
  }

  return (
    <div className="LivecamItem" onClick={onClick}>
      {!canView ? (
        <BlockedLivecamVideo />
      ) : !imageError && imageUrl ? (
        <img
          className="LivecamItem-image"
          src={imageUrl}
          alt={`Livecam preview for ${name}`}
          onError={() => setImageError(true)}
        />
      ) : null}
      <div className="LivecamItem-name-wrapper">
        <div className="LivecamItem-name">{name}</div>
      </div>
    </div>
  );
};

const AllLivecamModal = ({ auth, livecams, selectLivecam }) => {
  const canViewProtected = hasRole(auth, ROLE_VIEW_PROTECTED_LIVECAM);
  const sortedLivecams = livecams
    .slice()
    .sort((a, b) => a.properties.name.localeCompare(b.properties.name));
  return (
    <div className="AllLivecamModal">
      <header>
        <div className="title">Live Cameras</div>
      </header>
      <div className="LivecamItems">
        {sortedLivecams.map(livecam => {
          const { id, host, name, thermal } = livecam.properties;
          const canView = !livecam.properties.protected || canViewProtected;
          return (
            <LivecamItem
              key={id}
              host={host}
              name={name}
              thermal={thermal}
              onClick={() => selectLivecam(name)}
              canView={canView}
            />
          );
        })}
      </div>
    </div>
  );
};

export default AllLivecamModal;
