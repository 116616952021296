import * as actions from "../../constants/action_names";
import * as config from "../../constants/config";
import { fetchSql } from "../../constants/carto";

export const fetchVideoPoints = bounds => {
  const sql = `SELECT cartodb_id AS id, name, created_time, embed_url, height, width, the_geom, thumbnail_url
    FROM ${config.video_table}
    WHERE created_time > '${bounds[0].toISOString()}' AND created_time < '${bounds[1].toISOString()}' AND the_geom IS NOT NULL`;

  return dispatch => {
    dispatch({
      type: actions.VIDEO_POINTS_REQUEST,
      geojson: null
    });

    return fetchSql(sql, "geojson")
      .then(geojson => {
        dispatch({
          type: actions.VIDEO_POINTS_RESPONSE,
          geojson
        });
      })
      .catch(error =>
        dispatch({
          type: actions.VIDEO_POINTS_ERROR_RESPONSE,
          error
        })
      );
  };
};

export const fetchVideoOverview = () => {
  const sql = `SELECT date_trunc('week', created_time) AS week, COUNT(*) AS count
      FROM ${config.video_table}
      GROUP BY week`;

  return dispatch => {
    dispatch({
      type: actions.VIDEO_OVERVIEW_REQUEST,
      json: null
    });

    return fetchSql(sql, "json")
      .then(json => {
        json.rows = json.rows.map(row => ({
          ...row,
          date: new Date(row.week)
        }));
        dispatch({
          type: actions.VIDEO_OVERVIEW_RESPONSE,
          json
        });
      })
      .catch(error =>
        dispatch({
          type: actions.VIDEO_OVERVIEW_ERROR_RESPONSE,
          error: error.message
        })
      );
  };
};
