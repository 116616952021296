import * as actions from "../constants/action_names.js";
import queryString from "query-string";

const query = queryString.parse(window.location.href.split("?")[1] || "");

const initialState = {
  key: query.key,
  match: null,
  roles: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.AUTH_DATA_REQUEST:
      return {
        ...state,
        _fetchingAuthData: true,
        match: false
      };
    case actions.AUTH_DATA_RESPONSE:
      return {
        ...state,
        _fetchingAuthData: false,
        match: action.roles.length > 0,
        key: action.key,
        roles: action.roles
      };
    case actions.AUTH_DATA_ERROR_RESPONSE:
      return {
        ...state,
        _fetchingAuthData: false,
        match: false
      };
    default:
      return state;
  }
};
