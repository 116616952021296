import { withLeaflet } from "react-leaflet";
import { connect } from "react-redux";
import push from "../../constants/push.js";
import { slugify } from "../../constants/app.js";
import makeComponent from "../CritterMap/makeComponent.js";
import SvgLayer from "../CritterMap/SvgLayer";
import { pointColors } from "../../constants/layers_static.js";

export function getCorrectedDegrees(pan, degreesToNorth) {
  /*
   * Livecams report pan values that are not relative to north. They have a
   * property that indicates how far their pan is from north that we can use
   * to calculate the degrees panned relative to north.
   */
  const panPositive = pan < 0 ? pan + 360 : pan;
  const northPositive =
    degreesToNorth < 0 ? degreesToNorth + 360 : degreesToNorth;
  let corrected = panPositive - northPositive;
  if (corrected < 0) {
    corrected += 360;
  }
  return corrected;
}

function toRadians(deg) {
  return (Math.PI * (deg - 90)) / 180;
}

export function headingPath(lineLength, heading, widthDeg) {
  const l1 = toRadians(heading - widthDeg / 2);
  const l2 = toRadians(heading + widthDeg / 2);

  let l1x = Math.cos(l1) * lineLength,
    l1y = Math.sin(l1) * lineLength,
    l2x = Math.cos(l2) * lineLength,
    l2y = Math.sin(l2) * lineLength;

  return `M ${l1x} ${l1y} L 0 0 L ${l2x} ${l2y}`;
}

class LivecamLayer extends SvgLayer {
  rezoom() {
    // Force update of data, which will avoid collisions
    this.updateData(this.data);
  }

  updateData(data) {
    // Avoid collisions
    const collisionlessFeatures = this.avoidCollisions(data);

    const radius = 10;
    const selectedRadius = 20;
    const d = this.g
      .selectAll("g")
      .data(collisionlessFeatures.features, d => d.properties.id);

    const g = d
      .enter()
      .append("g")
      .attr("class", "livecam-g")
      .on("click", d => this.onClick(d.properties.name))
      .attr("transform", d => `translate(${d.x}, ${d.y})`);

    g.append("circle")
      .classed("livecam-inner-circle", true)
      .style("fill", pointColors.livecam)
      .style("mix-blend-mode", "overlay")
      .style("opacity", 0.8);

    // If not a raptor nest, add heading indicator
    g.filter(d => !d.properties.is_raptor_nest)
      .append("path")
      .classed("heading-indicator", true)
      .attr("pointer-events", "none")
      .style("stroke", "black")
      .style("fill", "none")
      .style("stroke-width", 2);

    // If a raptor nest, add a ring
    g.filter(d => d.properties.is_raptor_nest)
      .append("circle")
      .classed("raptor-nest-ring", true)
      .style("stroke", "black")
      .style("fill", "transparent");

    // If bispectral, use a half-circle
    g.filter(d => d.properties.bispectral)
      .append("path")
      .classed("bispectral-half-circle", true)
      .style("stroke", "none")
      .style("fill", "rgba(0, 0, 0, 0.6)")
      .attr("d", d => {
        let r = d.properties._actionMapSelected ? selectedRadius : radius;
        return `M0 -${r} A ${r} ${r} 0 0 0 0 ${r}`;
      });

    const merged = g.merge(d);

    merged
      .select(".livecam-inner-circle")
      .style("r", d =>
        d.properties._actionMapSelected ? selectedRadius : radius
      )
      .style("stroke-width", d => (d.properties._actionMapSelected ? 5 : 0))
      .style("stroke", "white");

    merged
      .select(".raptor-nest-ring")
      .style(
        "r",
        d => (d.properties._actionMapSelected ? selectedRadius : radius) * 1.25
      );

    merged
      .select(".heading-indicator")
      .transition()
      .attr("d", d => {
        const lineLength = d.properties._actionMapSelected ? 30 : 20;
        return headingPath(lineLength, 0, 120);
      })
      .attr("transform", d => {
        const { pan, deg_to_north } = d.properties;
        const angle = getCorrectedDegrees(pan, deg_to_north);
        return angle ? `rotate(${angle})` : null;
      });

    d.exit().remove();
  }
}

const mapStateToProps = state => ({
  livecam: state.livecam
});

const mapDispatchToProps = dispatch => ({
  go: cam => {
    dispatch(push("/livecams/" + slugify(cam)));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLeaflet(makeComponent(LivecamLayer)));
