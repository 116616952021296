import React, { Component } from "react";
import "./Species.scss";

const BUFFER = 4;
const HWIDTH = 175;

class Species extends Component {
  constructor(props) {
    super(props);
    this.div = React.createRef();

    this.state = { hover: false };
  }

  render() {
    const {
      title,
      info,
      height,
      selected,
      photo,
      description,
      alt,
      unitTerminology
    } = this.props;

    let formattedInfo =
      info > 1000
        ? Math.round(info / 1000) + "k"
        : info > 10 || Math.round(info) === info
        ? Math.round(info)
        : info.toFixed(1);

    let formattedInfoFull =
      Math.round(info) === info ? Math.round(info) : info.toFixed(1);

    const { hover } = this.state;

    return (
      <div
        className={
          "species " +
          (selected ? "active" : "inactive") +
          (alt ? " alt" : "") +
          (this.state.hover ? " hover" : "")
        }
        onClick={this.props.setFilter}
        ref={this.div}
        onMouseEnter={() => {
          let rect = this.div.current.getBoundingClientRect();
          let body = this.props.container.current.getBoundingClientRect();

          let top = rect.top - body.top - 4;
          let left = rect.left - body.left - BUFFER;
          let width = body.width - BUFFER * 2;
          this.setState({ hover: true, top, left, width, height: rect.height });
        }}
        onMouseLeave={() => {
          this.setState({ hover: false });
        }}
        style={{
          width: height,
          height: height,
          marginBottom: hover ? 244 - this.state.height + "px" : null
        }}
      >
        <div
          className={"innerContainer" + (this.state.hover ? " hover" : "")}
          style={
            this.state.hover
              ? {
                  left: -this.state.left,
                  width: this.state.width,
                  height: "240px"
                }
              : {}
          }
        >
          {this.state.hover ? (
            <div className="titlebar">
              <div className="hovertitle">{title}</div>
              <div className="hoverinfo">
                {formattedInfoFull + unitTerminology}
              </div>
            </div>
          ) : null}
          <div className="top">
            <div
              className={"introBox" + (height < 120 ? " small" : "")}
              style={{
                height: hover ? HWIDTH : height,
                width: hover ? HWIDTH : height
              }}
            >
              {photo ? (
                <img
                  src={photo}
                  alt={title + ": " + description}
                  style={{
                    width: hover ? HWIDTH : height,
                    height: hover ? HWIDTH : height
                  }}
                />
              ) : hover ? null : (
                <div
                  className={"placeholder" + (alt ? " alt" : "")}
                  style={{
                    width: hover ? HWIDTH : height,
                    height: hover ? HWIDTH : height
                  }}
                />
              )}
              <div className="title">{title}</div>
              <div className="info">{formattedInfo}</div>
            </div>
            {this.state.hover ? (
              <div className="sidebox">
                <div className="description">
                  {description
                    ? description
                    : "We don't have a description for this organism yet."}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default Species;
