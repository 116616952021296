import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers/rootReducer";
import { routerMiddleware, replace } from "connected-react-router";
import { createBrowserHistory } from "history";
import queryString from "query-string";

export const history = createBrowserHistory({
  // set the basename based on either a URL with a domain (from a .env file)
  // or a URL without a domain (from package.json)
  basename:
    process.env.PUBLIC_URL.indexOf("://") >= 0
      ? process.env.PUBLIC_URL.split("://")[1]
          .split("/")
          .slice(1)
          .join("")
      : process.env.PUBLIC_URL
});

const syncQueryParams = store => next => action => {
  if (action.pushToUrl) {
    const state = store.getState();
    const current = queryString.parse(state.router.location.search);
    let newSearch = { ...current, ...action.pushToUrl };
    Object.keys(newSearch).forEach(k => {
      if (!newSearch[k]) delete newSearch[k];
    });

    store.dispatch(
      replace({
        pathname: state.router.location.pathname,
        search: queryString.stringify(newSearch)
      })
    );
  }

  return next(action);
};

export default function configureStore() {
  return createStore(
    rootReducer(history),
    {},
    compose(applyMiddleware(routerMiddleware(history), syncQueryParams, thunk))
  );
}
