import * as actions from "../../constants/action_names.js";
import { authCheckKeyUrl } from "../../constants/config";

export const fetchAuth = key => {
  return dispatch => {
    dispatch({ type: actions.AUTH_DATA_REQUEST });

    return fetch(authCheckKeyUrl, {
      method: "POST",
      body: JSON.stringify({ key }),
      headers: new Headers({ "Content-Type": "application/json" })
    })
      .then(response => response.json())
      .then(json =>
        dispatch({
          type: actions.AUTH_DATA_RESPONSE,
          roles: json.roles,
          key
        })
      )
      .catch(error =>
        dispatch({
          type: actions.AUTH_DATA_ERROR_RESPONSE,
          error: error.message
        })
      );
  };
};
