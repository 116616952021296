import React, { Component } from "react";
import moment from "moment";
import * as d3 from "d3";
import MouseoverIndicatorChart from "../charts/MouseoverIndicatorChart";
import "./SoilChart.scss";

const potentialFieldCapacity = -33;
const potentialPermanentWiltingPoint = -1500;

const SoilConditionsMetric = ({ name, data, valueFormatter }) => {
  return (
    <div className="soil-conditions-metric">
      <div>{name}</div>
      <div className="soil-conditions-metric-depths">
        {data.map(d => (
          <div className="soil-conditions-metric-depth" key={d.key}>
            <div className="soil-conditions-metric-depth-name">
              <span
                className={`soil-conditions-depth-indicator depth-${d.key}`}
              ></span>
              {d.key} inches
            </div>
            <div className="soil-conditions-metric-values">
              {d.values.map(({ port_number, value }) => (
                <div className="metric-value" key={port_number}>
                  {valueFormatter(value)}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const SoilConditions = ({ time, data }) => {
  const timeMoment = moment(time);

  const nested = d3
    .nest()
    .key(d => d.data_type)
    .key(d => d.sensor_depth)
    .sortValues((a, b) => d3.ascending(a.sensor_depth, b.sensor_depth))
    .entries(data);

  return (
    <div className="soil-conditions">
      <div>
        Conditions on {timeMoment.format("MMMM Do YYYY")} at{" "}
        {timeMoment.format("h:mm a")}:
      </div>
      <div className="soil-conditions-metrics">
        <SoilConditionsMetric
          name="temperature"
          data={nested.filter(n => n.key === "temp")[0].values}
          valueFormatter={value => `${d3.format(".1f")(value)}º`}
        />
        <SoilConditionsMetric
          name="potential"
          data={nested.filter(n => n.key === "potential")[0].values}
          valueFormatter={value => `${d3.format(".1f")(value)}kPa`}
        />
      </div>
    </div>
  );
};

export class SoilChart extends Component {
  constructor(props) {
    super(props);
    this.marginBottom = 20;
    this.marginLeft = 60;

    this.state = {
      selectedTime: null
    };
  }

  nestData(data) {
    return d3
      .nest()
      .key(d => d.data_type)
      .key(d => d.sensor_depth)
      .key(d => d.port_number)
      .sortValues((a, b) => d3.ascending(a.date, b.date))
      .entries(data);
  }

  componentDidMount() {
    if (this.props.width && this.props.height) {
      this.chartHeight = this.calculateChartHeight();
      this.drawXAxis();
      this.drawPotentialYAxis();
      this.drawTemperatureYAxis();
      this.dates = this.getDates();
    }
  }

  componentDidUpdate() {
    if (this.props.width && this.props.height) {
      this.chartHeight = this.calculateChartHeight();
      this.drawXAxis();
      this.drawPotentialYAxis();
      this.drawTemperatureYAxis();
      this.dates = this.getDates();
    }
  }

  getDates() {
    return d3
      .set(this.props.data.map(d => d.date.toISOString()))
      .values()
      .sort();
  }

  calculateChartHeight() {
    return (this.props.height - 150) / 2;
  }

  drawXAxis() {
    const xAxis = d3.axisBottom(this.xScale).ticks(4);

    const selection = d3
      .select(this.potentialSvg)
      .selectAll(".x-axis")
      .data([xAxis]);

    const enter = selection
      .enter()
      .append("g")
      .classed("x-axis", true)
      .attr(
        "transform",
        `translate(0, ${this.chartHeight - this.marginBottom})`
      );

    enter.merge(selection).call(xAxis);
  }

  drawYAxis(rootSelection, scale, label) {
    const yAxis = d3.axisLeft(scale).ticks(4, "d");

    const axisSelection = rootSelection.selectAll(".y-axis").data([yAxis]);
    const axisEnter = axisSelection
      .enter()
      .append("g")
      .classed("y-axis", true);
    axisEnter
      .merge(axisSelection)
      .call(yAxis)
      .attr("transform", `translate(${this.marginLeft}, 0)`);

    const labelSelection = rootSelection.selectAll(".y-label").data([label]);
    const labelEnter = labelSelection
      .enter()
      .append("text")
      .classed("y-label", true);

    labelEnter
      .merge(labelSelection)
      .attr("transform", `translate(10, ${this.chartHeight / 2}) rotate(-90)`)
      .text(d => d);
  }

  drawPotentialYAxis() {
    this.drawYAxis(
      d3.select(this.potentialSvg),
      this.potentialYScale,
      "Potential (kPa)"
    );
  }

  drawTemperatureYAxis() {
    this.drawYAxis(
      d3.select(this.temperatureSvg),
      this.temperatureY,
      "Temperature (ºF)"
    );
  }

  render() {
    const { data, height, width } = this.props;
    let { selectedTime } = this.state;
    const dates = this.getDates();
    if (!selectedTime && dates) {
      selectedTime = new Date(dates.slice(-1)[0]);
    }

    const nested = this.nestData(data);
    if (data.length === 0 || !width || !height) {
      return <div>No data</div>;
    }

    this.chartHeight = this.calculateChartHeight();

    this.xScale = d3
      .scaleTime()
      .domain(d3.extent(data, d => d.date))
      .range([this.marginLeft, width - this.marginLeft])
      .nice();

    const flatTemperatureData = data.filter(d => d.data_type === "temp");
    const temperatureYExtent = d3.extent(flatTemperatureData, d => d.value);
    temperatureYExtent[0] = Math.min(0, temperatureYExtent[0]);
    this.temperatureY = d3
      .scaleLinear()
      .domain(temperatureYExtent)
      .range([this.chartHeight - this.marginBottom, this.marginBottom])
      .nice();

    const flatPotentialData = data.filter(d => d.data_type === "potential");
    const potentialYExtent = d3.extent(flatPotentialData, d => d.value);
    potentialYExtent[0] = Math.min(
      potentialYExtent[0],
      potentialPermanentWiltingPoint
    );
    this.potentialYScale = d3
      .scaleLog()
      .domain(potentialYExtent)
      .range([this.chartHeight - this.marginBottom, this.marginBottom])
      .nice();

    const guideLines = [
      { label: "field capacity", value: potentialFieldCapacity },
      {
        label: "permanent wilting point",
        value: potentialPermanentWiltingPoint
      }
    ];

    const temperatureLineGenerator = d3
      .line()
      .x(d => this.xScale(d.date))
      .y(d => this.temperatureY(d.value));

    const potentialLineGenerator = d3
      .line()
      .x(d => this.xScale(d.date))
      .y(d => this.potentialYScale(d.value));

    const potentialData = nested.filter(entry => entry.key === "potential")[0]
      .values;
    const temperatureData = nested.filter(entry => entry.key === "temp")[0]
      .values;

    return (
      <React.Fragment>
        <div className="soil-chart">
          <MouseoverIndicatorChart
            dates={this.getDates()}
            height={this.chartHeight * 2}
            onXValue={selectedTime => this.setState({ selectedTime })}
            width={width}
            xScale={this.xScale}
          >
            <g
              transform={`translate(0, 0)`}
              className="soil-temperature"
              height={this.chartHeight}
              ref={r => (this.temperatureSvg = r)}
            >
              {temperatureData.map(depth => {
                return depth.values.map(sensor => (
                  <path
                    className={`soil-temperature-${depth.key}-inches`}
                    key={sensor.key}
                    d={temperatureLineGenerator(sensor.values)}
                  />
                ));
              })}
            </g>
            <g
              transform={`translate(0, ${this.chartHeight})`}
              className="soil-potential"
              height={this.chartHeight}
              ref={r => (this.potentialSvg = r)}
            >
              {guideLines.map(guideLine => (
                <React.Fragment key={guideLine.label}>
                  <line
                    x1={this.marginLeft}
                    x2={width - this.marginLeft}
                    y1={this.potentialYScale(guideLine.value)}
                    y2={this.potentialYScale(guideLine.value)}
                    className="soil-potential-guide-line"
                  />
                  <text
                    transform={`translate(${width -
                      this.marginLeft}, ${this.potentialYScale(
                      guideLine.value
                    ) - 2})`}
                    className="soil-potential-guide-line-label"
                  >
                    {guideLine.label}
                  </text>
                </React.Fragment>
              ))}

              {potentialData.map(depth => {
                return depth.values.map(sensor => (
                  <path
                    className={`soil-potential-${depth.key}-inches`}
                    key={sensor.key}
                    d={potentialLineGenerator(sensor.values)}
                  />
                ));
              })}
            </g>
          </MouseoverIndicatorChart>
          <div className="soil-metadata-container">
            <div className="soil-legend-container">
              <svg className="soil-legend" height="50">
                <g transform="translate(10, 10)">
                  <line
                    x1="5"
                    x2="25"
                    y1="5"
                    y2="5"
                    className="legend-6-inches"
                  />
                  <text transform="translate(30, 10)">6 inches deep</text>
                </g>
                <g transform="translate(10, 30)">
                  <line
                    x1="5"
                    x2="25"
                    y1="5"
                    y2="5"
                    className="legend-36-inches"
                  />
                  <text transform="translate(30, 10)">36 inches deep</text>
                </g>
              </svg>
            </div>
            {selectedTime ? (
              <SoilConditions
                time={selectedTime}
                data={data.filter(
                  d => d.date.toISOString() === selectedTime.toISOString()
                )}
              />
            ) : null}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
