import * as d3 from "d3";
import { withLeaflet } from "react-leaflet";
import { connect } from "react-redux";
import push from "../../constants/push.js";
import makeComponent from "../CritterMap/makeComponent.js";
import SvgLayer from "../CritterMap/SvgLayer";
import { pointColors } from "../../constants/layers_static";

class BirdLayer extends SvgLayer {
  constructor(opts) {
    super(opts);

    this.birdScale = d3.scaleOrdinal([0, 5, 10, 15, 20, 25, 30, 35]);
    this.sizeScale = d3
      .scaleSqrt()
      .domain([0, 10])
      .range([0, 10]);
  }

  updateData(data) {
    super.updateData(data);

    let d = this.g
      .selectAll("circle")
      .data(this.data.features, d => d.properties.id);

    let g = d
      .enter()
      .append("circle")
      .on("click", d => this.onClick(d.properties.loc_id))
      .attr("r", 0);

    g.merge(d)
      .classed("selected", d => d.properties._actionMapSelected)
      .attr("transform", this.translate.bind(this))
      .style("fill", pointColors.eBird)
      .style("opacity", d => (d.properties._actionMapSelected ? 0.9 : 0.7))
      .style("stroke-width", d => (d.properties._actionMapSelected ? 5 : 1))
      .transition()
      .duration(300)
      .attr("r", d => this.sizeScale(d.properties.observations));

    this.g.select(".selected").raise();

    d.exit()
      .transition()
      .duration(300)
      .attr("r", 0)
      .remove();
  }
}

const mapStateToProps = state => ({
  eBird: state.eBird,
  timeline: state.timeline
});

const mapDispatchToProps = dispatch => ({
  go: obs => {
    dispatch(push("/birds/" + obs));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLeaflet(makeComponent(BirdLayer)));
