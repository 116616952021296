import React, { Component } from "react";
import { connect } from "react-redux";
import ReactResizeDetector from "react-resize-detector";
import { withRouter } from "react-router";
import moment from "moment";
import { setFilter } from "../../actions/trailcam";
import {
  fetchTrailcamCategories,
  fetchTrailcamCategoryImages
} from "../../actions/async/trailcam";
import { slugify, trailcamUrlDateString } from "../../constants/app";
import { hasRole, ROLE_VIEW_PROTECTED_BUCKEYECAM } from "../../constants/auth";
import push from "../../constants/push";
import ValueFilter from "../SpeciesList/ValueFilter";
import GraphDateTime from "./GraphDateTime";
import ImageList from "./ImageList";
import * as d3 from "d3";

const mapStateToProps = state => ({
  auth: state.auth,
  trailcams: state.trailcams,
  timeline: state.timeline
});

const mapDispatchToProps = dispatch => ({
  fetchCategories: (bounds, category) => {
    dispatch(fetchTrailcamCategories(bounds, category));
  },
  fetchImages: (bounds, category, canViewProtected = false) => {
    dispatch(fetchTrailcamCategoryImages(bounds, category, canViewProtected));
  },
  setFilter: filter => dispatch(setFilter(filter)),
  goToDate: date => {
    const datePart = moment(date).format(trailcamUrlDateString);
    dispatch(push(`/trailcams/by-category/${encodeURIComponent(datePart)}`));
  },
  goToPhoto: (date, photo) => {
    dispatch(
      push(
        `/trailcams/by-category/${slugify(photo.text)}/${date}/${
          photo.filename
        }`
      )
    );
  }
});

class CategoryTrailcamModal extends Component {
  componentDidMount() {
    this.props.fetchCategories();
    this.props.fetchImages(
      this.props.timeline.bounds,
      this.props.trailcams.filter,
      this.canViewProtected()
    );
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.trailcams.images && !prevProps.trailcams.images) ||
      (this.props.trailcams.images &&
        prevProps.trailcams.images &&
        this.props.trailcams.images.length !==
          prevProps.trailcams.images.length)
    ) {
      const maxDate = d3.max(this.props.trailcams.images.map(i => i.date));
      const minDate = d3.min(this.props.trailcams.images.map(i => i.date));
      const date = moment(this.props.match.params.date, trailcamUrlDateString);

      if (date < minDate || date > maxDate) {
        this.props.goToDate(maxDate);
      }
    }

    if (
      this.props.trailcams.filter !== prevProps.trailcams.filter ||
      this.props.timeline.bounds !== prevProps.timeline.bounds
    ) {
      this.props.fetchImages(
        this.props.timeline.bounds,
        this.props.trailcams.filter,
        this.canViewProtected()
      );
      this.props.goToDate(
        this.props.match.params.cam,
        Math.max(this.props.timeline.bounds[0], this.props.timeline.bounds[1])
      );
    }
  }

  canViewProtected() {
    return hasRole(this.props.auth, ROLE_VIEW_PROTECTED_BUCKEYECAM);
  }

  render() {
    const { categories } = this.props.trailcams;
    const { params } = this.props.match;

    const endDate = params.date
      ? moment(params.date, "YYYY-MM-DD-H")
      : moment(this.props.timeline.bounds[1]);

    let text = "";
    if (
      this.props.trailcams.images &&
      this.props.trailcams.images.length === 0 &&
      !this.canViewProtected()
    ) {
      text = "No photos. These cameras may be offline for the hunting season.";
    }

    return (
      <ReactResizeDetector
        handleWidth
        render={({ width }) => (
          <React.Fragment>
            {categories && categories.length > 0 ? (
              <ValueFilter
                values={categories}
                filter={this.props.trailcams.filter}
                setFilter={this.props.setFilter}
              />
            ) : null}
            <div className="title">{text}</div>

            <GraphDateTime
              data={this.props.trailcams.images || []}
              width={width}
              height={250}
              onClick={d => this.props.goToDate(d)}
              start={endDate}
            />
            <ImageList
              end={endDate}
              width={width}
              images={this.props.trailcams.images || []}
              callback={d => this.props.goToDate(d)}
              goToPhoto={photo =>
                this.props.goToPhoto(
                  params.date || endDate.format("YYYY-MM-DD-H"),
                  photo
                )
              }
            />
          </React.Fragment>
        )}
      />
    );
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CategoryTrailcamModal)
);
