import * as actions from "../constants/action_names.js";

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.LIVECAM_DETAILS_REQUEST:
      return {
        ...state
      };
    case actions.LIVECAM_DETAILS_RESPONSE:
      // We received new details for a livecam, update it
      return {
        ...state,
        points: {
          ...state.points,
          features: state.points.features.map(feature => {
            // First check if this livecam was selected, if so keep that
            // in state. Since this gets updated frequently when viewing a
            // livecam the actions sometimes overlap.
            let properties = {
              ...feature.properties,
              _actionMapSelected:
                feature.properties.name === state._actionMapSelectedName
            };

            // Only update properties for the livecam we received updates for
            if (feature.properties.name === action.livecam.properties.name) {
              properties = {
                ...properties,
                ...action.details
              };
            }
            return {
              ...feature,
              properties
            };
          })
        }
      };
    case actions.LIVECAM_DETAILS_ERROR_RESPONSE:
      console.error(action.error);
      return { ...state };
    case actions.LIVECAM_POINTS_RESPONSE:
      return {
        ...state,
        points: action.geojson
      };
    case actions.LIVECAM_POINTS_ERROR_RESPONSE:
      return {
        ...state,
        error: action.error
      };
    case actions.LIVECAM_SELECT:
      return {
        ...state,
        points: {
          ...state.points,
          features: state.points.features.map(feature => {
            return {
              ...feature,
              properties: {
                ...feature.properties,
                _actionMapSelected: feature.properties.name === action.livecam
              }
            };
          })
        },
        _actionMapSelectedName: action.livecam
      };
    default:
      return state;
  }
};
