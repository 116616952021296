import * as actions from "../constants/action_names";

export const changeMobilePanel = mobilePanel => {
  return dispatch => {
    dispatch({
      type: actions.MOBILE_PANEL_CHANGE,
      mobilePanel
    });
  };
};
