import React, { Component } from "react";
import { Marker, LayerGroup } from "react-leaflet";
import L from "leaflet";
import { connect } from "react-redux";
import push from "../../constants/push";
import { slugify, trailcamUrl } from "../../constants/app";
import "./TimelapseLayer.css";

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  go: cam => {
    dispatch(push("/timelapse/" + slugify(cam)));
  }
});

class TimelapseLayer extends Component {
  constructor(props) {
    super(props);

    this.layerGroup = React.createRef();
  }

  componentDidUpdate() {
    this.layerGroup.current.leafletElement
      .getLayers()
      .map(l => (l._icon.style.zIndex = this.props.zIndex));
  }

  render() {
    return (
      <LayerGroup ref={this.layerGroup}>
        {this.props.data.features.map(f => {
          const url = trailcamUrl(
            f.properties.servername,
            f.properties.camera_number,
            f.properties.filename
          );
          let icon = L.icon({
            iconUrl: url,
            className: `timelapse-icon ${
              f.properties._actionMapSelected ? "selected" : ""
            }`
          });

          return (
            <Marker
              position={[f.geometry.coordinates[1], f.geometry.coordinates[0]]}
              icon={icon}
              key={url}
              onClick={() => this.props.go(f.properties.text)}
              zIndexOffset={
                f.properties._actionMapSelected ? 5000 : this.props.zIndex
              }
            />
          );
        })}
      </LayerGroup>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TimelapseLayer);
