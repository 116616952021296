import React from "react";
import moment from "moment";
import * as d3 from "d3";
import "./WeatherConditions.scss";

const Condition = ({ label, units, value }) => (
  <div className="weather-condition">
    <div className="weather-condition-label">{label}</div>
    <div>
      <span className="weather-condition-value">{value}</span>
      <span className="weather-condition-units">{units}</span>
    </div>
  </div>
);

const WeatherConditions = ({ data, time }) => {
  const getConditions = data => {
    return [
      {
        label: "Temperature",
        units: "ºF",
        value: d3.format(".1f")(data.temperature)
      },
      {
        label: "Precipitation",
        units: '"',
        value: d3.format(".1f")(data.precipitation)
      },
      {
        label: "Dewpoint",
        units: "ºF",
        value: d3.format(".1f")(data.dewPoint)
      },
      {
        label: "Wind Speed",
        units: "MPH",
        value: d3.format(".1f")(data.windSpeed)
      },
      {
        label: "Humidity",
        units: "%",
        value: d3.format(".1f")(data.humidity)
      }
    ];
  };

  const conditions = getConditions(data);
  const timeMoment = moment(time);

  return (
    <div className="WeatherConditions">
      <div className="WeatherConditions-title">
        Conditions on {timeMoment.format("MMMM Do YYYY")} at{" "}
        {timeMoment.format("h:mm a")}:
      </div>
      <div className="WeatherConditions-values">
        {conditions.map(condition => (
          <Condition key={condition.label} {...condition} />
        ))}
      </div>
    </div>
  );
};

export default WeatherConditions;
