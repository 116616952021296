import groupBy from "lodash.groupby";
import moment from "moment-timezone";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { selectInaturalistObservation } from "../../actions/inaturalist";
import push from "../../constants/push";
import SpeciesDrilldown from "../SpeciesList/SpeciesDrilldown";
import * as actions from "../../constants/action_names";
import {
  fetchInaturalistPoints,
  fetchInaturalistOverview
} from "../../actions/async/inaturalist";
import "./InaturalistModal.scss";

const mapStateToProps = state => ({
  inaturalist: state.inaturalist,
  timeline: state.timeline
});

const mapDispatchToProps = dispatch => ({
  goToObservation: observationId => {
    dispatch(push("/inaturalist/" + observationId));
  },
  selectInaturalistObservation: observation => {
    dispatch(selectInaturalistObservation(observation));
  },
  setFilter: (filter, bounds) => {
    dispatch({
      type: actions.INATURALIST_SET_FILTER,
      filter,
      pushToUrl: { inatfilter: filter }
    });

    dispatch(fetchInaturalistPoints(bounds, filter));

    dispatch(fetchInaturalistOverview(filter));
  }
});

class InaturalistModal extends Component {
  static getCurrentObservations(props) {
    if (!(props.inaturalist && props.inaturalist.points)) return [];
    let matches = props.inaturalist.points.features;
    return matches.map(match => match.properties);
  }

  static selectCurrentObservation(props) {
    const id = parseInt(props.match.params.observation, 10);

    const observations = InaturalistModal.getCurrentObservations(props).filter(
      f => f.id === id
    );

    if (observations.length === 1 && !observations[0]._actionMapSelected) {
      props.selectInaturalistObservation(observations[0].id);
    } else if (!props.match.params.observation) {
      props.selectInaturalistObservation(null);
    }
  }

  componentDidMount() {
    InaturalistModal.selectCurrentObservation(this.props);
    this.props.setFilter(
      this.props.inaturalist.filter,
      this.props.timeline.bounds
    );
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.match.params.observation !==
        prevProps.match.params.observation ||
      (this.props.inaturalist.points &&
      this.props.inaturalist.points.features[0]
        ? !this.props.inaturalist.points.features[0].properties.hasOwnProperty(
            "_actionMapSelected"
          )
        : false)
    ) {
      InaturalistModal.selectCurrentObservation(this.props);
    }

    const observations = InaturalistModal.getCurrentObservations(this.props);
    const countedSpecies = groupBy(observations, "display_species_name");

    if (Object.keys(countedSpecies).length === 1) {
      if (observations[0].iconic_taxon_name === this.props.inaturalist.filter) {
        this.props.setFilter(
          observations[0].taxon_name,
          this.props.timeline.bounds
        );
      }
    }
  }

  getCurrentObservationPhotos(props) {
    if (!(props.inaturalist && props.inaturalist.photos)) return [];
    const id = parseInt(props.match.params.observation, 10);
    return props.inaturalist.photos
      .filter(photo => photo.observation_id === id)
      .map(photo => {
        const fullUrl = photo.url.replace("square", "original");
        return Object.assign({}, { ...photo }, { fullUrl });
      });
  }

  getTime(observation) {
    return moment.tz(observation.time, "America/Denver");
  }

  render() {
    const observations = InaturalistModal.getCurrentObservations(this.props);
    const id = parseInt(this.props.match.params.observation, 10);

    const observation =
      observations.filter(f => f.id === id).length === 1
        ? observations.filter(f => f.id === id)[0]
        : null;
    const photos = this.getCurrentObservationPhotos(this.props);

    const countedTaxa = groupBy(observations, "iconic_taxon_name");
    const countedSpecies = groupBy(observations, "display_species_name");

    return (
      <div className="InaturalistModal">
        <div className="title">iNaturalist observations</div>
        <div className="description">
          <p>
            Many researchers and hobbyists on MPG Ranch use{" "}
            <a href="https://www.inaturalist.org/home">iNaturalist</a> to track
            observed organisms, record photos, and help with identification.
            This data provides a unique opportunity to see what people on the
            ranch are seeing and noticing throughout the year.{" "}
            {observation
              ? ""
              : "Observations are organized by organism below. To see the full details for an individual observation, click through to the species level."}
          </p>
        </div>
        {observation ? (
          <div className="observation">
            <div className="title">
              Selected observation: {observation.display_species_name} (
              {observation.taxon_name})
            </div>
            <div className="meta">
              <a
                href={`https://www.inaturalist.org/observations/${
                  observation.id
                }`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Observed by {observation.user_name} at{" "}
                {this.getTime(observation).format("h:mm a on MMMM Do YYYY")}
              </a>
            </div>
            <div className="description">{observation.description}</div>
            <ul className="inaturalist-photo-list">
              {photos.map(photo => (
                <li key={photo.id}>
                  <img
                    alt={observation ? observation.preferred_common_name : null}
                    src={photo.fullUrl}
                  />
                </li>
              ))}
            </ul>
          </div>
        ) : null}
        {this.props.match.params.observation ? (
          <div className="opt" onClick={() => this.props.goToObservation("")}>
            Back to all observations
          </div>
        ) : null}

        <SpeciesDrilldown
          categories={Object.keys(countedTaxa).map(t => {
            const d = countedTaxa[t][0];
            const urls = countedTaxa[t].map(t => t.url).filter(u => u);
            return {
              location: d.id,
              category: t,
              category_display: t,
              count: countedTaxa[t].length,
              title: t,
              photo: urls[0] ? urls[0].replace("square", "medium") : null,
              description: d.iconic_description
            };
          })}
          species={
            // If there is just one species here, we display it as individual observations
            // instead of per species
            Object.keys(countedSpecies).length === 1
              ? observations.map(o => ({
                  location: null,
                  category: o.taxon_name,
                  category_display: o.display_species_name,
                  species: o.id,
                  observation: o.id,
                  count: 1,
                  title: o.display_species_name,
                  photo: o.url ? o.url.replace("square", "medium") : null,
                  description: o.description
                    ? o.description
                    : "There is no description for this specific observation. " +
                      o.wikipedia_summary
                }))
              : Object.keys(countedSpecies).map(s => {
                  const d = countedSpecies[s][0];
                  const urls = countedSpecies[s].map(t => t.url).filter(u => u);

                  return {
                    location: null,
                    category: d.iconic_taxon_name,
                    category_display: d.iconic_taxon_name,
                    species: d.taxon_name,
                    count: countedSpecies[s].length,
                    title: d.display_species_name,
                    photo: urls[0] ? urls[0].replace("square", "medium") : null,
                    description: d.wikipedia_summary
                  };
                })
          }
          setFilter={filter =>
            this.props.setFilter(
              filter,
              this.props.timeline.bounds,
              this.props.match.params.observation
            )
          }
          filter={this.props.inaturalist.filter}
          location={null}
          siteTerminology={"observation"}
          goTo={null}
          unitTerminology={" observed"}
          observation={parseInt(this.props.match.params.observation, 10)}
        />
      </div>
    );
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(InaturalistModal)
);
