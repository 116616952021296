import { categorical, gradientScaleOne } from "./colors";
import * as d3 from "d3";

export const modalSizes = {
  aerial: "hidden",
  ndvi: "hidden",
  solar: "hidden",
  elevation: "hidden",
  birds: "medium",
  inaturalist: "medium",
  restoration: "large",
  livecams: {
    site: "large",
    nosite: "large"
  },
  soil: {
    site: "large",
    nosite: "hidden"
  },
  trailcams: "extralarge",
  timelapse: {
    site: "large",
    nosite: "hidden"
  },
  raptors: "medium",
  videos: {
    nosite: "large",
    site: "fullscreen"
  },
  weather: {
    site: "large",
    nosite: "hidden"
  },
  biocrust: "medium",
  bees: "medium",
  flora: "medium",
  biocrustPoints: "medium"
};

export const pointColors = {
  flora: "#6464c8",
  eBird: categorical[2],
  biocrustPoints: categorical[4],
  biocrust: gradientScaleOne,
  bees: "#6c3bf0",
  soil: categorical[0],
  livecam: categorical[7],
  video: categorical[2],
  restoration: categorical[3],
  trailcam: d3
    .scaleLinear()
    .domain([0.0, 0.5, 1.0])
    .range(gradientScaleOne),

  inaturalist: {
    Plantae: categorical[0],
    Aves: categorical[1],
    Mammalia: categorical[2],
    Insecta: categorical[3],
    Reptilia: categorical[4],
    Other: "black"
  },

  raptors: {
    "Accipiter cooperii": categorical[0],
    "Aquila chrysaetos": categorical[1],
    "Buteo jamaicensis": categorical[2],
    "Falco peregrinus": categorical[3],
    "Haliaeetus leucocephalus": categorical[4],
    "Pandion haliaetus": categorical[5]
  }
};
