import React, { Component } from "react";
import { connect } from "react-redux";
import ValueFilter from "../SpeciesList/ValueFilter";
import { withRouter } from "react-router";
import * as actions from "../../constants/action_names.js";
import { unique } from "../../constants/app";
import push from "../../constants/push.js";
import "./BiocrustPointsModal.scss";
import SpeciesDrilldown from "../SpeciesList/SpeciesDrilldown";
import { selectBiocrust } from "../../actions/biocrust";

const SQ_M_PER_POINT = 200 * 0.09 * 0.01;

const mapStateToProps = state => ({
  biocrustPoints: state.biocrustPoints
});

const mapDispatchToProps = dispatch => ({
  selectBiocrustLocation: location => dispatch(selectBiocrust(location)),
  setFilter: filter => {
    dispatch({
      type: actions.BIOCRUST_POINTS_SET_FILTER,
      filter: filter || "total",
      pushToUrl: { biocrustpointsfilter: filter }
    });
  },
  goBack: () => dispatch(push("/biocrustPoints"))
});

class BiocrustPointsModal extends Component {
  componentDidUpdate(prevProps) {
    if (
      (this.props.biocrustPoints.points &&
        JSON.stringify(this.props.biocrustPoints.points) !==
          JSON.stringify(prevProps.biocrustPoints.points)) ||
      this.props.match.params.plot_id !== prevProps.match.params.plot_id
    ) {
      this.props.selectBiocrustLocation(this.props.match.params.plot_id);
    }
  }

  render() {
    // below contains code copied and pasted from BiocrustPointsLayer.js
    // would be better as a static function
    let filter = this.props.biocrustPoints.filter;

    if (filter === "lichen" || filter === "moss") {
      filter = `total_${filter}`;
    }
    if (filter === "") filter = "total";

    let data = this.props.biocrustPoints.species || [];
    data = data.sort((a, b) => b.total - a.total);

    const lifeforms_raw = unique(data, "lifeform");
    const lifeforms = ["total", ...lifeforms_raw];

    if (this.props.biocrustPoints.filter) {
      if (lifeforms_raw.indexOf(this.props.biocrustPoints.filter) >= 0) {
        data = data.filter(
          s => s.lifeform === this.props.biocrustPoints.filter
        );
      }
    }

    let totals = data.reduce(
      (acc, cur) => ({
        ...acc,
        [cur.genus]: {
          ...cur,
          photo: cur.default_photo_genus
            ? cur.default_photo_genus.replace("square", "medium")
            : acc[cur.genus] && acc[cur.genus].photo
            ? acc[cur.genus].photo
            : cur.photo !== ""
            ? cur.photo
            : cur.default_photo && cur.default_photo !== ""
            ? cur.default_photo.replace("square", "medium")
            : null,
          total: (acc[cur.genus] ? acc[cur.genus].total : 0) + cur.total,
          description:
            cur.wikipedia_summary_genus === "false"
              ? null
              : cur.wikipedia_summary_genus
        }
      }),
      {}
    );

    let categories = Object.keys(totals)
      .reduce((acc, cur) => [...acc, { ...totals[cur], genus: cur }], [])
      .sort((a, b) => b.total - a.total);

    // generate location data from GeoJSON, involves pivoting the table
    if (this.props.match.params.plot_id && this.props.biocrustPoints.points) {
      let activePoint = this.props.biocrustPoints.points.features.filter(
        f =>
          f.properties.plot_id.toLowerCase() === this.props.match.params.plot_id
      );

      if (activePoint.length > 0) {
        activePoint = activePoint[0];

        data = Object.keys(activePoint.properties).reduce((acc, cur) => {
          let sp = this.props.biocrustPoints.species.filter(
            s => s.code.toLowerCase() === cur
          );
          if (sp.length > 0) {
            sp = sp[0];
            return [
              ...acc,
              {
                ...sp,
                total: activePoint.properties[cur],
                location: this.props.match.params.plot_id
              }
            ];
          } else {
            return acc;
          }
        }, []);

        data = data.filter(d => d.total > 0);
      }
    }

    return (
      <div className="BiocrustPointsModal">
        <div className="title">Biocrust cover</div>
        <div className="description">
          <p>
            An area of intense study at MPG Ranch is the soil's "biocrust."
            Biocrust refers to the lichens and mosses that inhabit the top layer
            of undisturbed, arid soils. They are an under-researched part of the
            ecosystem that is essential for retaining water in the soil and
            helping plants grow.
          </p>
          <p>
            Like the flora, biocrust has been inventoried and measured across
            MPG Ranch. Click on the map or filter by genus and species to see
            what grows where.
          </p>
        </div>

        <ValueFilter
          values={lifeforms}
          filter={this.props.biocrustPoints.filter}
          setFilter={this.props.setFilter.bind(this)}
        />
        <SpeciesDrilldown
          categories={categories.map(d => ({
            count: d.total * SQ_M_PER_POINT,
            title: d.genus,
            category: d.genus,
            category_display: d.genus,
            photo: d.photo,
            description: d.description
          }))}
          species={data.map(d => ({
            ...d,
            location: d.location,
            category: d.genus,
            category_display: d.genus,
            species: d.genus + (d.species ? " " + d.species : ""),
            count: d.total * SQ_M_PER_POINT,
            title: d.genus + (d.species ? " " + d.species : ""),
            code: d.genus + (d.species ? " " + d.species : ""),
            description: d.wikipedia_summary,
            photo: d.photo
              ? d.photo
              : d.default_photo
              ? d.default_photo.replace("square", "medium")
              : null
          }))}
          setFilter={filter => this.props.setFilter(filter)}
          location={this.props.match.params.plot_id}
          filter={this.props.biocrustPoints.filter}
          siteTerminology={"measurement site"}
          goTo={this.props.goBack.bind(this)}
          valueFilter={
            this.props.biocrustPoints.filter === "total" ||
            this.props.biocrustPoints.filter === "moss" ||
            this.props.biocrustPoints.filter === "lichen"
          }
          unitTerminology={" square meters covered"}
        />
      </div>
    );
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BiocrustPointsModal)
);
