export const categories = [
  {
    name: "Environment",
    icon: "environment.svg",
    layers: ["weather", "aerial", "soil", "solar", "elevation"]
  },
  {
    name: "Restoration",
    icon: "restoration.svg",
    layers: ["restoration"]
  },
  {
    name: "Media",
    icon: "media.svg",
    layers: ["livecams", "trailcams", "timelapse", "videos"]
  },
  {
    name: "Observations",
    icon: "field-guide.svg",
    layers: [
      "flora",
      "biocrustPoints",
      "bees",
      "birds",
      "inaturalist",
      "raptors"
    ]
  },
  {
    name: "Analysis",
    icon: "networking.png",
    layers: ["ndvi", "biocrust"]
  }
];
