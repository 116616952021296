import * as d3 from "d3";
import { withLeaflet } from "react-leaflet";
import { connect } from "react-redux";
import push from "../../constants/push.js";
import { pointColors } from "../../constants/layers_static";
import makeComponent from "../CritterMap/makeComponent.js";
import SvgLayer from "../CritterMap/SvgLayer";
import { LatLng } from "leaflet";
import "./FloraLayer.css";

class FloraLayer extends SvgLayer {
  constructor(opts) {
    super(opts);

    this.birdScale = d3.scaleOrdinal([0, 5, 10, 15, 20, 25, 30, 35]);
    this.colorScale = d3.scaleOrdinal(d3.schemeCategory10);
    this.sizeScale = d3
      .scaleSqrt()
      .domain([0, 10])
      .range([0, 10]);
  }

  updateData(data, props) {
    super.updateData(data);

    let map = this.map;

    function projectPoint(x, y) {
      var point = map.latLngToLayerPoint(new LatLng(y, x));
      this.stream.point(point.x, point.y);
    }

    let transform = d3.geoTransform({ point: projectPoint });
    let path = d3.geoPath().projection(transform);

    let d = this.g
      .selectAll("path")
      .data(this.data.features, d => d.properties.n);

    let colorScale = v =>
      d3.interpolateRgb("rgba(0,0,0,0)", pointColors.flora)(
        Math.max(Math.min(1, v || 0), 0)
      );

    let filter = "native";

    if (props.flora) {
      filter = props.flora.filter || filter;
    }

    let max = 0;
    if (this.data.features) {
      max = d3.max(
        this.data.features.map(d => d.properties[filter.toLowerCase()])
      );
    }

    d.enter()
      .append("path")
      .merge(d)
      .attr(
        "class",
        d => "flora" + (d.properties.n === +props.n ? " active" : "")
      )
      .attr("d", path)
      .attr("fill", d => {
        return colorScale(d.properties[filter.toLowerCase()] / max);
      })
      .attr("stroke", "black")
      .style("pointer-events", d =>
        d.properties[filter.toLowerCase()] / max > 0.05 ? "auto" : "none"
      )
      .on("click", d => this.onClick(d.properties.n));

    d.exit().remove();
  }
}

const mapStateToProps = state => ({
  flora: state.flora,
  timeline: state.timeline
});

const mapDispatchToProps = dispatch => ({
  go: n => dispatch(push("/flora/" + n))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLeaflet(makeComponent(FloraLayer)));
