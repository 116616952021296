import { MapLayer, withLeaflet } from "react-leaflet";
import LeafletGeotiff from "leaflet-geotiff/leaflet-geotiff-plotty";
import plotty from "plotty";
import { pointColors } from "../../constants/layers_static";

plotty.addColorScale("mycolorscale", pointColors.biocrust, [0, 0.5, 1]);

class GeoTiffLayer extends MapLayer {
  createLeafletElement(props) {
    return new LeafletGeotiff(props.url, {
      renderer: LeafletGeotiff.plotty({
        clampLow: false,
        displayMin: props.displayMin,
        displayMax: props.displayMax,
        colorScale: "mycolorscale"
      })
    });
  }

  updateLeafletElement(fromProps, props) {
    if (fromProps.url !== props.url) {
      this.leafletElement.removeFrom(props.leaflet.map);

      this.leafletElement = this.createLeafletElement(props).addTo(
        props.leaflet.map
      );
    }

    this.leafletElement._image.style.opacity = props.opacity;
    this.leafletElement.setZIndex(props.zIndex);
  }
}

export default withLeaflet(GeoTiffLayer);
