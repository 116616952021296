export const ROLE_CONTROL_PROTECTED_LIVECAM = "control protected livecam";
export const ROLE_DISABLE_BUCKEYECAM_PHOTO = "disable buckeyecam photo";
export const ROLE_VIEW_PROTECTED_BUCKEYECAM = "view protected buckeyecam";
export const ROLE_VIEW_PROTECTED_LIVECAM = "view protected livecam";

export const hasRole = (auth, role) => {
  if (!auth.match) return false;
  const { roles } = auth;
  return roles.indexOf("all") >= 0 || roles.indexOf(role) >= 0;
};
