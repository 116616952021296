import * as actions from "../constants/action_names.js";
import queryString from "query-string";
import * as config from "../constants/config.js";

const query = queryString.parse(window.location.href.split("?")[1] || "");

const initialState = {
  center: query.center ? query.center.map(d => +d) : config.mpgCenter,
  zoom: query.zoom ? query.zoom : config.mpgZoom,
  property: query.property ? query.property : "mpg"
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.BOUNDARY_RESPONSE:
      return {
        ...state,
        boundary: action.json
      };
    case actions.BOUNDARY_ERROR:
      return {
        ...state,
        boundary: action.error
      };
    case actions.SET_ZOOM:
      return {
        ...state,
        zoom: action.zoom
      };
    case actions.SET_CENTER:
      return {
        ...state,
        center: action.center
      };
    case actions.SET_PROPERTY:
      return {
        ...state,
        property: action.property
      };
    case actions.SET_MODAL_REF:
      return {
        ...state,
        modalRef: action.ref
      };
    case actions.SET_MODAL_SIZE:
      return {
        ...state,
        modalSize: action.size
      };
    case actions.CLEAR_MODAL_SIZE:
      return {
        ...state,
        modalSize: null
      };
    default:
      return state;
  }
};
