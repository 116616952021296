import React, { Component } from "react";
import { pointColors } from "../../constants/layers_static";

class RaptorLegend extends Component {
  render() {
    return (
      <div className="PointLegend InatLegend">
        <div className="label">Logged raptor positions</div>
        <div className="scale">
          {Object.keys(pointColors.raptors).map(t => (
            <div className="color" key={t + "_legend_label"}>
              <svg width="25" height="25">
                <circle
                  cx="12.5"
                  cy="12.5"
                  r="10"
                  fill={pointColors.raptors[t]}
                  opacity={0.8}
                />
              </svg>
              <div className="label">{t}</div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default RaptorLegend;
