import * as config from "../../constants/config.js";

const imageryUrls = {
  2015: config.customAerial2015,
  2016: config.customAerial2016,
  2017: config.customAerial2017,
  2018: config.customAerial2018,
  2020: config.customAerial2020,
  2021: config.customAerial2021
};

export const imageryExists = year =>
  Object.keys(imageryUrls).includes(year.toString());

export const getImageryYear = year => (imageryExists(year) ? year : 2018);

export const getImageryUrl = year => imageryUrls[getImageryYear(year)];
