import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Map, TileLayer, GeoJSON } from "react-leaflet";
import { withRouter } from "react-router";
import classNames from "classnames";
import { setTimeline } from "../../actions/timeline_actions.js";
import {
  setCenter,
  setZoom,
  setProperty
} from "../../actions/critterMap_actions.js";
import { layers } from "../../constants/layers.js";
import * as config from "../../constants/config.js";
import "./L.TileLayer.NoGap";
import "./CritterMap.scss";

const mapStateToProps = state => ({
  ...state,
  timeline: state.timeline,
  layerSelector: state.layerSelector,
  critterMap: state.critterMap
});

const mapDispatchToProps = dispatch => ({
  setTimeline: bounds => dispatch(setTimeline(bounds)),
  setZoom: zoom => dispatch(setZoom(zoom)),
  setCenter: center => dispatch(setCenter(center)),
  setProperty: prop => dispatch(setProperty(prop))
});

class CritterMap extends Component {
  state = {
    mobileLegendVisible: false
  };

  zoomEnd(e) {
    this.props.setZoom(e.target.getZoom());
  }

  moveEnd(e) {
    const center = e.target.getCenter();
    this.props.setCenter([center.lat, center.lng]);
  }

  toggleProperties() {
    if (this.props.critterMap.property === "mpg") {
      this.props.setProperty("north");
      this.props.setCenter(config.northCenter);
      this.props.setZoom(config.northZoom);
    } else {
      this.props.setProperty("mpg");
      this.props.setCenter(config.mpgCenter);
      this.props.setZoom(config.mpgZoom);
    }
  }

  componentDidUpdate() {
    let map = this.map.leafletElement;
    window.setTimeout(() => {
      map.invalidateSize(false);
    }, 310);
  }

  render() {
    const { mobileLegendVisible } = this.state;

    const mapLayers = this.props.layerSelector.active.map((l, i) => {
      if (layers[l].map.component) {
        return React.cloneElement(
          layers[l].map.component({ ...this.props, zIndex: i }),
          {
            style: { zIndex: i },
            zIndex: i
          }
        );
      } else {
        return [];
      }
    });

    const center = this.props.critterMap
      ? this.props.critterMap.center
      : [46.7064965, -114.0184147];
    const zoom = this.props.critterMap ? this.props.critterMap.zoom : 13;

    let boundary = this.props.critterMap.boundary;
    let boundaryKey = "loading";
    const endTimeBounds = moment(this.props.timeline.bounds[1]);
    if (boundary) {
      boundary = Object.assign({}, boundary, {
        features: boundary.features.filter(feature => {
          const start = moment(feature.properties.valid_start);
          const end = feature.properties.valid_end
            ? moment(feature.properties.valid_end)
            : null;
          return start <= endTimeBounds && (!end || end > endTimeBounds);
        })
      });
      boundaryKey = `loaded_${boundary.features
        .map(f => f.properties.cartodb_id)
        .join("_")}`;
    }

    let mapLegends = this.props.layerSelector.active.map((l, i) => {
      if (layers[l].legend) {
        return React.cloneElement(layers[l].legend.component(this.props), {
          key: "legend_" + l
        });
      }
      return null;
    });

    mapLegends.reverse();

    return (
      <div className="CritterMap">
        <div
          className={classNames("legend-container", {
            "mobile-visible": mobileLegendVisible
          })}
        >
          {mapLegends}
        </div>
        <div
          className="legend-button opt"
          onClick={() =>
            this.setState({
              mobileLegendVisible: !mobileLegendVisible
            })
          }
        >
          {mobileLegendVisible ? "Hide" : "View"} legend
        </div>
        <div className="map-container">
          <Map
            center={center}
            zoom={zoom}
            onZoomEnd={this.zoomEnd.bind(this)}
            onMoveEnd={this.moveEnd.bind(this)}
            maxZoom={20}
            ref={n => (this.map = n)}
            width={window.innerWidth}
            preferCanvas={true}
          >
            {this.props.critterMap.property === "mpg" ? (
              <TileLayer
                key="mpg-terrain"
                url={config.mapStackTerrain}
                maxNativeZoom={18}
                maxZoom={22}
                preferCanvas={false}
                className="stamen-terrain"
                attribution='&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://stamen.com/" target="_blank">Stamen Design</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/about/" target="_blank">OpenStreetMap contributors</a>'
              />
            ) : null}
            {this.props.critterMap.property === "north" ? (
              <React.Fragment>
                <TileLayer
                  key="north-terrain"
                  url={config.mapStackTerrain}
                  maxNativeZoom={13}
                  maxZoom={17}
                  className="stamen-terrain-light"
                  attribution='&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://stamen.com/" target="_blank">Stamen Design</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/about/" target="_blank">OpenStreetMap contributors</a>'
                />
                <TileLayer
                  key="north-fstopo"
                  url={config.fstopo}
                  className="fstopo"
                  maxNativeZoom={16}
                  maxZoom={17}
                  minZoom={12}
                  detectRetina={false}
                  tms={true}
                />
              </React.Fragment>
            ) : null}
            <GeoJSON
              key={`${boundaryKey}_casing`}
              data={boundary}
              style={{
                fillColor: "none",
                color: "black",
                weight: 4,
                fill: false
              }}
            />
            <GeoJSON
              key={boundaryKey}
              data={boundary}
              style={{
                fillColor: "none",
                color: "#f1f4de",
                weight: 3,
                fill: false
              }}
            />
            {mapLayers}
          </Map>
        </div>

        <div className="map-inset" onClick={this.toggleProperties.bind(this)}>
          <Map
            center={
              this.props.critterMap.property === "mpg"
                ? config.northInsetCenter
                : config.mpgInsetCenter
            }
            zoom={
              this.props.critterMap.property === "mpg"
                ? config.northZoom
                : config.mpgZoom
            }
            dragging={false}
            zoomControl={false}
            boxZoom={false}
            touchZoom={false}
            scrollWheelZoom={false}
            preferCanvas={true}
          >
            {this.props.critterMap.property === "north" ? (
              <TileLayer
                key="mpg_inset"
                url={config.mapStackTerrain}
                className="stamen-terrain"
              />
            ) : (
              <TileLayer
                key="north_inset_fstopo"
                url={config.fstopo}
                tms={true}
              />
            )}
            <GeoJSON
              key={`${boundaryKey}_casing`}
              data={boundary}
              style={{
                fillColor: "none",
                fill: false,
                color: "black",
                weight: 8
              }}
            />
            <GeoJSON
              key={boundaryKey}
              data={boundary}
              style={{
                fillColor: "none",
                fill: false,
                color: "#f1f4de",
                weight: 4
              }}
            />
            {mapLayers}
          </Map>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CritterMap)
);
