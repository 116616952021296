import React, { Component } from "react";
import "./Legend.scss";

export default class GradientLegend extends Component {
  render() {
    let unit = this.props.unit || "";
    let min = this.props.min || "0";
    return (
      <div className="PointLegend">
        <div className="label">{this.props.label}</div>
        <div className="scale">
          <div className="tick left">{min + unit}</div>
          <div
            className="scalecolor"
            style={{
              backgroundImage: this.props.scale
            }}
          />
          <div className="tick">{this.props.max + unit}</div>
        </div>
      </div>
    );
  }
}
