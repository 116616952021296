import * as actions from "../../constants/action_names";
import * as config from "../../constants/config";
import { fetchSql } from "../../constants/carto";

export const fetchRestorationDetail = bounds => {
  const sql = `SELECT COUNT(r.cartodb_id) AS count, report_mu, mu.the_geom, report_mu AS id
        FROM (
          SELECT cartodb_id, report_mu FROM ${config.restoration_table}
          WHERE report_date > '${bounds[0].toISOString()}' AND report_date < '${bounds[1].toISOString()}'
          AND published = true
          UNION ALL
          SELECT cartodb_id, management_unit AS report_mu FROM ${
            config.restoration_action_responses
          }
          WHERE timestamp::timestamp > '${bounds[0].toISOString()}' AND timestamp::timestamp < '${bounds[1].toISOString()}'
          UNION ALL
          SELECT cartodb_id, management_unit AS report_mu FROM ${
            config.restoration_photo_responses
          }
          WHERE timestamp::timestamp > '${bounds[0].toISOString()}' AND timestamp::timestamp < '${bounds[1].toISOString()}'
        ) r
        LEFT JOIN ${config.management_unit_table} mu ON mu.name = r.report_mu
        WHERE mu.the_geom IS NOT NULL
        GROUP BY report_mu, mu.the_geom
        ORDER BY ST_Area(mu.the_geom) DESC`;

  return dispatch => {
    dispatch({ type: actions.RESTORATION_POINTS_REQUEST });

    return fetchSql(sql, "geojson")
      .then(geojson => {
        dispatch({
          type: actions.RESTORATION_POINTS_RESPONSE,
          geojson
        });
      })
      .catch(error =>
        dispatch({
          type: actions.RESTORATION_POINTS_ERROR_RESPONSE,
          error: error.message
        })
      );
  };
};

export const fetchRestorationOverview = () => {
  const sql = `SELECT date_trunc('week', report_date) AS week, COUNT(*) AS count
      FROM ${config.restoration_table}
      WHERE published = true
      GROUP BY week`;

  return dispatch => {
    dispatch({ type: actions.RESTORATION_OVERVIEW_REQUEST });

    return fetchSql(sql, "json")
      .then(json => {
        json.rows = json.rows.map(row => ({
          ...row,
          date: new Date(row.week)
        }));
        dispatch({
          type: actions.RESTORATION_OVERVIEW_RESPONSE,
          json
        });
      })
      .catch(error =>
        dispatch({
          type: actions.RESTORATION_OVERVIEW_ERROR_RESPONSE,
          error: error.message
        })
      );
  };
};

export const fetchRestorationReports = (bounds, mu) => {
  const sql = `SELECT report_name, report_authors, report_mu, report_date, last_updated, report_type, report_tags, report_id, report_data, '' AS img_token, '' AS img_caption
        FROM ${config.restoration_table}
        WHERE report_date > '${bounds[0].toISOString()}' AND report_date < '${bounds[1].toISOString()}'
        AND published = true
        ${
          mu !== "all"
            ? `AND regexp_replace(regexp_replace(lower(report_mu),  E'[^\\\\w\\\\s-]', '', 'gi'), E'[-\\\\s]+', '-', 'gi') = '${mu}'`
            : ""
        }
      UNION ALL
        SELECT title AS report_name, '' AS report_authors, management_unit AS report_mu, timestamp::timestamp AS report_date, timestamp::timestamp AS last_updated, 'update' AS report_type, '' AS report_tags, cartodb_id::varchar AS report_id, text AS report_data, egnyte_photo_public_token_1, photo_caption_1
        FROM ${config.restoration_action_responses}
        WHERE timestamp::timestamp > '${bounds[0].toISOString()}' AND timestamp::timestamp < '${bounds[1].toISOString()}'
        ${
          mu !== "all"
            ? `AND regexp_replace(regexp_replace(lower(management_unit),  E'[^\\\\w\\\\s-]', '', 'gi'), E'[-\\\\s]+', '-', 'gi') = '${mu}'`
            : ""
        }
      UNION ALL
        SELECT '' AS report_name, '' AS report_authors, management_unit AS report_mu, timestamp::timestamp AS report_date, timestamp::timestamp AS last_updated, 'photo-update' AS report_type, '' AS report_tags, cartodb_id::varchar AS report_id, photo_description AS report_data, egnyte_photo_public_token, '' AS photo_caption_1
        FROM ${config.restoration_photo_responses}
        WHERE timestamp::timestamp > '${bounds[0].toISOString()}' AND timestamp::timestamp < '${bounds[1].toISOString()}'
        ${
          mu !== "all"
            ? `AND regexp_replace(regexp_replace(lower(management_unit),  E'[^\\\\w\\\\s-]', '', 'gi'), E'[-\\\\s]+', '-', 'gi') = '${mu}'`
            : ""
        }`;

  return dispatch => {
    return fetchSql(sql, "json")
      .then(json => {
        dispatch({
          type: actions.RESTORATION_REPORTS_RESPONSE,
          json
        });
      })
      .catch(error =>
        dispatch({
          type: actions.RESTORATION_REPORTS_ERROR,
          json: error.message
        })
      );
  };
};
