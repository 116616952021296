import { combineReducers } from "redux";
import authReducer from "./authReducer";
import biocrustReducer from "./biocrustReducer";
import biocrustPointsReducer from "./biocrustPointsReducer";
import eBirdReducer from "./eBirdReducer";
import timelineReducer from "./timelineReducer";
import trailcamReducer from "./trailcamReducer";
import layerReducer from "./layerReducer";
import livecamReducer from "./livecamReducer";
import mobileReducer from "./mobileReducer";
import { connectRouter } from "connected-react-router";
import raptorReducer from "./raptorReducer";
import restorationReducer from "./restorationReducer";
import critterMapReducer from "./critterMapReducer";
import timelapseReducer from "./timelapseReducer";
import soilReducer from "./soilReducer";
import videoReducer from "./videoReducer";
import weatherReducer from "./weatherReducer";
import floraReducer from "./floraReducer";
import beeReducer from "./beeReducer";
import inaturalistReducer from "./inaturalistReducer";

export default history =>
  combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    biocrust: biocrustReducer,
    biocrustPoints: biocrustPointsReducer,
    eBird: eBirdReducer,
    mobile: mobileReducer,
    timeline: timelineReducer,
    trailcams: trailcamReducer,
    livecam: livecamReducer,
    raptors: raptorReducer,
    restoration: restorationReducer,
    timelapse: timelapseReducer,
    soil: soilReducer,
    video: videoReducer,
    weather: weatherReducer,
    layerSelector: layerReducer,
    critterMap: critterMapReducer,
    flora: floraReducer,
    bees: beeReducer,
    inaturalist: inaturalistReducer
  });
