import * as d3 from "d3";
import React, { Component } from "react";
import moment from "moment";
import suncalc from "suncalc";
import { pointColors } from "../../constants/layers_static";

export default class GraphDateTime extends Component {
  shouldComponentUpdate(newProps, newState) {
    if (
      newProps.data.length !== this.props.data.length ||
      newProps.start.valueOf() !== this.props.start.valueOf() ||
      this.props.width !== newProps.width
    ) {
      return true;
    }

    return false;
  }

  // Build the SVG path string for sunrise/sunset at MPG Ranch
  makeSunriseSunsetPath(begin, end, xScale, yScale) {
    if (!begin) return;

    const sunrise = m =>
      moment(suncalc.getTimes(new Date(m), 46.8721, -113.994).sunrise);

    const sunset = m =>
      moment(suncalc.getTimes(new Date(m), 46.8721, -113.994).sunset);

    let extendedEnd = end.add(20, "days");
    let extendedBegin = begin.subtract(0, "days");
    let sr, h, ss;

    sr = sunrise(extendedBegin);
    h = sr.hour() + sr.minute() / 60 + sr.second() / 3600;

    let path =
      "M " +
      (xScale(
        extendedBegin
          .hours(0)
          .minutes(0)
          .seconds(0)
      ) || 0) +
      " " +
      yScale(h) +
      " ";

    let d;

    for (d = moment(extendedBegin); d <= extendedEnd; d.add(1, "days")) {
      sr = sunrise(d);
      h = sr.hour() + sr.minute() / 60 + sr.second() / 3600;

      path +=
        "L " +
        (xScale(
          d
            .hours(0)
            .minutes(0)
            .seconds(0)
        ) || 0) +
        " " +
        yScale(h) +
        " ";
    }

    for (d = moment(d); d >= extendedBegin; d = d.add(-1, "days")) {
      ss = sunset(d);
      h = ss.hour() + ss.minute() / 60 + ss.second() / 3600;

      path +=
        "L " +
        (xScale(
          d
            .hours(0)
            .minutes(0)
            .seconds(0)
        ) || 0) +
        " " +
        yScale(h) +
        " ";
    }

    sr = sunrise(extendedBegin);
    h = sr.hour() + sr.minute() / 60 + sr.second() / 3600;

    path += "M " + (xScale(extendedBegin) || 0) + " " + yScale(h) + " ";
    return path;
  }

  render() {
    var data = d3
      .nest()
      .key(function(d) {
        return moment(d.date).format("YYYY-MM-DD HH");
      })
      .rollup(v => v.length)
      .entries(this.props.data);

    data = data.map(d => ({ ...d, key: moment(d.key, "YYYY-MM-DD HH") }));

    var begin = moment(d3.min(data.map(d => d.key))).hours(0);
    var end = moment(d3.max(data.map(d => d.key))).hours(0);

    var marginLeft = 50;
    var marginTop = 25;
    var marginBottom = 20;
    var marginRight = 10;

    var maxVal = d3.max(data.map(d => d.value));

    var yScale = d3
      .scaleLinear()
      .domain([0, 24])
      .range([marginTop, this.props.height - marginBottom]);

    var yTransform = v => yScale((v.hour() + 0) % 24);

    var xScale = d3
      .scaleTime()
      .domain([begin, end])
      .range([marginLeft, this.props.width - marginRight]);

    let xTransform = v => {
      let m = moment(v);
      m = m
        .hour(0)
        .minute(0)
        .second(0);
      return xScale(m);
    };

    var rScale = d3
      .scaleSqrt()
      .domain([0, maxVal])
      .range([0, 10]);

    var colorScale = v => pointColors.trailcam(v / maxVal);

    let circles = data.map(d => {
      return xTransform(d.key) ? (
        <circle
          cx={xTransform(d.key)}
          cy={yTransform(d.key)}
          r={rScale(d.value)}
          style={{ fill: colorScale(d.value) }}
          onClick={() => {
            this.props.onClick(d.key);
          }}
          key={"circle_" + d.key.format()}
          opacity={0.8}
        />
      ) : null;
    });

    let ticks = [];
    let nMonths = (moment(end) - moment(begin)) / (1000 * 60 * 60 * 24 * 30);
    let offset = 1;

    if (nMonths > 24) {
      offset = 6;
    } else if (nMonths > 12) {
      offset = 3;
    } else if (nMonths > 6) {
      offset = 2;
    }

    for (
      let d = moment(begin)
        .hour(0)
        .minute(0)
        .second(0)
        .date(0)
        .add(1, "month");
      d < moment(end);
      d = d.add(offset, "month")
    ) {
      ticks.push(
        ...[
          <line
            className="grid"
            x1={xTransform(d) || 0}
            y1={marginTop}
            y2={this.props.height - marginBottom}
            x2={xTransform(d) || 0}
            key={"tick_line_" + d.format()}
          />,
          <text
            className="date-tick"
            textAnchor="middle"
            x={xTransform(d) || 0}
            y={yScale(0)}
            dy={-10}
            key={"tick-text_" + d.format()}
            style={
              this.props.width > 500
                ? { fontSize: "16px" }
                : { fontSize: "12px" }
            }
          >
            {moment(d).format("MMMM YYYY")}
          </text>
        ]
      );
    }
    return (
      <svg
        ref={n => (this.svg = n)}
        viewBox={`0 0 ${this.props.width || 0} ${this.props.height || 0}`}
      >
        <line
          className="grid minor"
          x1={marginLeft}
          y1={yScale(6)}
          y2={yScale(6)}
          x2={this.props.width}
        />
        <line
          className="grid"
          x1={marginLeft}
          y1={yScale(12)}
          y2={yScale(12)}
          x2={this.props.width}
        />
        <line
          className="grid minor"
          x1={marginLeft}
          y1={yScale(18)}
          y2={yScale(18)}
          x2={this.props.width}
        />
        <text
          textAnchor="beginning"
          x={0}
          y={yScale(6)}
          dy={5}
          style={
            this.props.width > 500 ? { fontSize: "16px" } : { fontSize: "12px" }
          }
        >
          6 am
        </text>
        <text
          textAnchor="beginning"
          x={0}
          y={yScale(18)}
          dy={5}
          style={
            this.props.width > 500 ? { fontSize: "16px" } : { fontSize: "12px" }
          }
        >
          6 pm
        </text>
        <line
          className="vertical grid"
          x1={xTransform(this.props.start) || 0}
          y1={marginTop}
          y2={this.props.height}
          x2={xTransform(this.props.start) || 0}
        />
        <text
          textAnchor={
            xTransform(this.props.start) < this.props.width / 2
              ? "beginning"
              : "end"
          }
          x={
            xTransform(this.props.start) +
              (xTransform(this.props.start) < this.props.width / 2
                ? 10
                : -10) || 0
          }
          y={this.props.height - 4}
          style={
            this.props.width > 500 ? { fontSize: "14px" } : { fontSize: "10px" }
          }
        >
          {`${
            this.props.start.hours() === 23
              ? this.props.start.format("MMMM DD, YYYY")
              : this.props.start.format("ha MMMM DD, YYYY")
          }`}
        </text>
        {ticks}
        <path
          className="daylight"
          d={this.makeSunriseSunsetPath(
            moment(begin),
            moment(end),
            xScale,
            yScale
          )}
        />
        {circles}
      </svg>
    );
  }
}
