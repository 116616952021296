import * as actions from "../../constants/action_names";
import * as config from "../../constants/config";
import { fetchSql } from "../../constants/carto";

export const fetchRaptorOverview = () => {
  const sql = `SELECT SUM(cnt) AS cnt, weekly
    FROM (
        SELECT date_trunc('week', date_time::date) AS weekly, COUNT(pt.id) AS cnt
        FROM ${config.raptor_table} pt
        JOIN ${config.boundary_table} py
        ON ST_DWithin(pt.the_geom, py.the_geom, 0.05)
        WHERE py.cartodb_id = 1
        GROUP BY weekly
    ) AS _
    GROUP BY weekly`;

  return dispatch => {
    dispatch({
      type: actions.RAPTOR_OVERVIEW_REQUEST
    });

    return fetchSql(sql, "json")
      .then(json => {
        json.rows = json.rows.map(r => ({
          ...r,
          weekly: new Date(r.weekly)
        }));
        dispatch({
          type: actions.RAPTOR_OVERVIEW_RESPONSE,
          json
        });
      })
      .catch(error =>
        dispatch({
          type: actions.RAPTOR_OVERVIEW_ERROR_RESPONSE,
          error: error.message
        })
      );
  };
};

export const fetchRaptorDetail = bounds => {
  // This only fetches Ospreys, but those are the only raptors that spend a significant
  // amount of time inside the ranch boundaries anyway.

  const sql_lines = `
    SELECT tracks.id, ST_Segmentize(ST_MakeLine(tracks.the_geom), 0.2) As the_geom, tracks.species
    FROM (
      SELECT pt.id, pt.date_time, pt.timestamp, pt.the_geom,
        CASE
          WHEN pt.name_taxon_canonical = 'Pandion' THEN 'Pandion haliaetus'
          WHEN pt.name_taxon_canonical = 'Aquila' THEN 'Aquila chrysaetos'
          ELSE pt.name_taxon_canonical
        END AS species
      FROM ${config.raptor_table} pt
      WHERE pt.date_time > '${bounds[0].toISOString()}' AND pt.date_time < '${bounds[1].toISOString()}'
      ORDER BY pt.id, pt.timestamp) tracks
    GROUP BY tracks.id, tracks.species`;

  const sql_points = `
      SELECT pt.id, pt.the_geom,
      CASE
        WHEN pt.name_taxon_canonical = 'Pandion' THEN 'Pandion haliaetus'
        WHEN pt.name_taxon_canonical = 'Aquila' THEN 'Aquila chrysaetos'
        ELSE pt.name_taxon_canonical
      END AS species
      FROM ${config.raptor_table} pt
      WHERE pt.date_time > '${bounds[0].toISOString()}' AND pt.date_time < '${bounds[1].toISOString()}'
      ORDER BY pt.timestamp`;

  return dispatch => {
    return Promise.all([
      fetchSql(sql_lines, "geojson"),
      fetchSql(sql_points, "geojson")
    ])
      .then(([lines, points]) => {
        dispatch({
          type: actions.RAPTOR_POINTS_RESPONSE,
          tracks: lines,
          points
        });
      })
      .catch(error =>
        dispatch({
          type: actions.RAPTOR_POINTS_ERROR_RESPONSE,
          error: error.message
        })
      );
  };
};
