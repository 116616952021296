// code credit: https://muffinresearch.co.uk/removing-leading-whitespace-in-es6-template-strings/
// convert multiline es6 template strings to a single line & remove extra whitespace
export function singleLineString(strings, ...values) {
  // Interweave the strings with the
  // substitution vars first.
  let output = "";
  for (let i = 0; i < values.length; i++) {
    output += strings[i] + values[i];
  }
  output += strings[values.length];

  // Split on newlines.
  let lines = output.split(/(?:\r\n|\n|\r)/);

  // Rip out the leading whitespace.
  return encodeURIComponent(
    lines
      .map(line => {
        return line.replace(/^\s+/gm, "");
      })
      .join(" ")
      .trim()
  );
}

export function slugify(s) {
  return s
    .toString()
    .toLowerCase()
    .replace(/[^\w\s-]/gi, "")
    .replace(/[-\s]+/gi, "-");
}

export function trailcamUrl(servername, camera_number, filename) {
  return (
    "http://ranch-buckeye.s3.amazonaws.com/" +
    servername +
    "/Cam" +
    camera_number +
    "/" +
    filename
  );
}

export const trailcamUrlDateString = "YYYY-MM-DD-HH";

export const unique = (objs, k) => {
  if (objs && objs.length > 0) {
    let uniques = {};
    objs.forEach(s => (uniques[s[k]] = true));
    return Object.keys(uniques);
  } else {
    return [];
  }
};
