import * as actions from "../constants/action_names";

export const selectWeatherStation = station => {
  return dispatch => {
    dispatch({
      type: actions.WEATHER_STATION_SELECT,
      station
    });
  };
};
