import React, { Component } from "react";
import { pointColors } from "../../constants/layers_static";
import PointLegend from "../SpeciesList/PointLegend";
import * as d3 from "d3";

class BiocrustPointsLegend extends Component {
  render() {
    const { species, pointdata, filter } = this.props;

    let max = 0;
    let getValueForFilter = () => 0;

    if (pointdata.features && species) {
      let matchedSpecies = species.filter(
        s => s.genus + " " + s.species === filter
      );

      if (matchedSpecies.length !== 1) {
        matchedSpecies = species
          .filter(s => s.genus === filter)
          .map(s => s.code.toLowerCase());
      }

      getValueForFilter = d => {
        if (
          filter === "total_lichen" ||
          filter === "total" ||
          filter === "total_moss"
        ) {
          return d.properties[filter];
        }

        if (matchedSpecies.length === 1 && matchedSpecies[0].code) {
          return d.properties[matchedSpecies[0].code.toLowerCase()];
        } else {
          return matchedSpecies.reduce(
            (acc, cur) => d.properties[cur] + acc,
            0
          );
        }
      };

      max = d3.max(pointdata.features.map(d => getValueForFilter(d))) || 0;
    }
    // above contains code copied and pasted from BiocrustPointsLayer.js
    // would be better as a static function

    return (
      <PointLegend
        max={max.toFixed(1)}
        label="Biocrust cover"
        color={pointColors.biocrustPoints}
        unit={"%"}
      />
    );
  }
}

export default BiocrustPointsLegend;
