import React, { Component } from "react";
import "./FullscreenModal.scss";

export default class FullscreenModal extends Component {
  componentDidMount() {
    document.addEventListener("keydown", this.keydown.bind(this));
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.keydown.bind(this));
  }

  keydown(e) {
    const ESC_KEY = 27;
    if (e.keyCode === ESC_KEY) this.props.onClose();
  }

  render() {
    return (
      <div className="fullscreen-modal">
        <div className="fullscreen-modal-close" onClick={this.props.onClose}>
          &times;
        </div>
        <div className="fullscreen-modal-content">{this.props.children}</div>
      </div>
    );
  }
}
