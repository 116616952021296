import moment from "moment";
import * as actions from "../../constants/action_names";
import * as config from "../../constants/config";
import { fetchSql } from "../../constants/carto";

export const fetchWeatherData = (bounds, stationSlug) => {
  let timeUnit = "hour";
  const duration = moment.duration(moment(bounds[1]).diff(bounds[0]));
  if (duration.years() >= 1) {
    timeUnit = "week";
  } else if (duration.months() >= 1) {
    timeUnit = "day";
  }

  // We subtract the previous record's precipitation from each record's
  // precipitation because the Weather Underground data accumulates
  // precipitation data (each record has the total precipitation for that day,
  // not just the time covered at that record).
  const sql = `
    WITH
      cleaned_values AS (
        SELECT station, 
          date_trunc('${timeUnit}', time_stamp) as time_stamp,
          dewpti::float as dewpti,
          hum::float as hum,
          (CASE
            WHEN s.data_source = 'Weather Underground' 
              THEN greatest(0, precip_totali::float - lag(precip_totali::float, 1) OVER (PARTITION BY station ORDER BY time_stamp)) 
            ELSE precip_totali::float
            END
          ) AS precip_totali,
          pressurei::float as pressurei,
          tempi::float as tempi,
          wspdi::float as wspdi
        FROM ${config.weather_data_table}
        LEFT OUTER JOIN ${config.weather_station_table} s ON s.id = station
        WHERE time_stamp > '${bounds[0].toISOString()}' AND time_stamp < '${bounds[1].toISOString()}'
        AND regexp_replace(regexp_replace(lower(station),  E'[^\\\\w\\\\s-]', '', 'gi'), E'[-\\\\s]+', '-', 'gi') = '${stationSlug}'
      )
    SELECT time_stamp, avg(dewpti) as dewpti, avg(hum) as hum, sum(precip_totali) as precip_totali, avg(pressurei) as pressurei, avg(tempi) as tempi, avg(wspdi) as wspdi
    FROM cleaned_values 
    GROUP BY time_stamp
    ORDER BY time_stamp
  `;

  return dispatch => {
    dispatch({
      type: actions.WEATHER_DATA_REQUEST,
      json: null
    });

    return fetchSql(sql, "json")
      .then(json => {
        json.rows = json.rows.map(row => ({
          ...row,
          date: new Date(row.time_stamp)
        }));
        dispatch({
          type: actions.WEATHER_DATA_RESPONSE,
          json
        });
      })
      .catch(error =>
        dispatch({
          type: actions.WEATHER_DATA_ERROR_RESPONSE,
          error: error.message
        })
      );
  };
};

export const fetchWeatherPoints = bounds => {
  const sql = `
    WITH latest_data AS (
      SELECT station, max(time_stamp) AS time_stamp
      FROM ${config.weather_data_table}
      WHERE time_stamp > '${bounds[0].toISOString()}' 
        AND time_stamp < '${bounds[1].toISOString()}'
      GROUP BY station
    )
    SELECT s.id, s.the_geom, d.tempi, d.wdird, d.wspdi, d.time_stamp
    FROM ${config.weather_station_table} s
    LEFT OUTER JOIN latest_data l ON l.station = s.id
    LEFT OUTER JOIN ${config.weather_data_table} d ON
      d.station = l.station AND d.time_stamp = l.time_stamp
  `;

  return dispatch => {
    dispatch({
      type: actions.WEATHER_POINTS_REQUEST,
      geojson: null
    });

    return fetchSql(sql, "geojson")
      .then(geojson => {
        dispatch({
          type: actions.WEATHER_POINTS_RESPONSE,
          geojson
        });
      })
      .catch(error =>
        dispatch({
          type: actions.WEATHER_POINTS_ERROR_RESPONSE,
          error
        })
      );
  };
};

export const fetchWeatherOverview = () => {
  const sql = `SELECT date_trunc('week', time_stamp) AS week, COUNT(*) AS count
      FROM ${config.weather_data_table}
      GROUP BY week`;

  return dispatch => {
    dispatch({
      type: actions.WEATHER_OVERVIEW_REQUEST,
      json: null
    });

    return fetchSql(sql, "json")
      .then(json => {
        json.rows = json.rows.map(row => ({
          ...row,
          date: new Date(row.week)
        }));
        dispatch({
          type: actions.WEATHER_OVERVIEW_RESPONSE,
          json
        });
      })
      .catch(error =>
        dispatch({
          type: actions.WEATHER_OVERVIEW_ERROR_RESPONSE,
          error: error.message
        })
      );
  };
};
