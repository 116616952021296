import React, { Component } from "react";
import "./Legend.scss";

export default class PointLegend extends Component {
  render() {
    let isColorArray = Array.isArray(this.props.color);

    return (
      <div className="PointLegend">
        <div className="label">{this.props.label}</div>
        <div className="scale">
          <div className="tick">{`${this.props.min || 1}${
            this.props.unit ? this.props.unit : ""
          }`}</div>
          <svg width="50" height="50">
            <circle
              cx="25"
              cy="25"
              r="5"
              fill={isColorArray ? this.props.color[0] : this.props.color}
              opacity={0.8}
            />
          </svg>

          <svg width="50" height="50">
            <circle
              cx="25"
              cy="25"
              r="11.5"
              fill={isColorArray ? this.props.color[1] : this.props.color}
              opacity={0.8}
            />
          </svg>

          <svg width="50" height="50">
            <circle
              cx="25"
              cy="25"
              r="16"
              fill={isColorArray ? this.props.color[2] : this.props.color}
              opacity={0.8}
            />
          </svg>

          <svg width="50" height="50">
            <circle
              cx="25"
              cy="25"
              r="20"
              fill={isColorArray ? this.props.color[3] : this.props.color}
              opacity={0.8}
            />
          </svg>
          <div className="tick">{`${this.props.max}${
            this.props.unit ? this.props.unit : ""
          }`}</div>
        </div>
      </div>
    );
  }
}
