import * as actions from "../../constants/action_names";
import * as config from "../../constants/config";
import { fetchSql, fetchSqlPost } from "../../constants/carto";

const column_list = [
  "n",
  "latitude",
  "longitude",
  "ACEGLA",
  "ALITRI",
  "SAGCUN",
  "KOCSCO",
  "AMAGRO",
  "AMARET",
  "TOXRYD",
  "HERLAN",
  "LOMAMB",
  "LOMCOU",
  "LOMDIS",
  "LOMMAC",
  "LOMTRI",
  "OSMBER",
  "OSMOCC",
  "PERGAI",
  "SIUSUA",
  "ANGARG",
  "APOAND",
  "APOCAN",
  "ARANUD",
  "ASCSPE",
  "ACHMIL",
  "AGOAUR",
  "AGOGLA",
  "ANAMAR",
  "ANTANA",
  "ANTDIM",
  "ANTLUZ",
  "ANTMIC",
  "ANTPAR",
  "ANTRAC",
  "ANTROS",
  "ANTUMB",
  "ARCMIN",
  "ARNCOR",
  "ARNFUL",
  "ARNLAT",
  "ARNSOR",
  "ARTABS",
  "ARTDRA",
  "ARTFRI",
  "ARTLUD",
  "ARTTRI",
  "BALSAG",
  "CARNUT",
  "CENSTO",
  "CHADOU",
  "CHRVIS",
  "CICHOR",
  "CIRARV",
  "CIRUND",
  "CIRVUL",
  "CONCAN",
  "CORTIN",
  "CREATR",
  "CREINT",
  "DIECAN",
  "ERINAU",
  "ERICOM",
  "ERICOR",
  "ERIDIV",
  "ERIGLA",
  "ERILON",
  "ERIPUM",
  "ERISPE",
  "ERISUB",
  "ERILAN",
  "EURCON",
  "GAIARI",
  "GNASPP",
  "GRISQU",
  "HELANN",
  "HETVIL",
  "HIEALB",
  "HIESCO",
  "HIEUMB",
  "LACSER",
  "LEUVUL",
  "LOGARV",
  "MATDIS",
  "PACCAN",
  "RATCOL",
  "SENINT",
  "SENSER",
  "SENTRI",
  "SOLCAN",
  "SOLMIS",
  "SYMEAT",
  "SYMERI",
  "SYMLAE",
  "TANVUL",
  "TARLAE",
  "TAROFF",
  "TRADUB",
  "XANSTR",
  "MADGLO",
  "HELMUL",
  "MICNUT",
  "BERREP",
  "ALNVIR",
  "ALNINC",
  "ASPPRO",
  "CRYAMB",
  "CRYCEL",
  "HACDEF",
  "HACFLO",
  "LAPOCC",
  "LITARV",
  "LITRUD",
  "MEROBL",
  "MYOASI",
  "MYOLAX",
  "MYOSTR",
  "PLASCO",
  "ECHVUL",
  "ALYALY",
  "ARATHA",
  "ARAHOL",
  "ARAMIC",
  "ARANUT",
  "BOEPEN",
  "BOESPP",
  "CAMMIC",
  "CAPBUR",
  "CAROLI",
  "CARPEN",
  "DRANEM",
  "DRAVER",
  "ERYASP",
  "ERYINC",
  "LEPDEN",
  "LEPPER",
  "NASOFF",
  "PHYGEY",
  "RORPAL",
  "SISALT",
  "SISLOE",
  "THLARV",
  "TURGLA",
  "DESINC",
  "DESSOP",
  "OPUFRA",
  "CALSTA",
  "CAMROT",
  "LINBOR",
  "LONCIL",
  "SAMNIG",
  "SAMRAC",
  "SAMSP",
  "SYMALB",
  "LONUTA",
  "ARECON",
  "ARESER",
  "DIAARM",
  "HOLUMB",
  "SILANT",
  "SILLAT",
  "SILMEN",
  "SILORE",
  "STEMED",
  "STELON",
  "SAPOFF",
  "CHEALB",
  "CHEFRE",
  "CHELEP",
  "KRALAN",
  "SALKAL",
  "PERSER",
  "HYPPER",
  "CONARV",
  "CORSER",
  "SEDLAN",
  "SEDSTE",
  "JUNSCO",
  "CARARC",
  "CARATH",
  "CARBAC",
  "CARBRE",
  "CARCAN",
  "CARCON",
  "CARDEW",
  "CARFIL",
  "CARGEY",
  "CARHOO",
  "CARMIC",
  "CARPEL",
  "CARPET",
  "CARPRA",
  "CARRET",
  "CARROS",
  "CARRUP",
  "CARSTI",
  "CARUTR",
  "CARVES",
  "CARVUL",
  "CARXER",
  "ELEACI",
  "ELEPAL",
  "SCHTAB",
  "SCICYP",
  "ELEOVA",
  "ATHFIL",
  "CYSFRA",
  "DRYFIL",
  "WOOORE",
  "SHECAN",
  "EQUARV",
  "EQUHYE",
  "EQULAE",
  "EQUSYL",
  "ARCUVA",
  "MENFER",
  "VACCES",
  "VACGLO",
  "VACSCO",
  "EUPESU",
  "EUPSER",
  "ASTCAN",
  "ASTINF",
  "ASTMIS",
  "ASTPUR",
  "GLYMAX",
  "LUPARG",
  "LUPPOL",
  "LUPSER",
  "MEDLUP",
  "MEDSAT",
  "MELOFF",
  "ONOVIC",
  "OXYBES",
  "SALSCL",
  "TRIAUR",
  "TRIPRA",
  "TRIREP",
  "VICAME",
  "TRILON",
  "DALPUR",
  "GLYLEP",
  "CORAUR",
  "GENAMA",
  "EROCIC",
  "GERVIS",
  "GERPUS",
  "RIBHUD",
  "RIBINE",
  "RIBVIS",
  "RIBCER",
  "RIBIRR",
  "RIBLAC",
  "HIPVUL",
  "PHILEW",
  "ELOCAN",
  "HYDCAP",
  "PHAHAS",
  "PHALIN",
  "PHAGLA",
  "NEMBRE",
  "SISMON",
  "JUNART",
  "JUNBAL",
  "JUNDUD",
  "JUNENC",
  "JUNTEN",
  "LUZHIT",
  "LUZMUL",
  "AGAURT",
  "GALTET",
  "LAMAMP",
  "MARVUL",
  "MENAQU",
  "MENARV",
  "MONFIS",
  "LEMMIN",
  "SPIPOL",
  "UTRVUL",
  "ALLCER",
  "CLIUNI",
  "ERYGRA",
  "FRIPUD",
  "MAIRAC",
  "MAISTE",
  "PROTRA",
  "STEOCC",
  "STRAMP",
  "TOXVEN",
  "TRIGRA",
  "VERVIR",
  "XERTEN",
  "LINLEW",
  "MENDIS",
  "MENLAE",
  "MALPAR",
  "SPHCOC",
  "PTEAND",
  "NUPLUT",
  "CHAANG",
  "CLAPUL",
  "EPIBRA",
  "EPICIL",
  "EPIGLA",
  "GAUCOC",
  "CIRALP",
  "CLAAMO",
  "CALBUL",
  "CYPMON",
  "GOOOBL",
  "PIPUNA",
  "CASHIS",
  "CASLUT",
  "CASMIN",
  "CASPAL",
  "PEDBRA",
  "PEDCON",
  "PEDRAC",
  "OROFAS",
  "ESCCAL",
  "MIMGUT",
  "ABIGRA",
  "ABILAS",
  "LAROCC",
  "PICENG",
  "PINCON",
  "PINPON",
  "PSEMEN",
  "PINMON",
  "BESWYO",
  "COLPAR",
  "LINDAL",
  "LINVUL",
  "PENALB",
  "PENDIP",
  "PENERI",
  "PENPRO",
  "PENWIL",
  "PLALAN",
  "PLAPAT",
  "VERAME",
  "VERANA",
  "VERARV",
  "VERVER",
  "COLDEB",
  "AGRCRI",
  "AGRSCA",
  "AGRSTO",
  "ALOGEN",
  "ANDGER",
  "APEINT",
  "ARIPUR",
  "BOUGRA",
  "BROCAR",
  "BROCOM",
  "BROHOR",
  "BROINE",
  "BROJAP",
  "BROTEC",
  "BROVUL",
  "CALCAN",
  "CALRUB",
  "CINLAT",
  "DACGLO",
  "DANUNI",
  "DICACU",
  "ECHCRI",
  "ELYCAN",
  "ELYCIN",
  "ELYELY",
  "ELYGLA",
  "ELYLAN",
  "ELYREP",
  "ELYTRA",
  "FESCAM",
  "FESIDA",
  "FESOCC",
  "FESOVI",
  "FESSUB",
  "GLYELA",
  "GLYGRA",
  "GLYSTR",
  "HORJUB",
  "KOEMAC",
  "MELBUL",
  "PANCAP",
  "PHAARU",
  "PHLPRA",
  "POABUL",
  "POACOM",
  "POACUS",
  "POAPAL",
  "POAPRA",
  "POASEC",
  "POATRI",
  "POAWHE",
  "PSAJUN",
  "PSESPI",
  "SETPAR",
  "SPOCRY",
  "STICOM",
  "STIHYM",
  "STINEL",
  "STIOCC",
  "STIVIR",
  "THIINT",
  "TRICER",
  "TRIAES",
  "VULOCT",
  "ZEAMAY",
  "GILTEN",
  "LEPNUT",
  "LEPSEP",
  "MICGRA",
  "PHLHOO",
  "PHLKEL",
  "POLMIC",
  "POLPUL",
  "POLVIS",
  "COLLIN",
  "ERIUMB",
  "ERIFLA",
  "ERIOVA",
  "POLAMP",
  "POLCON",
  "POLDOU",
  "POLLAP",
  "POLMAC",
  "RUMACE",
  "RUMCRI",
  "OXYDIG",
  "CLALAN",
  "CLAPER",
  "MONLIN",
  "LEWRED",
  "POTAMP",
  "POTFOL",
  "ANDOCC",
  "DOUMON",
  "DODCON",
  "DODPUL",
  "LYSCIL",
  "PYRASA",
  "PYRCHL",
  "CHIUMB",
  "ORTSEC",
  "RANABO",
  "RANAQU",
  "RANGLA",
  "RANREP",
  "RANUNC",
  "THADAS",
  "THAOCC",
  "ANEPAT",
  "RANFLA",
  "ACTRUB",
  "CLECOL",
  "CLELIG",
  "CLEOCC",
  "DELBIC",
  "CEAVEL",
  "AMEALN",
  "CERLED",
  "DRYGLA",
  "FRAVES",
  "FRAVIR",
  "GEUMAC",
  "GEUTRI",
  "HOLDIS",
  "PHYMAL",
  "POTBIE",
  "POTGRA",
  "POTREC",
  "PRUEMA",
  "PRUVIR",
  "PURTRI",
  "ROSGYM",
  "ROSWOO",
  "RUBIDA",
  "RUBPAR",
  "SANOCC",
  "SORSCO",
  "SPIBET",
  "SANMIN",
  "CRADOU",
  "GALAPA",
  "GALBOR",
  "GALTRI",
  "SALSCO",
  "POPBAL",
  "POPTRE",
  "SALALB",
  "SALBEB",
  "SALEXI",
  "COMUMB",
  "HEUCYL",
  "HEUPAR",
  "LITTEN",
  "MICODO",
  "MICOCC",
  "MITNUD",
  "MITSTA",
  "TIATRI",
  "LITGLA",
  "LITPAR",
  "CYNOFF",
  "ORTTEN",
  "PRUVUL",
  "VERBLA",
  "VERTHA",
  "LIMAQU",
  "SELDEN",
  "HYONIG",
  "SOLDUL",
  "SOLTRI",
  "SPAANG",
  "SPAEUR",
  "TYPLAT",
  "URTDIO",
  "PLEMAC",
  "VALDIO",
  "VERBRA",
  "VIOADU",
  "VIOCAN",
  "VIOGLA",
  "VIONUT",
  "VIOORB",
  "ARCAME",
  "ARCDOU",
  "ZANPAL",
  "native",
  "nonnative",
  "diversity",
  "Iridaceae",
  "Campanulaceae",
  "Violaceae",
  "Plantaginaceae",
  "Apocynaceae",
  "Zannichelliaceae",
  "Scrophulariaceae",
  "Urticaceae",
  "Monotropaceae",
  "Typhaceae",
  "Caprifoliaceae",
  "Ranunculaceae",
  "Hydrocharitaceae",
  "Pyrolaceae",
  "Hydrangeaceae",
  "Fabaceae",
  "Polygonaceae",
  "Verbenaceae",
  "Apiaceae",
  "Salicaceae",
  "Caryophyllaceae",
  "Portulacaceae",
  "Papaveraceae",
  "Hippuridaceae",
  "Sparganiaceae",
  "Anacardiaceae",
  "Linaceae",
  "Lentibulariaceae",
  "Convolvulaceae",
  "Brassicaceae",
  "Orchidaceae",
  "Amaranthaceae",
  "Asclepiadaceae",
  "Liliaceae",
  "Crassulaceae",
  "Saxifragaceae",
  "Cyperaceae",
  "Primulaceae",
  "Orobanchaceae",
  "Boraginaceae",
  "Potamogetonaceae",
  "Cactaceae",
  "Valerianaceae",
  "Lamiaceae",
  "Juncaceae",
  "Hydrophyllaceae",
  "Cleomaceae",
  "Onagraceae",
  "Berberidaceae",
  "Rhamnaceae",
  "Lemnaceae",
  "Rubiaceae",
  "Phrymaceae",
  "Araliaceae",
  "Gentianaceae",
  "Euphorbiaceae",
  "Dryopteridaceae",
  "Nymphaeaceae",
  "Polemoniaceae",
  "Solanaceae",
  "Poaceae",
  "Fumariaceae",
  "Betulaceae",
  "Clusiaceae",
  "Malvaceae",
  "Loasaceae",
  "Cupressaceae",
  "Viscaceae",
  "Geranianceae",
  "Santalaceae",
  "Rosaceae",
  "Alismataceae",
  "Elaeagnaceae",
  "Aceraceae",
  "Ericaceae",
  "Cornaceae",
  "Asteraceae",
  "Callitrichaceae",
  "Pinaceae",
  "Grossulariaceae",
  "Equisetaceae",
  "forb",
  "vine",
  "shrub",
  "tree",
  "graminoid"
];

export const fetchFloraOverview = () => {};

export const fetchFloraDetail = () => {
  //   const sql = `SELECT * FROM ${config.flora_detail_table}`;
  const sql = `SELECT ${column_list.map(
    c => "p." + c
  )}, voronoi.the_geom AS the_geom
          FROM ${config.flora_detail_table} p
          JOIN ${config.flora_voronoi_table} voronoi
          ON p.n = voronoi.n`;

  const speciesSql = `SELECT * FROM ${config.flora_species_list}`;

  return dispatch => {
    dispatch({
      type: actions.FLORA_GEOJSON_REQUEST,
      geojson: null
    });

    return Promise.all([
      fetchSqlPost(sql, "geojson"),
      fetchSql(speciesSql, "json")
    ])
      .then(data => {
        let geojson = data[0];
        let species = data[1];

        geojson.features = geojson.features.map(f => {
          let properties = f.properties;

          let ks = Object.keys(properties);
          let total = properties.native + properties.nonnative;

          if (total > 100) {
            ks.forEach(k => {
              if (k !== "n" && k !== "latitude" && k !== "longitude") {
                properties[k] = (f.properties[k] / total) * 100;
              }
            });
          }

          return { ...f, properties };
        });

        let families = species.rows
          .filter(s => s.namescientific === s.namefamily)
          .reduce((acc, cur) => ({ ...acc, [cur.namefamily]: cur }), {});

        let rows = species.rows.map(s => {
          let code = s.plantcode;
          const { max, total, n } = geojson.features.reduce(
            (acc, f) => ({
              total: acc.total + f.properties[code.toLowerCase()],
              max: Math.max(acc.max, f.properties[code.toLowerCase()]),
              n: acc.n + (f.properties[code.toLowerCase()] > 0 ? 1 : 0)
            }),
            { max: 0, total: 0, n: 0 }
          );

          return {
            ...s,
            family_description: families[s.namefamily].description,
            family_photo: families[s.namefamily].photo,
            total,
            max,
            n
          };
        });

        rows = rows.filter(s => s.total > 0);

        dispatch({
          type: actions.FLORA_GEOJSON_RESPONSE,
          geojson,
          species: rows
        });
      })
      .catch(error =>
        dispatch({
          type: actions.FLORA_GEOJSON_ERROR_RESPONSE,
          error: error.message
        })
      );
  };
};
