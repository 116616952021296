import * as actions from "../constants/action_names";

export const selectManagementUnit = managementUnit => {
  return dispatch => {
    dispatch({
      type: actions.RESTORATION_MANAGEMENT_UNIT_SELECT,
      managementUnit
    });
  };
};
