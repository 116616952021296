import * as actions from "../constants/action_names";

export const selectTimelapse = timelapse => {
  return dispatch => {
    dispatch({
      type: actions.TIMELAPSE_SELECT,
      timelapse
    });
  };
};

export const setFullscreen = fullscreen => dispatch => {
  return dispatch({
    type: actions.TIMELAPSE_SET_FULLSCREEN,
    fullscreen,
    pushToUrl: { fullscreen }
  });
};
