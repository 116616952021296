import * as actions from "../../constants/action_names";
import * as config from "../../constants/config";
import { fetchSql } from "../../constants/carto";
import { getCamera } from "../../loaders/livecamLoader";

export const fetchLivecamPoints = () => {
  const sql = `SELECT cartodb_id AS id, name, updated_host as host, deg_to_north, the_geom, is_raptor_nest, is_thermal AS thermal, bispectral, protected, controls_protected
    FROM ${config.livecam_table}
    WHERE active = true`;

  return dispatch => {
    dispatch({
      type: actions.LIVECAM_POINTS_REQUEST
    });

    return fetchSql(sql, "geojson")
      .then(geojson => {
        dispatch({
          type: actions.LIVECAM_POINTS_RESPONSE,
          geojson
        });

        // Once we receive livecam points, fetch details for each camera
        geojson.features.forEach(livecam =>
          dispatch(fetchLivecamDetails(livecam))
        );
      })
      .catch(error =>
        dispatch({
          type: actions.LIVECAM_POINTS_ERROR_RESPONSE,
          error: error.message
        })
      );
  };
};

export const fetchLivecamDetails = livecam => {
  return dispatch => {
    dispatch({
      type: actions.LIVECAM_DETAILS_REQUEST,
      livecam
    });

    return getCamera(livecam.properties)
      .query()
      .then(details =>
        dispatch({
          type: actions.LIVECAM_DETAILS_RESPONSE,
          livecam,
          details
        })
      )
      .catch(error =>
        dispatch({
          type: actions.LIVECAM_DETAILS_ERROR_RESPONSE,
          error: error.message
        })
      );
  };
};
