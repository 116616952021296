import React from "react";
import { fetchBiocrustDetail } from "../actions/async/biocrust";
import {
  fetchEBirdOverview,
  fetchEBirdPoints,
  fetchEBirdModalDetail
} from "../actions/async/ebird";
import {
  fetchInaturalistOverview,
  fetchInaturalistPoints
} from "../actions/async/inaturalist";
import { fetchLivecamPoints } from "../actions/async/livecam";
import {
  fetchRaptorOverview,
  fetchRaptorDetail
} from "../actions/async/raptor";
import {
  fetchRestorationDetail,
  fetchRestorationOverview
} from "../actions/async/restoration";
import { fetchSoilOverview, fetchSoilPoints } from "../actions/async/soil";
import { fetchVideoOverview, fetchVideoPoints } from "../actions/async/video";
import {
  fetchMotionOverview,
  fetchTrailcamTriggerDetail,
  fetchTimelapseOverview,
  fetchTimelapseDetail
} from "../actions/async/trailcam";
import {
  fetchWeatherOverview,
  fetchWeatherPoints
} from "../actions/async/weather";
import { fetchBeeDetail, fetchBeesOverview } from "../actions/async/bees";
import { fetchFloraDetail } from "../actions/async/flora";
import BeeLayer from "../components/bees/BeeLayer.js";
import BeeModal from "../components/bees/BeeModal.js";
import BeeLegend from "../components/bees/BeeLegend.js";
import TrailcamLayer from "../components/trailcams/TrailcamLayer.js";
import TrailcamModal from "../components/trailcams/TrailcamModal.js";
import BirdLayer from "../components/birds/BirdLayer.js";
import BirdModal from "../components/birds/BirdModal.js";
import InaturalistLayer from "../components/inaturalist/InaturalistLayer.js";
import InaturalistModal from "../components/inaturalist/InaturalistModal.js";
import InaturalistLegend from "../components/inaturalist/InaturalistLegend.js";
import BiocrustLayer from "../components/biocrust/BiocrustLayer";
import BiocrustModal from "../components/biocrust/BiocrustModal";
import BiocrustPointsLayer from "../components/biocrustPoints/BiocrustPointsLayer";
import BiocrustPointsModal from "../components/biocrustPoints/BiocrustPointsModal";
import LivecamLayer from "../components/livecams/LivecamLayer.js";
import LivecamModal from "../components/livecams/LivecamModal.js";
import RestorationLayer from "../components/restoration/RestorationLayer.js";
import RestorationModal from "../components/restoration/RestorationModal.js";
import SoilLayer from "../components/soil/SoilLayer.js";
import SoilModal from "../components/soil/SoilModal.js";
import VideoLayer from "../components/videos/VideoLayer.js";
import VideoModal from "../components/videos/VideoModal.js";
import AerialLayer from "../components/aerial/AerialLayer.js";
import AerialLegend from "../components/aerial/AerialLegend";
import AerialTimeline from "../components/aerial/AerialTimeline";
import { imageryExists } from "../components/aerial/imagery";
import WeatherLayer from "../components/weather/WeatherLayer.js";
import WeatherModal from "../components/weather/WeatherModal.js";
import { TileLayer } from "react-leaflet";
import { Route } from "react-router";
import TimelapseLayer from "../components/timelapse/TimelapseLayer.js";
import TimelapseModal from "../components/timelapse/TimelapseModal.js";
import moment from "moment";
import * as config from "../constants/config.js";
import RaptorLayer from "../components/raptors/RaptorLayer.js";
import RaptorModal from "../components/raptors/RaptorModal.js";
import RaptorLegend from "../components/raptors/RaptorLegend.js";
import FloraModal from "../components/flora/FloraModal.js";
import FloraLayer from "../components/flora/FloraLayer.js";
import FloraLegend from "../components/flora/FloraLegend.js";
import GradientLegend from "../components/SpeciesList/GradientLegend";
import PointLegend from "../components/SpeciesList/PointLegend";
import { pointColors } from "./layers_static";
import * as d3 from "d3";
import BiocrustPointsLegend from "../components/biocrustPoints/BiocrustPointsLegend";

export const layers = {
  aerial: {
    name: "Aerial imagery",
    timeline: {
      action: null,
      component: AerialTimeline,
      transform: () => {
        let dates = [];
        for (var year = 2015; year <= moment().year(); year++) {
          if (imageryExists(year)) {
            dates.push({
              start: moment(new Date(year, 0, 9)),
              end: moment(new Date(year, 11, 26))
            });
          }
        }
        return dates;
      }
    },
    legend: {
      component: props => (
        <AerialLegend
          startDate={props.timeline.bounds[0]}
          endDate={props.timeline.bounds[1]}
        />
      )
    },
    map: {
      action: null,
      component: props => (
        <AerialLayer
          key="aerial_imagery_map_layer"
          opacity={props.layerSelector.opacity.aerial}
        />
      )
    },
    modal: {
      component: () => <div>Aerial imagery</div>
    },
    opacity: true
  },
  ndvi: {
    name: "Vegetation (NDVI)",
    timeline: { transform: null, action: null },
    map: {
      action: null,
      component: props => (
        <TileLayer
          url={config.ndvi2015}
          key={"ndvi_map_layer"}
          className="ndvi-tile-layer"
          detectRetina={true}
          maxNativeZoom={18}
          maxZoom={22}
          pane={"overlayPane"}
          opacity={props.layerSelector.opacity.ndvi}
        />
      )
    },
    modal: {
      component: () => null
    },
    legend: {
      component: () => (
        <GradientLegend
          min="Barren"
          max="Vegetated"
          label="Normalized difference vegation index"
          scale="linear-gradient(90deg, #40004b, #722e80, #9a6daa, #c1a4cd, #e3d2e6, #eff0ef, #d6eed1, #a2d79f, #5dad65, #217939, #217939)"
        />
      )
    },
    opacity: true
  },
  biocrust: {
    name: "Predicted biocrust",
    timeline: { transform: null, action: null },
    map: {
      action: null,
      component: props => (
        <BiocrustLayer
          key="biocrust"
          opacity={props.layerSelector.opacity.biocrust}
        />
      )
    },
    modal: {
      component: () => <BiocrustModal />
    },
    opacity: true,
    legend: {
      component: props => (
        <GradientLegend
          max={100}
          unit={"%"}
          label={"Predicted " + props.biocrust.filter}
          scale={
            "linear-gradient(90deg, " + pointColors.biocrust.join(",") + ")"
          }
        />
      )
    }
  },
  biocrustPoints: {
    name: "Biocrust",
    timeline: { transform: null, action: null },
    map: {
      action: fetchBiocrustDetail,
      component: props => {
        const points = props.biocrustPoints.points
          ? Object.assign({}, props.biocrustPoints.points)
          : { features: [] };
        return <BiocrustPointsLayer data={points} key="biocrustPoints" />;
      }
    },
    modal: {
      component: () => (
        <Route
          path={"/biocrustPoints/:plot_id?"}
          render={props => <BiocrustPointsModal {...props} />}
        />
      )
    },
    legend: {
      component: props => (
        <BiocrustPointsLegend
          species={props.biocrustPoints.species || []}
          pointdata={props.biocrustPoints.points}
          filter={props.biocrustPoints.filter}
        />
      )
    }
  },
  solar: {
    name: "Solar radiation",
    timeline: { transform: null, action: null },
    map: {
      action: null,
      component: props => (
        <TileLayer
          url={config.avgSolarIns}
          key={"solar_map_layer"}
          className="solar-tile-layer"
          detectRetina={true}
          maxNativeZoom={15}
          maxZoom={22}
          pane={"overlayPane"}
          opacity={props.layerSelector.opacity.solar}
        />
      )
    },
    legend: {
      component: () => (
        <GradientLegend
          min="Less"
          max="More"
          label="Solar radiation"
          scale="linear-gradient(270deg, #ffffcc, #fff1a9, #fee087, #fec966, #feab4b, #fd893c, #fa5c2e, #ec3023, #d31121, #af0225, #af0225)"
        />
      )
    },
    modal: {
      component: () => null
    },
    opacity: true
  },
  elevation: {
    name: "Elevation",
    timeline: { transform: null, action: null },
    map: {
      action: null,
      component: props => (
        <TileLayer
          url={config.elevationTiles}
          key={"elevation_map_layer"}
          className="elevation-tile-layer"
          detectRetina={false}
          maxNativeZoom={15}
          minZoom={8}
          opacity={props.layerSelector.opacity.elevation}
          pane={"overlayPane"}
          tms={true}
        />
      )
    },
    legend: {
      component: () => (
        <GradientLegend
          min="950"
          max="2150"
          unit="&nbsp;m"
          label="Elevation"
          scale={`linear-gradient(90deg, rgba(30, 0, 0, 255),
                    rgba(161, 104, 104, 255),
                    rgba(195, 131, 128, 255),
                    rgba(206, 167, 145, 255),
                    rgba(216, 192, 158, 255),
                    rgba(224, 212, 168, 255),
                    rgba(231, 230, 178, 255),
                    rgba(238, 238, 200, 255),
                    rgba(244, 244, 221, 255),
                    rgba(249, 249, 239, 255),
                    rgba(255, 255, 255, 255))`}
        />
      )
    },
    opacity: true,
    modal: {
      component: () => null
    }
  },
  birds: {
    name: "eBird",
    timeline: {
      action: fetchEBirdOverview,
      transform: props => {
        if (props.eBird.selected) {
          return {
            all: props.eBird.overview
              ? props.eBird.overview.rows.map(o => ({
                  date: o.week,
                  radius: o.obs_qty * 0.2
                }))
              : null,
            selected: props.eBird.selected.rows.map(o => ({
              date: o.week,
              radius: o.obs_qty * 0.2
            }))
          };
        } else {
          return props.eBird.overview
            ? props.eBird.overview.rows.map(o => ({
                date: o.week,
                radius: o.obs_qty * 0.2
              }))
            : null;
        }
      }
    },

    map: {
      action: [fetchEBirdPoints, fetchEBirdModalDetail],
      component: props => {
        let points = props.eBird.points
          ? Object.assign({}, props.eBird.points)
          : { features: [] };

        return (
          <BirdLayer data={points} key={"ebird_map_layer_" + points.length} />
        );
      }
    },

    additionalAsyncArguments: props => [props.eBird.filter],

    modal: {
      component: () => (
        <Route
          path="/birds/:locId?"
          render={props => <BirdModal {...props} />}
        />
      )
    },

    legend: {
      component: () => (
        <PointLegend
          max={40}
          label={"Bird species observations"}
          color={pointColors.eBird}
        />
      )
    }
  },

  restoration: {
    name: "Updates",
    timeline: {
      action: fetchRestorationOverview,
      transform: props => {
        return props.restoration.overview
          ? props.restoration.overview.rows.map(row => {
              return { date: row.date, radius: row.count * 20 };
            })
          : null;
      }
    },
    map: {
      action: fetchRestorationDetail,
      component: props => {
        let points = props.restoration.points
          ? Object.assign({}, props.restoration.points)
          : { features: [] };
        return (
          <RestorationLayer
            data={points}
            key={"restoration_map_layer_" + points.length}
          />
        );
      }
    },
    modal: {
      component: () => <RestorationModal />
    },
    legend: {
      component: props => {
        let max = d3.max(
          (props.restoration.points
            ? props.restoration.points.features
            : []
          ).map(f => f.properties.count)
        );

        return max ? (
          <GradientLegend
            max={max}
            unit=""
            label="Number of restoration updates"
            scale={
              "linear-gradient(90deg, rgba(0,0,0,0), " +
              pointColors.restoration +
              ")"
            }
          />
        ) : (
          <div />
        );
      }
    }
  },

  livecams: {
    name: "Live cameras",
    timeline: {},
    map: {
      action: fetchLivecamPoints,
      component: props => {
        const points = props.livecam.points
          ? Object.assign({}, props.livecam.points)
          : { features: [] };

        return (
          <LivecamLayer
            data={points}
            key={"livecam_map_layer_" + points.length}
          />
        );
      }
    },
    modal: {
      component: () => (
        <Route
          path="/livecams"
          render={props => (
            <LivecamModal auth={props.auth} livecam={props.livecam} />
          )}
        />
      )
    }
  },

  flora: {
    name: "Flora",
    timeline: {},
    map: {
      action: fetchFloraDetail,
      component: props => {
        const polys = props.flora.geojson
          ? Object.assign({}, props.flora.geojson)
          : { features: [] };

        return (
          <Route
            path="/:modal?/:n?"
            key="flora_map_layer"
            render={ps => (
              <FloraLayer
                data={polys}
                key={"flora_map_layer_" + polys.length}
                n={ps.match.params.modal === "flora" ? ps.match.params.n : -1}
                zIndex={props.zIndex}
              />
            )}
          />
        );
      }
    },
    modal: {
      component: () => (
        <Route
          path={"/flora/:n?"}
          exact={true}
          render={props => <FloraModal {...props} />}
        />
      )
    },
    legend: {
      component: props => (
        <FloraLegend
          geojson={props.flora.geojson}
          filter={props.flora.filter}
          species={props.flora.species || []}
        />
      )
    }
  },

  bees: {
    name: "Pollinators",
    timeline: {
      action: fetchBeesOverview,
      transform: props => {
        if (props.bees.selected) {
          return {
            all: props.bees.overview
              ? props.bees.overview.rows.map(o => ({
                  date: o.samplingdate,
                  radius: o.cnt * 0.5
                }))
              : null,
            selected: props.bees.selected.rows.map(o => ({
              date: o.samplingdate,
              radius: o.cnt * 0.5
            }))
          };
        } else {
          return props.bees.overview
            ? props.bees.overview.rows.map(o => ({
                date: o.samplingdate,
                radius: o.cnt * 0.5
              }))
            : null;
        }
      }
    },
    map: {
      action: fetchBeeDetail,
      component: props => {
        const points = props.bees.points
          ? Object.assign({}, props.bees.points)
          : { features: [] };

        return <BeeLayer data={points} key={"bee_layer_" + points.length} />;
      }
    },
    modal: {
      component: () => (
        <Route
          path="/bees/:site?"
          key="beemodal"
          render={props => <BeeModal />}
        />
      )
    },
    additionalAsyncArguments: props => [props.bees.filter],
    legend: {
      component: props => (
        <BeeLegend filter={props.bees.filter} points={props.bees.points} />
      )
    }
  },

  inaturalist: {
    name: "iNaturalist",
    timeline: {
      action: fetchInaturalistOverview,
      transform: props => {
        if (props.inaturalist.selected) {
          return {
            all: props.inaturalist.overview
              ? props.inaturalist.overview.rows.map(o => ({
                  date: o.date,
                  radius: o.count * 5
                }))
              : null,
            selected: props.inaturalist.selected.rows.map(o => ({
              date: o.date,
              radius: o.count * 5
            }))
          };
        } else {
          return props.inaturalist.overview
            ? props.inaturalist.overview.rows.map(o => ({
                date: o.date,
                radius: o.count * 5
              }))
            : null;
        }
      }
    },
    map: {
      action: fetchInaturalistPoints,
      component: props => {
        let points = props.inaturalist.points
          ? Object.assign({}, props.inaturalist.points)
          : { features: [] };

        return (
          <InaturalistLayer
            data={points}
            key="inaturalist_map_layer"
            style={{ color: "rgba(0,0,255,0.3)", width: 1 }}
          />
        );
      }
    },
    modal: {
      component: () => (
        <React.Fragment>
          <Route
            path="/inaturalist/:observation?"
            render={props => <InaturalistModal {...props} />}
          />
        </React.Fragment>
      )
    },
    legend: {
      component: () => <InaturalistLegend />
    },
    additionalAsyncArguments: props => [props.inaturalist.filter]
  },

  trailcams: {
    name: "Trail cameras",
    timeline: {
      action: fetchMotionOverview,
      transform: props => {
        return props.trailcams.overview
          ? props.trailcams.overview.rows.map(o => {
              return { date: o.weekly, radius: o.cnt / 10 };
            })
          : null;
      }
    },
    map: {
      action: fetchTrailcamTriggerDetail,
      component: props => {
        const points = props.trailcams.points
          ? Object.assign({}, props.trailcams.points)
          : { features: [] };

        return (
          <TrailcamLayer
            data={points}
            key={"trailcam_map_layer_" + points.length}
          />
        );
      }
    },
    modal: {
      component: props => (
        <Route
          path="/trailcams"
          render={props => <TrailcamModal {...props} />}
        />
      )
    },
    legend: {
      component: props => {
        let max = props.trailcams.points
          ? d3.max(props.trailcams.points.features.map(f => f.properties.cnt))
          : 1;

        return max ? (
          <PointLegend
            label="Trailcam images"
            max={max}
            color={[0, 0.33, 0.67, 1].map(v => pointColors.trailcam(v))}
          />
        ) : (
          <div />
        );
      }
    }
  },

  timelapse: {
    name: "Timelapse",
    timeline: {
      action: fetchTimelapseOverview,
      transform: props => {
        return props.timelapse.overview
          ? props.timelapse.overview.rows.map(o => {
              return { date: o.weekly, radius: o.cnt / 2 };
            })
          : null;
      }
    },
    map: {
      action: fetchTimelapseDetail,
      component: props => {
        const points = props.timelapse.points
          ? Object.assign({}, props.timelapse.points)
          : { features: [] };

        return (
          <TimelapseLayer
            data={points}
            key={"timelapse_map_layer_" + points.length}
          />
        );
      }
    },
    modal: {
      component: () => (
        <Route
          path="/timelapse/:cam"
          render={props => <TimelapseModal {...props} />}
        />
      )
    }
  },

  raptors: {
    name: "Raptors",
    timeline: {
      action: fetchRaptorOverview,
      transform: props => {
        return props.raptors.overview
          ? props.raptors.overview.rows.map(o => {
              return { date: o.weekly, radius: o.cnt / 3 };
            })
          : null;
      }
    },
    map: {
      action: fetchRaptorDetail,
      component: props => {
        const tracks = props.raptors.tracks
          ? Object.assign({}, props.raptors.tracks)
          : { features: [] };

        const points = props.raptors.points
          ? Object.assign({}, props.raptors.points)
          : { features: [] };

        return (
          <RaptorLayer
            tracks={tracks}
            points={points}
            zoom={props.critterMap.zoom}
            key={"raptor_map_layer_" + points.length}
          />
        );
      }
    },
    modal: {
      component: () => <RaptorModal />
    },
    legend: {
      component: props => <RaptorLegend points={props.raptors.points} />
    }
  },

  soil: {
    name: "Soil moisture",
    timeline: {
      action: fetchSoilOverview,
      transform: props => {
        return props.soil.overview
          ? props.soil.overview.rows.map(o => {
              return { date: o.date, radius: o.count * 0.015 };
            })
          : null;
      }
    },
    map: {
      action: fetchSoilPoints,
      component: props => {
        const points = props.soil.points
          ? Object.assign({}, props.soil.points)
          : { features: [] };

        return <SoilLayer data={points} key="soil_map_layer" />;
      }
    },
    modal: {
      component: () => (
        <Route
          path="/soil/:station"
          render={props => <SoilModal {...props} />}
        />
      )
    },
    legend: {
      component: () => (
        <PointLegend
          color={pointColors.soil}
          min="Dry"
          max="Wet"
          label="Soil sensors"
        />
      )
    }
  },

  videos: {
    name: "Videos",
    timeline: {
      action: fetchVideoOverview,
      transform: props => {
        return props.video.overview
          ? props.video.overview.rows.map(o => {
              return { date: o.date, radius: o.count * 30 };
            })
          : null;
      }
    },
    map: {
      action: fetchVideoPoints,
      component: props => {
        let points = props.video.points
          ? Object.assign({}, props.video.points)
          : { features: [] };

        return (
          <VideoLayer
            data={points}
            key={"video_map_layer"}
            style={{ color: "rgba(0,0,255,0.3)", width: 1 }}
          />
        );
      }
    },
    modal: {
      component: () => (
        <Route path="/videos" render={props => <VideoModal {...props} />} />
      )
    }
  },

  weather: {
    name: "Weather",
    timeline: {
      action: fetchWeatherOverview,
      transform: props => {
        return props.weather.overview
          ? props.weather.overview.rows.map(o => {
              return { date: o.date, radius: o.count * 0.015 };
            })
          : null;
      }
    },
    map: {
      action: fetchWeatherPoints,
      component: props => {
        const points = props.weather.points
          ? Object.assign({}, props.weather.points)
          : { features: [] };

        return <WeatherLayer data={points} key="weather_map_layer" />;
      }
    },
    modal: {
      component: () => (
        <Route
          path="/weather/:station"
          render={props => <WeatherModal {...props} />}
        />
      )
    }
  }
};
