import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import FullscreenModal from "../modals/FullscreenModal";
import { fetchTimelapseImages } from "../../actions/async/trailcam";
import { selectTimelapse, setFullscreen } from "../../actions/timelapse";
import { slugify, trailcamUrl } from "../../constants/app";
import "./TimelapseModal.scss";
import TimelapseAnimation from "./TimelapseAnimation.js";
import fullscreenImg from "../../resources/icons/fullscreen.svg";

const mapStateToProps = state => ({
  timelapse: state.timelapse,
  timeline: state.timeline
});

const mapDispatchToProps = dispatch => ({
  fetchImages: (bounds, camSlug) => {
    dispatch(fetchTimelapseImages(bounds, camSlug));
  },
  setFullscreen: enable => dispatch(setFullscreen(enable)),
  selectTimelapse: timelapse => dispatch(selectTimelapse(timelapse))
});

class TimelapseModal extends Component {
  static selectCurrentTimelapse(props) {
    if (!(props.timelapse && props.timelapse.points)) return null;
    const slug = props.match.params.cam;
    const match = props.timelapse.points.features.filter(
      feature => slugify(feature.properties.text) === slug
    )[0];
    if (match && !match.properties._actionMapSelected) {
      props.selectTimelapse(match.properties.text);
    }
  }

  componentDidMount() {
    this.props.fetchImages(
      this.props.timeline.bounds,
      this.props.match.params.cam
    );
    TimelapseModal.selectCurrentTimelapse(this.props);
  }

  componentDidUpdate(previousProps) {
    if (
      this.props.match.params.cam !== previousProps.match.params.cam ||
      this.props.timeline.bounds !== previousProps.timeline.bounds
    ) {
      this.props.fetchImages(
        this.props.timeline.bounds,
        this.props.match.params.cam
      );
    }
    if (
      this.props.match.params.cam !== previousProps.match.params.cam ||
      this.props.timelapse.points !== previousProps.timelapse.points
    ) {
      TimelapseModal.selectCurrentTimelapse(this.props);
    }
  }

  render() {
    const images =
      this.props.timelapse.images && this.props.timelapse.images.length > 0
        ? this.props.timelapse.images.map(d => ({
            url: trailcamUrl(d.servername, d.camera_number, d.filename),
            date: d.date
          }))
        : [];
    const { fullscreen } = this.props.timelapse;

    let text = "No photos for this time period";

    if (this.props.timelapse.images && this.props.timelapse.images[0]) {
      text = this.props.timelapse.images[0].text;
      if (text === "") text = this.props.timelapse.images[0].id;
    }

    return (
      <React.Fragment>
        <div className="TimelapseModal">
          <div className="title">Timelapse: {text}</div>
          <div className="description">
            <p>
              The wildlife cameras around MPG Ranch are also configured to take
              a photograph every day at noon. Together, these images form a
              timelapse of changing plants, weather, and water. Select a
              different location on the map or region in the timeline to change
              the timelapse.
            </p>
          </div>

          <div className="timelapse-container">
            <TimelapseAnimation images={images} />
            {this.props.timelapse.images && this.props.timelapse.images[0] ? (
              <div
                className="fullscreen"
                onClick={() => this.props.setFullscreen(true)}
              >
                <img src={fullscreenImg} alt="View in fullscreen" />
              </div>
            ) : null}
          </div>
        </div>
        {fullscreen ? (
          <FullscreenModal onClose={() => this.props.setFullscreen(false)}>
            <TimelapseAnimation images={images} />
          </FullscreenModal>
        ) : null}
      </React.Fragment>
    );
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(TimelapseModal)
);
