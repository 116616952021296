import { Layer, LatLng } from "leaflet";
import * as d3 from "d3";

export default class SvgLayer extends Layer {
  constructor(opts) {
    super(opts);

    this.data = opts.data;
    this.onClick = opts.onClick;
  }

  translate(d) {
    const transformedPoint = this.transformPoint(...d.geometry.coordinates);
    return `translate(${transformedPoint.x}, ${transformedPoint.y})`;
  }

  transformPoint(x, y) {
    return this.map.latLngToLayerPoint(new LatLng(y, x));
  }

  updateData(data) {
    this.data = data;
  }

  rezoom() {
    this.g
      .selectAll("g.translategroup")
      .attr("transform", this.translate.bind(this));
  }

  avoidCollisions(data) {
    // Settings for forces
    const decayRate = 0.3;
    const forceStrength = 0.002;
    const radius = 8;

    // Set x and y to initial layer coordinates from lat lng
    const updatedFeatures = data.features.map(d => {
      const point = this.transformPoint(...d.geometry.coordinates);
      return Object.assign({}, d, point);
    });

    // If relatively zoomed in, use force simulation to avoid collisions
    if (this.map.getZoom() >= 12) {
      const simulation = d3
        .forceSimulation(updatedFeatures)
        .velocityDecay(decayRate)
        .force(
          "x",
          d3
            .forceX()
            .x(d => this.transformPoint(...d.geometry.coordinates).x)
            .strength(forceStrength)
        )
        .force(
          "y",
          d3
            .forceY()
            .y(d => this.transformPoint(...d.geometry.coordinates).y)
            .strength(forceStrength)
        )
        .force(
          "collide",
          d3
            .forceCollide()
            .radius(radius)
            .iterations(2)
        );

      // Make simulation run now rather than running dynamically
      const ticks = Math.ceil(
        Math.log(simulation.alphaMin()) / Math.log(1 - simulation.alphaDecay())
      );
      for (let i = 0; i < ticks; ++i) {
        simulation.tick();
      }
    }

    return Object.assign({}, data, { features: updatedFeatures });
  }

  viewReset() {
    let topLeft = [];
    let bottomRight = [];
    if (this.data.features.length > 0) {
      let bounds = d3.geoPath().bounds(this.data);
      let tl = this.transformPoint(bounds[0][0], bounds[0][1]);
      let br = this.transformPoint(bounds[1][0], bounds[1][1]);
      topLeft = [tl.x - 200, br.y - 200];
      bottomRight = [br.x + 200, tl.y + 200];
    } else {
      topLeft = [-1200, -1200];
      bottomRight = [1200, 1200];
    }

    this.svg
      .attr("width", bottomRight[0] - topLeft[0])
      .attr("height", bottomRight[1] - topLeft[1])
      .style("left", topLeft[0] + "px")
      .style("top", topLeft[1] + "px");

    this.g.attr(
      "transform",
      "translate(" + -topLeft[0] + "," + -topLeft[1] + ")"
    );
    this.rezoom();
  }

  onAdd(map) {
    this.map = map;
    this.svg = d3
      .select(map.getPanes().markerPane)
      .append("svg")
      .attr("width", 800)
      .attr("height", 800)
      .style("z-index", this.zIndex);

    this.g = this.svg.append("g").attr("class", "leaflet-zoom-hide");

    map.on("zoomend", this.viewReset.bind(this));
    map.on("viewreset", this.viewReset.bind(this));

    this.viewReset();
  }

  onRemove(map) {
    this.svg.remove();
  }

  setZIndex(zIndex) {
    this.zIndex = zIndex;
    if (this.svg) {
      this.svg.style("z-index", zIndex);
    }
  }
}
