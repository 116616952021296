import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, withRouter } from "react-router";

import { slugify } from "../../constants/app";
import {
  hasRole,
  ROLE_CONTROL_PROTECTED_LIVECAM,
  ROLE_VIEW_PROTECTED_LIVECAM
} from "../../constants/auth";
import FullscreenLivecamModal from "./FullscreenLivecamModal";
import LivecamModalContent from "./LivecamModalContent";
import { fetchLivecamDetails } from "../../actions/async/livecam";
import { selectLivecam } from "../../actions/livecam";

const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = dispatch => ({
  fetchLivecamDetails: livecam => dispatch(fetchLivecamDetails(livecam)),
  selectLivecam: livecam => dispatch(selectLivecam(livecam))
});

class IndividualLivecamModal extends Component {
  static getCurrentLivecam(props) {
    if (!(props.livecam && props.livecam.points)) return null;
    const slug = props.match.params.cam;
    const match = props.livecam.points.features.filter(
      feature => slugify(feature.properties.name) === slug
    );
    return match.length > 0 ? match[0] : null;
  }

  static selectCurrentLivecam(props) {
    const livecam = IndividualLivecamModal.getCurrentLivecam(props);
    if (livecam && !livecam.properties._actionMapSelected) {
      props.selectLivecam(livecam.properties.name);
    }
  }

  componentDidMount() {
    IndividualLivecamModal.selectCurrentLivecam(this.props);
  }

  shouldComponentUpdate(nextProps) {
    if (
      JSON.stringify(nextProps.location) ===
        JSON.stringify(this.props.location) &&
      JSON.stringify(nextProps.livecam) === JSON.stringify(this.props.livecam)
    ) {
      return false;
    }
    return true;
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.match.params.cam !== prevProps.match.params.cam ||
      JSON.stringify(this.props.livecam.points) !==
        JSON.stringify(prevProps.livecam.points)
    ) {
      IndividualLivecamModal.selectCurrentLivecam(this.props);
    }
  }

  render() {
    const { auth } = this.props;
    const canControlProtected = hasRole(auth, ROLE_CONTROL_PROTECTED_LIVECAM);
    const canViewProtected = hasRole(auth, ROLE_VIEW_PROTECTED_LIVECAM);
    const livecam = IndividualLivecamModal.getCurrentLivecam(this.props);

    return (
      <>
        <Route
          path="/livecams/:cam/:preset?"
          render={props => {
            const { pathname, search } = props.location;
            return (
              <LivecamModalContent
                canControlProtected={canControlProtected}
                canViewProtected={canViewProtected}
                fetchLivecamDetails={this.props.fetchLivecamDetails}
                fullscreen={false}
                fullscreenUrl={`${pathname}/fullscreen${search}`}
                livecam={livecam}
                preset={props.match.params.preset}
              />
            );
          }}
        />

        <Route
          path="/livecams/:cam/:preset?/fullscreen"
          render={props => {
            const closeUrl = `/livecams/${props.match.params.cam}`;
            return (
              <FullscreenLivecamModal
                livecam={livecam}
                fetchLivecamDetails={this.props.fetchLivecamDetails}
                canControlProtected={canControlProtected}
                canViewProtected={canViewProtected}
                closeUrl={closeUrl}
                preset={props.match.params.preset}
                {...props}
              />
            );
          }}
        />
      </>
    );
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(IndividualLivecamModal)
);
