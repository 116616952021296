export const tables = {
  development: {
    bee_table: "dev_table_091818_beesforstamen_mkuhlman",
    biocrust_data_table: "biocrust_data",
    biocrust_species_list_table: "biocrust_species_list",
    boundary_table: "mpg_boundaries_combined_historic",
    ebird_table: "dev_ebird_data",
    flora_detail_table: "dev_plants_aggregate_1",
    flora_species_list: "dev_plants_with_photos",
    flora_voronoi_table: "dev_plants_labeled_voronoi_grid",
    inaturalist_observations_table: "dev_inaturalist_observations",
    inaturalist_photos_table: "dev_inaturalist_photos",
    inaturalist_taxa_table: "dev_inaturalist_taxa",
    livecam_table: "dev_mpg_ranch_livecams",
    management_unit_table: "dev_mu_4",
    observation_points_table: "mpg_observation_points",
    restoration_action_responses: "dev_log_action_responses",
    restoration_photo_responses: "dev_mu_environment_photos_responses",
    restoration_table: "dev_mpg_habitat_reports",
    soil_data_table: "dev_soil_data",
    soil_station_table: "dev_mpg_ranch_soil_stations",
    trailcam_cameras_table: "dev_mpg_ranch_buckeyecams",
    trailcam_photos_table: "dev_buckeye_photos",
    video_table: "dev_mpg_ranch_videos",
    weather_data_table: "dev_weather_data",
    weather_station_table: "dev_mpg_ranch_weather_stations",
    bee_taxa_table: "bee_taxa",
    raptor_table: "movebank_output_combined"
  },
  production: {
    bee_table: "table_091818_beesforstamen_mkuhlman",
    biocrust_data_table: "biocrust_data",
    biocrust_species_list_table: "biocrust_species_list",
    boundary_table: "mpg_boundaries_combined_historic",
    ebird_table: "ebird_data",
    flora_detail_table: "plants_aggregate_1",
    flora_species_list: "plants_with_photos",
    flora_voronoi_table: "plants_labeled_voronoi_grid",
    inaturalist_observations_table: "inaturalist_observations",
    inaturalist_photos_table: "inaturalist_photos",
    inaturalist_taxa_table: "inaturalist_taxa",
    livecam_table: "mpg_ranch_livecams",
    management_unit_table: "mu_4",
    observation_points_table: "mpg_observation_points",
    restoration_action_responses: "log_action_responses",
    restoration_photo_responses: "mu_environment_photos_responses",
    restoration_table: "mpg_habitat_reports",
    soil_data_table: "soil_data",
    soil_station_table: "mpg_ranch_soil_stations",
    trailcam_cameras_table: "mpg_ranch_buckeyecams",
    trailcam_photos_table: "buckeye_photos",
    video_table: "mpg_ranch_videos",
    weather_data_table: "weather_data",
    weather_station_table: "mpg_ranch_weather_stations",
    bee_taxa_table: "bee_taxa",
    raptor_table: "movebank_output_combined"
  }
};
