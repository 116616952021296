import * as actions from "../constants/action_names";

export const selectBiocrust = site => {
  return dispatch => {
    dispatch({
      type: actions.BIOCRUST_POINT_SELECT,
      site
    });
  };
};
