import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, withRouter } from "react-router";
import { slugify } from "../../constants/app";
import AllLivecamModal from "./AllLivecamModal";
import IndividualLivecamModal from "./IndividualLivecamModal";
import push from "../../constants/push.js";
import "./Livecam.css";

const mapStateToProps = state => ({
  auth: state.auth,
  livecam: state.livecam
});

const mapDispatchToProps = dispatch => ({
  push: url => dispatch(push(url))
});

class LivecamModal extends Component {
  render() {
    return (
      <div className="LivecamModal">
        <Route
          path="/livecams"
          exact={true}
          render={props => {
            const { auth, livecam } = this.props;
            const livecams = livecam.points ? livecam.points.features : [];
            return (
              <AllLivecamModal
                auth={auth}
                livecams={livecams}
                selectLivecam={name => {
                  this.props.push(`/livecams/${slugify(name)}`);
                }}
              />
            );
          }}
        />

        <Route
          path="/livecams/:cam/:preset?"
          render={() => <IndividualLivecamModal livecam={this.props.livecam} />}
        />
      </div>
    );
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LivecamModal)
);
