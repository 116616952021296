import * as actions from "../constants/action_names.js";
import queryString from "query-string";

const query = queryString.parse(window.location.href.split("?")[1] || "");

const DEFAULT_STATE = {
  filter: query.biocrustpointsfilter ? query.biocrustpointsfilter : "total",
  points: { features: [] }
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actions.BIOCRUST_POINTS_GEOJSON_REQUEST:
      return {
        ...state,
        _fetchingSoilPoints: true
      };
    case actions.BIOCRUST_POINTS_GEOJSON_RESPONSE:
      return {
        ...state,
        points: {
          ...action.geojson,
          features: action.geojson.features.map(f => ({
            ...f,
            properties: {
              ...f.properties,
              total: f.properties.total_lichen + f.properties.total_moss
            }
          }))
        },
        species: action.species
      };
    case actions.BIOCRUST_POINTS_GEOJSON_ERROR_RESPONSE:
      console.error(action.error);
      return {
        ...state,
        error: action.error
      };
    case actions.BIOCRUST_POINTS_SET_FILTER:
      return {
        ...state,
        filter: action.filter
      };
    case actions.BIOCRUST_POINT_SELECT:
      return {
        ...state,
        points: {
          ...state.points,
          features: state.points.features.map(feature => {
            return {
              ...feature,
              properties: {
                ...feature.properties,
                _actionMapSelected:
                  feature.properties.plot_id.toLowerCase() ===
                  (action.site ? action.site.toLowerCase() : "none")
              }
            };
          })
        }
      };
    default:
      return state;
  }
};
