import moment from "moment";
import * as actions from "../../constants/action_names";
import * as config from "../../constants/config";
import { fetchSql } from "../../constants/carto";

export const fetchBeesOverview = filter => {
  const sql = `SELECT SUM(n) as cnt, samplingdate
      FROM ${config.bee_table}
      GROUP BY samplingdate`;

  if (filter) {
    const filteredSql = `SELECT SUM(n) as cnt, samplingdate
    FROM ${config.bee_table}
    WHERE SPLIT_PART(genus_species, ' ', 1) = '${filter}' OR
    REPLACE(genus_species, '  ', ' ') = '${filter}' 
    GROUP BY samplingdate`;

    return dispatch => {
      return Promise.all([fetchSql(sql, "json"), fetchSql(filteredSql, "json")])
        .then(([json, filteredjson]) => {
          json.rows = json.rows.map(r => ({
            ...r,
            samplingdate: new Date(r.samplingdate)
          }));
          filteredjson.rows = filteredjson.rows.map(r => ({
            ...r,
            samplingdate: new Date(r.samplingdate)
          }));
          dispatch({
            type: actions.BEE_OVERVIEW_RESPONSE,
            json,
            selected: filteredjson
          });
        })
        .catch(error =>
          dispatch({
            type: actions.BEE_OVERVIEW_ERROR_RESPONSE,
            error: error.message
          })
        );
    };
  } else {
    return dispatch => {
      return fetchSql(sql, "json")
        .then(json => {
          json.rows = json.rows.map(r => ({
            ...r,
            samplingdate: new Date(r.samplingdate)
          }));

          dispatch({
            type: actions.BEE_OVERVIEW_RESPONSE,
            json,
            selected: null
          });
        })
        .catch(error =>
          dispatch({
            type: actions.BEE_OVERVIEW_ERROR_RESPONSE,
            error: error.message
          })
        );
    };
  }
};

export const fetchBeeDetail = bounds => {
  if (!bounds) {
    bounds = [moment().subtract(6, "months"), moment()];
  }

  const sql = `SELECT SUM(b.m) AS m, SUM(b.f) AS f, SUM(b.n) AS cnt, SUM(b.queen::int) as queen, b.the_geom, b.genus_species, b.location_name,
              t.wikipedia_summary, t.default_photo, t.default_photo_attribution, t.preferred_common_name AS common_name,
              g.default_photo AS genus_photo, g.default_photo_attribution AS genus_photo_attribution, g.wikipedia_summary AS genus_summary
        FROM (${config.bee_table} b
        LEFT JOIN ${config.bee_taxa_table} g ON b.genusname = g.name)
        LEFT JOIN ${
          config.bee_taxa_table
        } t ON t.name = CONCAT(b.genusname, ' ', b.species)
        WHERE b.samplingdate > '${bounds[0].toISOString()}' AND b.samplingdate < '${bounds[1].toISOString()}'    
        GROUP BY b.the_geom, b.genus_species, b.location_name, t.wikipedia_summary, t.default_photo, t.default_photo_attribution, g.default_photo, g.default_photo_attribution, g.wikipedia_summary, t.preferred_common_name`;

  return dispatch => {
    dispatch({
      type: actions.BEE_POINTS_REQUEST,
      geojson: null
    });

    return fetchSql(sql, "geojson")
      .then(geojson => {
        dispatch({
          type: actions.BEE_POINTS_RESPONSE,
          geojson
        });
      })
      .catch(error =>
        dispatch({
          type: actions.BEE_POINTS_ERROR_RESPONSE,
          error: error.message
        })
      );
  };
};
