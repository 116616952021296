import * as actions from "../constants/action_names";

export const selectLivecam = livecam => {
  return dispatch => {
    dispatch({
      type: actions.LIVECAM_SELECT,
      livecam
    });
  };
};
