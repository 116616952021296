import * as actions from "../constants/action_names.js";
import moment from "moment";

export const setTimeline = (bounds, push) => dispatch => {
  return dispatch({
    type: actions.SET_TIMELINE,
    bounds,
    pushToUrl: push
      ? { timelineBounds: bounds.map(d => moment(d).unix()) }
      : null
  });
};
