import React, { Component } from "react";

class ValueFilter extends Component {
  render() {
    return (
      <div className="filter">
        {this.props.values.map(l => (
          <div
            onClick={() => {
              this.props.setFilter(l);
            }}
            key={l}
            className={
              "opt " + (this.props.filter === l ? "active" : "inactive")
            }
          >
            {l.charAt(0).toUpperCase() + l.slice(1)}
          </div>
        ))}
      </div>
    );
  }
}

export default ValueFilter;
