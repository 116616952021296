import * as d3 from "d3";

export function filterGeodata(filter, geodata) {
  let filtered = filter
    ? geodata.features.filter(
        f =>
          f.properties.genus_species.replace("  ", " ") === filter ||
          f.properties.genus_species.split(" ")[0] === filter
      )
    : geodata.features;

  // merge features at the same point
  // this could probably be moved into the data fetch
  let features = {};
  filtered.forEach(f => {
    if (!features[f.properties.location_name]) {
      let newprops = {
        cnt: 0,
        bees: {},
        id: f.properties.location_name,
        _actionMapSelected: f.properties._actionMapSelected
      };

      newprops.bees[f.properties.genus_species] = {
        ...f.properties,
        start_cnt: 0
      };

      features[f.properties.location_name] = { ...f, properties: newprops };
    } else {
      features[f.properties.location_name].properties = {
        ...features[f.properties.location_name].properties,
        bees: {
          ...features[f.properties.location_name].properties.bees,
          [f.properties.genus_species]: {
            ...f.properties,
            start_cnt: features[f.properties.location_name].properties.cnt,
            cnt: f.properties.cnt
          }
        }
      };
    }

    features[f.properties.location_name].properties.cnt += f.properties.cnt;
  });

  features = Object.values(features);

  let max = d3.max(features.map(f => f.properties.cnt));

  return { features, max };
}
