import React, { Component } from "react";
import * as d3 from "d3";

class TimelinePointLayer extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.size.width !== nextProps.size.width) {
      return true;
    }

    if (this.props.scale !== nextProps.scale) {
      return true;
    }

    if (nextProps.data.length !== this.props.data.length) {
      return true;
    }

    if (nextProps.data.length === 0) return false;

    this.props.data.forEach((d, i) => {
      if (nextProps.data[i].r !== d.r) return true;
    });

    return false;
  }

  render() {
    let min_x = this.props.scale(d3.min(this.props.data.map(d => d.date)));
    let max_x = this.props.scale(d3.max(this.props.data.map(d => d.date)));
    let average_r = Math.pow(d3.median(this.props.data.map(d => d.r)), 2);

    let density = this.props.data.length / (max_x - min_x);
    let opacity = Math.min(0.3, Math.max(0.05, 0.4 / (density * average_r)));
    let fill = this.props.color;

    let circles = this.props.data.map(d => (
      <circle
        key={d.key}
        cx={this.props.scale(d.date)}
        cy={this.props.size.height / 2}
        r={d.r}
        style={{ opacity, fill }}
      />
    ));

    return <React.Fragment>{circles}</React.Fragment>;
  }
}

export default TimelinePointLayer;
