import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import * as d3 from "d3";
import { selectBeeSite, setBeeFilter } from "../../actions/bees";
import push from "../../constants/push.js";
import "./BeeModal.scss";
import SpeciesDrilldown from "../SpeciesList/SpeciesDrilldown";
import { fetchBeesOverview } from "../../actions/async/bees";

const mapStateToProps = state => ({
  bees: state.bees,
  timeline: state.timeline
});

const mapDispatchToProps = dispatch => ({
  selectBeeSite: site => dispatch(selectBeeSite(site)),
  setBeeFilter: filter => {
    dispatch(setBeeFilter(filter));
    dispatch(fetchBeesOverview(filter));
  },
  goBack: () => dispatch(push("/bees"))
});

class BeeModal extends Component {
  componentDidUpdate(prevProps) {
    if (
      (this.props.bees.points &&
        JSON.stringify(this.props.bees.points) !==
          JSON.stringify(prevProps.bees.points)) ||
      this.props.match.params.site !== prevProps.match.params.site
    ) {
      this.props.selectBeeSite(this.props.match.params.site);
    }
  }

  render() {
    if (this.props.bees.points) {
      const colorScale = d3.scaleOrdinal(d3.schemeCategory10);

      let unique_species = {};
      this.props.bees.points.features.forEach(f => {
        colorScale(f.properties.genus_species);
        if (!(f.properties.genus_species in unique_species)) {
          unique_species[f.properties.genus_species] = {
            ...f,
            genus: f.properties.genus_species.split(" ")[0],
            genus_species: f.properties.genus_species.replace("  ", " "),
            count: 0
          };
        }
        unique_species[f.properties.genus_species].count += f.properties.cnt;
      });
      unique_species = Object.values(unique_species);

      let unique_geni = {};
      unique_species.forEach(s => {
        if (!(s.genus in unique_geni)) {
          unique_geni[s.genus] = { ...s, count: 0, genus: s.genus };
        }

        unique_geni[s.genus].count += s.count;
      });
      unique_geni = Object.values(unique_geni);

      if (this.props.match.params.site) {
        unique_species = this.props.bees.points.features
          .filter(
            f =>
              f.properties.location_name.replace(/\D/g, "") ===
              this.props.match.params.site
          )
          .map(f => f.properties)
          .map(f => ({ ...f, count: f.cnt, properties: { ...f } }));
      }

      return (
        <div className="BeeModal">
          <div className="title">Pollinator research</div>
          <div className="description">
            <p>
              Marirose Kuhlman is a botanist at MPG Ranch{" "}
              <a href="https://www.mpgranch.com/author/marirose-kuhlman">
                conducting ongoing research into pollinators and their
                relationship to native and invasive plants.{" "}
              </a>
              The bees of the Bitterroot Valley are as diverse as the more
              visible parts of the ecosystem, and different bees fill different
              niches on MPG Ranch.
            </p>
            <p>
              Explore her data by selecting different seasons on the timeline or
              collection sites on the map. Click on genii and species to filter
              by organism.
            </p>
          </div>

          <SpeciesDrilldown
            categories={unique_geni.map(s => ({
              count: s.count,
              title: s.genus,
              category: s.genus,
              category_display: s.genus,
              photo: s.properties
                ? s.properties.genus_photo
                  ? s.properties.genus_photo.replace("square", "medium")
                  : null
                : null,
              description: s.properties.genus_summary
            }))}
            species={unique_species.map(s => ({
              location: (s.location_name || "").replace(/\D/g, ""),
              category: s.genus,
              category_display: s.genus,
              species: s.genus_species,
              count: s.count,
              title: s.genus_species || s.genus,
              photo: s.properties
                ? s.properties.default_photo
                  ? s.properties.default_photo.replace("square", "medium")
                  : null
                : null,
              description: s.properties
                ? s.properties.wikipedia_summary === "false"
                  ? s.properties.common_name
                    ? s.properties.common_name
                    : null
                  : s.properties.wikipedia_summary
                : null
            }))}
            setFilter={filter => this.props.setBeeFilter(filter)}
            filter={this.props.bees.filter}
            location={this.props.match.params.site}
            siteTerminology={"collection site"}
            unitTerminology={" collected"}
            goTo={this.props.goBack.bind(this)}
          />
        </div>
      );
    } else {
      return <div />;
    }
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BeeModal)
);
