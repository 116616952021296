import * as actions from "../constants/action_names.js";
import queryString from "query-string";

const query = queryString.parse(window.location.href.split("?")[1] || "");

const initialState = {
  filter: query.biocrustfilter ? query.biocrustfilter : "bryophyte cover"
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.BIOCRUST_SET_FILTER:
      return {
        ...state,
        filter: action.filter
      };
    default:
      return state;
  }
};
