export const BOUNDARY_RESPONSE = "BOUNDARY_RESPONSE";
export const BOUNDARY_ERROR = "BOUNDARY_ERROR";

export const SET_TIMELINE = "SET_TIMELINE";

export const AUTH_DATA_REQUEST = "AUTH_DATA_REQUEST";
export const AUTH_DATA_RESPONSE = "AUTH_DATA_RESPONSE";
export const AUTH_DATA_ERROR_RESPONSE = "AUTH_DATA_ERROR_RESPONSE";

export const BEE_OVERVIEW_RESPONSE = "BEE_OVERVIEW_RESPONSE";
export const BEE_OVERVIEW_REQUEST = "BEE_OVERVIEW_REQUEST";
export const BEE_OVERVIEW_ERROR_RESPONSE = "BEE_OVERVIEW_ERROR_RESPONSE";

export const BEE_POINTS_RESPONSE = "BEE_POINTS_RESPONSE";
export const BEE_POINTS_REQUEST = "BEE_POINTS_REQUEST";
export const BEE_POINTS_ERROR_RESPONSE = "BEE_POINTS_ERROR_RESPONSE";
export const BEE_POINT_SELECT = "BEE_POINT_SELECT";
export const SET_BEE_FILTER = "SET_BEE_FILTER";

export const BIOCRUST_POINTS_GEOJSON_REQUEST =
  "BIOCRUST_POINTS_GEOJSON_REQUEST";
export const BIOCRUST_POINTS_GEOJSON_RESPONSE =
  "BIOCRUST_POINTS_GEOJSON_RESPONSE";
export const BIOCRUST_POINTS_GEOJSON_ERROR_RESPONSE =
  "BIOCRUST_POINTS_GEOJSON_ERROR_RESPONSE";
export const BIOCRUST_POINTS_SET_FILTER = "BIOCRUST_POINTS_SET_FILTER";
export const BIOCRUST_POINT_SELECT = "BIOCRUST_POINT_SELECT";
export const BIOCRUST_SET_FILTER = "BIOCRUST_SET_FILTER";

export const EBIRD_OVERVIEW_RESPONSE = "EBIRD_OVERVIEW_RESPONSE";
export const EBIRD_OVERVIEW_REQUEST = "EBIRD_OVERVIEW_REQUEST";
export const EBIRD_OVERVIEW_ERROR_RESPONSE = "EBIRD_OVERVIEW_ERROR_RESPONSE";
export const EBIRD_POINTS_RESPONSE = "EBIRD_POINTS_RESPONSE";
export const EBIRD_POINTS_REQUEST = "EBIRD_POINTS_REQUEST";
export const EBIRD_POINTS_ERROR_RESPONSE = "EBIRD_POINTS_ERROR_RESPONSE";
export const EBIRD_DETAIL_RESPONSE = "EBIRD_DETAIL_RESPONSE";
export const EBIRD_DETAIL_REQUEST = "EBIRD_DETAIL_REQUEST";
export const EBIRD_DETAIL_ERROR_RESPONSE = "EBIRD_DETAIL_ERROR_RESPONSE";
export const EBIRD_SELECT = "EBIRD_SELECT";
export const EBIRD_SET_FILTER = "EBIRD_SET_FILTER";

export const FLORA_GEOJSON_REQUEST = "FLORA_GEOJSON_REQUEST";
export const FLORA_GEOJSON_RESPONSE = "FLORA_GEOJSON_RESPONSE";
export const FLORA_GEOJSON_ERROR_RESPONSE = "FLORA_GEOJSON_ERROR_RESPONSE";
export const FLORA_SET_FILTER = "FLORA_SET_FILTER";

export const INATURALIST_OVERVIEW_RESPONSE = "INATURALIST_OVERVIEW_RESPONSE";
export const INATURALIST_OVERVIEW_REQUEST = "INATURALIST_OVERVIEW_REQUEST";
export const INATURALIST_OVERVIEW_ERROR_RESPONSE =
  "INATURALIST_OVERVIEW_ERROR_RESPONSE";

export const INATURALIST_PHOTOS_RESPONSE = "INATURALIST_PHOTOS_RESPONSE";
export const INATURALIST_PHOTOS_REQUEST = "INATURALIST_PHOTOS_REQUEST";
export const INATURALIST_PHOTOS_ERROR_RESPONSE =
  "INATURALIST_PHOTOS_ERROR_RESPONSE";

export const INATURALIST_POINTS_RESPONSE = "INATURALIST_POINTS_RESPONSE";
export const INATURALIST_POINTS_REQUEST = "INATURALIST_POINTS_REQUEST";
export const INATURALIST_POINTS_ERROR_RESPONSE =
  "INATURALIST_POINTS_ERROR_RESPONSE";

export const INATURALIST_SELECT = "INATURALIST_SELECT";
export const INATURALIST_SET_FILTER = "INATURALIST_SET_FILTER";

export const LIVECAM_DETAILS_RESPONSE = "LIVECAM_DETAILS_RESPONSE";
export const LIVECAM_DETAILS_REQUEST = "LIVECAM_DETAILS_REQUEST";
export const LIVECAM_DETAILS_ERROR_RESPONSE = "LIVECAM_DETAILS_ERROR_RESPONSE";
export const LIVECAM_POINTS_RESPONSE = "LIVECAM_POINTS_RESPONSE";
export const LIVECAM_POINTS_REQUEST = "LIVECAM_POINTS_REQUEST";
export const LIVECAM_POINTS_ERROR_RESPONSE = "LIVECAM_POINTS_ERROR_RESPONSE";
export const LIVECAM_SELECT = "LIVECAM_SELECT";

export const MOBILE_PANEL_CHANGE = "MOBILE_PANEL_CHANGE";

export const MOTION_OVERVIEW_RESPONSE = "MOTION_OVERVIEW_RESPONSE";
export const MOTION_OVERVIEW_REQUEST = "MOTION_OVERVIEW_REQUEST";
export const MOTION_OVERVIEW_ERROR_RESPONSE = "MOTION_OVERVIEW_ERROR_RESPONSE";

export const MOTION_POINTS_RESPONSE = "MOTION_POINTS_RESPONSE";
export const MOTION_POINTS_RESPONSE_ERROR = "MOTION_POINTS_RESPONSE_ERROR";

export const TIMELAPSE_OVERVIEW_RESPONSE = "TIMELAPSE_OVERVIEW_RESPONSE";
export const TIMELAPSE_OVERVIEW_ERROR = "TIMELAPSE_OVERVIEW_ERROR";

export const TIMELAPSE_DETAIL_RESPONSE = "TIMELAPSE_DETAIL_RESPONSE";
export const TIMELAPSE_DETAIL_ERROR = "TIMELAPSE_DETAIL_ERROR";

export const TIMELAPSE_IMAGE_RESPONSE = "TIMELAPSE_IMAGE_RESPONSE";
export const TIMELAPSE_IMAGE_ERROR = "TIMELAPSE_IMAGE_ERROR";

export const TIMELAPSE_SET_FULLSCREEN = "TIMELAPSE_SET_FULLSCREEN";
export const TIMELAPSE_SELECT = "TIMELAPSE_SELECT";

export const TRAILCAM_CATEGORIES_REQUEST = "TRAILCAM_CATEGORIES_REQUEST";
export const TRAILCAM_CATEGORIES_RESPONSE = "TRAILCAM_CATEGORIES_RESPONSE";
export const TRAILCAM_CATEGORIES_ERROR = "TRAILCAM_CATEGORIES_ERROR";
export const TRAILCAM_IMAGE_REQUEST = "TRAILCAM_IMAGE_REQUEST";
export const TRAILCAM_IMAGE_RESPONSE = "TRAILCAM_IMAGE_RESPONSE";
export const TRAILCAM_IMAGE_ERROR = "TRAILCAM_IMAGE_ERROR";
export const TRAILCAM_SET_FILTER = "TRAILCAM_SET_FILTER";
export const TRAILCAM_IMAGE_DISABLE_REQUEST = "TRAILCAM_IMAGE_DISABLE_REQUEST";
export const TRAILCAM_IMAGE_DISABLE_RESPONSE =
  "TRAILCAM_IMAGE_DISABLE_RESPONSE";
export const TRAILCAM_IMAGE_DISABLE_ERROR_RESPONSE =
  "TRAILCAM_IMAGE_DISABLE_ERROR_RESPONSE";

export const TRAILCAM_SELECT = "TRAILCAM_SELECT";

export const RAPTOR_OVERVIEW_RESPONSE = "RAPTOR_OVERVIEW_RESPONSE";
export const RAPTOR_OVERVIEW_REQUEST = "RAPTOR_OVERVIEW_REQUEST";
export const RAPTOR_OVERVIEW_ERROR_RESPONSE = "RAPTOR_OVERVIEW_ERROR_RESPONSE";

export const RAPTOR_POINTS_RESPONSE = "RAPTOR_POINTS_RESPONSE";
export const RAPTOR_POINTS_REQUEST = "RAPTOR_POINTS_REQUEST";
export const RAPTOR_POINTS_ERROR_RESPONSE = "RAPTOR_POINTS_ERROR_RESPONSE";

export const RESTORATION_OVERVIEW_RESPONSE = "RESTORATION_OVERVIEW_RESPONSE";
export const RESTORATION_OVERVIEW_REQUEST = "RESTORATION_OVERVIEW_REQUEST";
export const RESTORATION_OVERVIEW_ERROR_RESPONSE =
  "RESTORATION_OVERVIEW_ERROR_RESPONSE";

export const RESTORATION_POINTS_RESPONSE = "RESTORATION_POINTS_RESPONSE";
export const RESTORATION_POINTS_REQUEST = "RESTORATION_POINTS_REQUEST";
export const RESTORATION_POINTS_ERROR_RESPONSE =
  "RESTORATION_POINTS_ERROR_RESPONSE";

export const RESTORATION_REPORTS_RESPONSE = "RESTORATION_REPORTS_RESPONSE";
export const RESTORATION_REPORTS_ERROR = "RESTORATION_REPORTS_ERROR";

export const RESTORATION_MANAGEMENT_UNIT_SELECT =
  "RESTORATION_MANAGEMENT_UNIT_SELECT";

export const SOIL_DATA_RESPONSE = "SOIL_DATA_RESPONSE";
export const SOIL_DATA_REQUEST = "SOIL_DATA_REQUEST";
export const SOIL_DATA_ERROR_RESPONSE = "SOIL_DATA_ERROR_RESPONSE";

export const SOIL_OVERVIEW_RESPONSE = "SOIL_OVERVIEW_RESPONSE";
export const SOIL_OVERVIEW_REQUEST = "SOIL_OVERVIEW_REQUEST";
export const SOIL_OVERVIEW_ERROR_RESPONSE = "SOIL_OVERVIEW_ERROR_RESPONSE";

export const SOIL_POINTS_RESPONSE = "SOIL_POINTS_RESPONSE";
export const SOIL_POINTS_REQUEST = "SOIL_POINTS_REQUEST";
export const SOIL_POINTS_ERROR_RESPONSE = "SOIL_POINTS_ERROR_RESPONSE";

export const SOIL_STATION_SELECT = "SOIL_STATION_SELECT";

export const VIDEO_OVERVIEW_RESPONSE = "VIDEO_OVERVIEW_RESPONSE";
export const VIDEO_OVERVIEW_REQUEST = "VIDEO_OVERVIEW_REQUEST";
export const VIDEO_OVERVIEW_ERROR_RESPONSE = "VIDEO_OVERVIEW_ERROR_RESPONSE";

export const VIDEO_POINTS_RESPONSE = "VIDEO_POINTS_RESPONSE";
export const VIDEO_POINTS_REQUEST = "VIDEO_POINTS_REQUEST";
export const VIDEO_POINTS_ERROR_RESPONSE = "VIDEO_POINTS_ERROR_RESPONSE";

export const VIDEO_SELECT = "VIDEO_SELECT";

export const WEATHER_DATA_RESPONSE = "WEATHER_DATA_RESPONSE";
export const WEATHER_DATA_REQUEST = "WEATHER_DATA_REQUEST";
export const WEATHER_DATA_ERROR_RESPONSE = "WEATHER_DATA_ERROR_RESPONSE";

export const WEATHER_OVERVIEW_RESPONSE = "WEATHER_OVERVIEW_RESPONSE";
export const WEATHER_OVERVIEW_REQUEST = "WEATHER_OVERVIEW_REQUEST";
export const WEATHER_OVERVIEW_ERROR_RESPONSE =
  "WEATHER_OVERVIEW_ERROR_RESPONSE";

export const WEATHER_POINTS_RESPONSE = "WEATHER_POINTS_RESPONSE";
export const WEATHER_POINTS_REQUEST = "WEATHER_POINTS_REQUEST";
export const WEATHER_POINTS_ERROR_RESPONSE = "WEATHER_POINTS_ERROR_RESPONSE";

export const WEATHER_STATION_SELECT = "WEATHER_STATION_SELECT";

export const SET_ACTIVE_LAYERS = "SET_ACTIVE_LAYERS";
export const SET_LAYER_OPACITY = "SET_LAYER_OPACITY";
export const UNSET_LAYER_OPACITY = "UNSET_LAYER_OPACITY";

export const SET_ZOOM = "SET_ZOOM";
export const SET_CENTER = "SET_CENTER";
export const SET_PROPERTY = "SET_PROPERTY";
export const SET_MODAL_REF = "SET_MODAL_REF";
export const SET_MODAL_SIZE = "SET_MODAL_SIZE";
export const CLEAR_MODAL_SIZE = "CLEAR_MODAL_SIZE";
