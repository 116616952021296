import * as actions from "../constants/action_names";

export const setFilter = filter => {
  return dispatch => {
    dispatch({
      type: actions.TRAILCAM_SET_FILTER,
      filter,
      pushToUrl: { trailcamfilter: filter }
    });
  };
};

export const selectTrailcam = trailcam => {
  return dispatch => {
    dispatch({
      type: actions.TRAILCAM_SELECT,
      trailcam
    });
  };
};
