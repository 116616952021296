import moment from "moment";
import * as actions from "../../constants/action_names";
import * as config from "../../constants/config";
import { fetchSql } from "../../constants/carto";

export const fetchEBirdOverview = filter => {
  const sql = `SELECT date_trunc('week', obs_time) AS week, SUM(obs_qty) AS obs_qty
      FROM ${config.ebird_table}
      GROUP BY week
      ORDER BY week`;

  if (filter) {
    const filteredSql = `SELECT date_trunc('week', obs_time) AS week, SUM(obs_qty) AS obs_qty
    FROM ${config.ebird_table}
    WHERE (scientific_name = '${filter}' OR family = '${filter}')
    GROUP BY week
    ORDER BY week`;

    return dispatch => {
      return Promise.all([fetchSql(sql, "json"), fetchSql(filteredSql, "json")])
        .then(([json, filteredjson]) => {
          json.rows = json.rows.map(r => ({
            ...r,
            week: new Date(r.week)
          }));
          filteredjson.rows = filteredjson.rows.map(r => ({
            ...r,
            week: new Date(r.week)
          }));

          dispatch({
            type: actions.EBIRD_OVERVIEW_RESPONSE,
            json,
            selected: filteredjson
          });
        })
        .catch(error =>
          dispatch({
            type: actions.EBIRD_OVERVIEW_ERROR_RESPONSE,
            error: error.message
          })
        );
    };
  } else {
    return dispatch => {
      return fetchSql(sql, "json")
        .then(json => {
          json.rows = json.rows.map(r => ({
            ...r,
            week: new Date(r.week)
          }));
          dispatch({
            type: actions.EBIRD_OVERVIEW_RESPONSE,
            json
          });
        })
        .catch(error =>
          dispatch({
            type: actions.EBIRD_OVERVIEW_ERROR_RESPONSE,
            error: error.message
          })
        );
    };
  }
};

export const fetchEBirdModalDetail = (bounds, filter) => {
  if (!bounds) {
    bounds = [moment().subtract(6, "months"), moment()];
  }

  const speciesCountSql = `SELECT o.*, photo, photo_source, description, description_source FROM
      (SELECT SUM(obs_qty) AS obs_qty, COUNT(obs_qty) AS observations, species_order, family, family_common, scientific_name, common_name, species_code, loc_id
      FROM ${config.ebird_table}  
      WHERE obs_time > '${bounds[0].toISOString()}' AND obs_time < '${bounds[1].toISOString()}'       
      GROUP BY scientific_name, common_name, family, family_common, species_order, species_code, loc_id
      ORDER BY obs_qty DESC) o
    LEFT JOIN ${config.bird_taxa} ON o.scientific_name = ${
    config.bird_taxa
  }.scientific_name`;

  const speciesCountGlobalSql = `SELECT o.*, photo, photo_source, description, description_source FROM
      (SELECT SUM(obs_qty) AS obs_qty, COUNT(obs_qty) AS observations, species_order, family, family_common, scientific_name, common_name, species_code
      FROM ${config.ebird_table}  
      WHERE obs_time > '${bounds[0].toISOString()}' AND obs_time < '${bounds[1].toISOString()}'       
      GROUP BY scientific_name, common_name, family, family_common, species_order, species_code
      ORDER BY obs_qty DESC) o
    LEFT JOIN ${config.bird_taxa} ON o.scientific_name = ${
    config.bird_taxa
  }.scientific_name`;

  const familiesCountSql = `SELECT o.*, photo, photo_source, description, description_source FROM 
      (SELECT SUM(obs_qty) AS obs_qty, COUNT(obs_qty) AS observations, family, family_common
      FROM ${config.ebird_table}  
      WHERE obs_time > '${bounds[0].toISOString()}' AND obs_time < '${bounds[1].toISOString()}'       
      GROUP BY family, family_common
      ORDER BY obs_qty DESC) o
    LEFT JOIN bird_taxa ON o.family = bird_taxa.scientific_name`;

  return dispatch => {
    dispatch({
      type: actions.EBIRD_DETAIL_REQUEST,
      geojson: null
    });

    return Promise.all([
      fetchSql(speciesCountSql, "json"),
      fetchSql(speciesCountGlobalSql, "json"),
      fetchSql(familiesCountSql, "json")
    ])
      .then(([species, speciesGlobal, families]) => {
        dispatch({
          type: actions.EBIRD_DETAIL_RESPONSE,
          speciesSite: species.rows,
          species: speciesGlobal.rows,
          families: families.rows
        });
      })
      .catch(error =>
        dispatch({
          type: actions.EBIRD_DETAIL_ERROR_RESPONSE,
          error: error.message
        })
      );
  };
};

export const fetchEBirdPoints = (bounds, filter) => {
  if (!bounds) {
    bounds = [moment().subtract(6, "months"), moment()];
  }

  const sql = `SELECT SUM(obs_qty) AS obs_qty, loc_id, COUNT(obs_qty) AS observations, the_geom, loc_id AS id
    FROM ${config.ebird_table}     
    WHERE obs_time > '${bounds[0].toISOString()}' AND obs_time < '${bounds[1].toISOString()}'
    ${
      filter
        ? `AND (scientific_name = '${filter}' OR family = '${filter}')`
        : ``
    }
    GROUP BY the_geom, loc_id`;

  return dispatch => {
    dispatch({
      type: actions.EBIRD_POINTS_REQUEST,
      geojson: null
    });

    return fetchSql(sql, "geojson")
      .then(geojson => {
        dispatch({
          type: actions.EBIRD_POINTS_RESPONSE,
          geojson
        });
      })
      .catch(error =>
        dispatch({
          type: actions.EBIRD_POINTS_ERROR_RESPONSE,
          error: error.message
        })
      );
  };
};
