import React from "react";
import moment from "moment";

import { getImageryYear } from "./imagery";

const AerialLegend = ({ startDate, endDate }) => {
  let endYear = getImageryYear(moment(endDate).year());
  let startYear = getImageryYear(moment(startDate).year());

  let year = startYear;
  if (endYear !== startYear) {
    year += " and " + endYear;
  }

  return (
    <div className="PointLegend AerialLegend">
      <div className="label">Aerial imagery from {year}</div>
    </div>
  );
};

export default AerialLegend;
