import React, { Component } from "react";
import { GeoJSON } from "react-leaflet";
import L from "leaflet";
import { pointColors } from "../../constants/layers_static";

import "./RaptorLayer.scss";

class RaptorLayer extends Component {
  render() {
    const zoom = this.props.zoom;

    let lineOpacity = 0.65;
    let lines = true;
    let points = false;
    let pointSize = 6;

    if (zoom >= 12) {
      lineOpacity = 0.3;
      points = true;
    }

    if (zoom >= 13) {
      lineOpacity = 0.2;
    }

    if (zoom >= 14) {
      lines = false;
    }

    if (zoom >= 16) {
      pointSize = 8;
    }

    return (
      <React.Fragment>
        {lines ? (
          <GeoJSON
            data={this.props.tracks}
            key={"lines_" + this.props.tracks.features.length}
            style={feature => ({
              opacity: lineOpacity,
              stroke: true,
              color: pointColors.raptors[feature.properties.species]
            })}
            renderer={L.svg()}
            className="raptor-path"
          />
        ) : null}
        {points ? (
          <GeoJSON
            data={this.props.points}
            key={
              "points_" +
              this.props.points.features.length +
              "_" +
              pointSize +
              lines
            }
            pointToLayer={(feature, latlng) => {
              return L.circleMarker(latlng, {
                radius: pointSize,
                fillColor: pointColors.raptors[feature.properties.species],
                color: "black",
                fillOpacity: 0.3,
                interactive: false,
                opacity: lines ? 0 : 0.3,
                weight: 1
              });
            }}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

export default RaptorLayer;
