import * as actions from "../constants/action_names.js";
import queryString from "query-string";

const query = queryString.parse(window.location.href.split("?")[1] || "");

const initialState = {
  filter: query.beefilter ? query.beefilter : null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.BEE_OVERVIEW_REQUEST:
      return {
        ...state
      };
    case actions.BEE_OVERVIEW_RESPONSE:
      return {
        ...state,
        overview: action.json,
        selected: action.selected
      };
    case actions.BEE_OVERVIEW_ERROR_RESPONSE:
      return {
        ...state,
        overview: action.error
      };
    case actions.BEE_POINTS_RESPONSE:
      return {
        ...state,
        points: action.geojson
      };
    case actions.SET_BEE_FILTER:
      return {
        ...state,
        filter: action.filter
      };
    case actions.BEE_POINT_SELECT:
      return {
        ...state,
        points: {
          ...state.points,
          features: state.points.features.map(feature => {
            const siteId = feature.properties.location_name.replace(/\D/g, "");
            return {
              ...feature,
              properties: {
                ...feature.properties,
                _actionMapSelected: siteId === action.site
              }
            };
          })
        }
      };
    default:
      return state;
  }
};
