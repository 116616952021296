import { withLeaflet } from "react-leaflet";
import { connect } from "react-redux";
import push from "../../constants/push.js";
import { slugify } from "../../constants/app.js";
import makeComponent from "../CritterMap/makeComponent.js";
import SvgLayer from "../CritterMap/SvgLayer";

import weatherIcon1 from "./icons/wind-1.svg";
import weatherIcon2 from "./icons/wind-2.svg";
import weatherIcon3 from "./icons/wind-3.svg";
import weatherIcon4 from "./icons/wind-4.svg";
import weatherIcon5 from "./icons/wind-5.svg";
import weatherIcon6 from "./icons/wind-6.svg";
import weatherIcon7 from "./icons/wind-7.svg";
import weatherIcon8 from "./icons/wind-8.svg";
import weatherIcon9 from "./icons/wind-9.svg";
import weatherIcon10 from "./icons/wind-10.svg";
import weatherIcon11 from "./icons/wind-11.svg";
import weatherIcon12 from "./icons/wind-12.svg";

class WeatherLayer extends SvgLayer {
  formatTemperature(temp) {
    return `${Math.round(temp)}º`;
  }

  getWindSpeedIcon(speed) {
    if (speed >= 1 && speed < 4) return weatherIcon1;
    if (speed >= 4 && speed < 8) return weatherIcon2;
    if (speed >= 8 && speed < 13) return weatherIcon3;
    if (speed >= 13 && speed < 19) return weatherIcon4;
    if (speed >= 19 && speed < 25) return weatherIcon5;
    if (speed >= 25 && speed < 32) return weatherIcon6;
    if (speed >= 32 && speed < 39) return weatherIcon7;
    if (speed >= 39 && speed < 47) return weatherIcon8;
    if (speed >= 47 && speed < 55) return weatherIcon9;
    if (speed >= 55 && speed < 64) return weatherIcon10;
    if (speed >= 64 && speed < 75) return weatherIcon11;
    if (speed >= 75) return weatherIcon12;
  }

  updateData(data) {
    super.updateData(data);

    let d = this.g
      .selectAll("g")
      .data(this.data.features, d => d.properties.id);

    let g = d.enter().append("g");

    const selectedRadius = 25;
    const defaultRadius = 10;
    const selectedIconSize = 35;
    const defaultIconSize = 20;

    g.append("circle")
      .attr("cy", 0)
      .attr("cx", 0)
      .style("fill", "#ccc")
      .style("mix-blend-mode", "overlay")
      .style("opacity", 0.75)
      .on("click", d => this.onClick(d.properties.id));

    g.append("text")
      .classed("temperature", true)
      .attr("y", 3)
      .style("font-size", 9)
      .style("text-anchor", "middle");

    g.append("image").classed("wind-speed-icon", true);

    const merged = g
      .merge(d)
      .attr("transform", this.translate.bind(this))
      .attr("class", "translategroup");

    merged.select(".temperature").text(d => {
      if (!d.properties || !d.properties.tempi || d.properties.tempi === "0") {
        return "";
      }
      return this.formatTemperature(d.properties.tempi);
    });

    merged
      .select(".wind-speed-icon")
      .attr("xlink:href", d => {
        const speed = parseFloat(d.properties.wspdi);
        return this.getWindSpeedIcon(speed);
      })
      .attr("transform", d => {
        const angle =
          d.properties && d.properties.wdird ? d.properties.wdird : 0;
        return `rotate(${angle})`;
      })
      .attr("height", d =>
        d.properties._actionMapSelected ? selectedIconSize : defaultIconSize
      )
      .attr("width", d =>
        d.properties._actionMapSelected ? selectedIconSize : defaultIconSize
      )
      .attr("x", d =>
        d.properties._actionMapSelected
          ? -(selectedIconSize / 2)
          : -(defaultIconSize / 2)
      )
      .attr("y", d =>
        d.properties._actionMapSelected
          ? -(selectedIconSize + selectedRadius)
          : -(defaultIconSize + defaultRadius)
      );

    merged
      .select("circle")
      .attr("r", d =>
        d.properties._actionMapSelected ? selectedRadius : defaultRadius
      )
      .style("stroke", d =>
        d.properties._actionMapSelected ? "black" : "#666"
      )
      .style("stroke-width", d =>
        d.properties._actionMapSelected ? "3px" : null
      );

    d.exit().remove();
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  go: weatherStationName => {
    dispatch(push("/weather/" + slugify(weatherStationName)));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLeaflet(makeComponent(WeatherLayer)));
